import React, { useMemo, useEffect, useCallback } from "react";
import { SideFilter as SideFilterFeature, useSideFilterConfigBuilder } from "../../../side-filter";
import useInventoryTrackPageDataSourceContext from "../../contexts/inventory-track-page-data-source-context";
import useInventoryTrackPageConfigContext from "../../contexts/inventory-track-page-config-context";
import Style from "./SideFilters.module.css";
import CollapsibleView from "./components/collapsible-view";
import useInventoryTrackPageStateContext from "../../contexts/inventory-track-page-state-context";
import useCustomSideFilterOptionFetcher from "./hooks/use-custom-side-filter-option-fetcher";
import useAuthenticationContext from "../../../../context/authentication-context";

export const SideFilters = () => {
  const { cognitoUser } = useAuthenticationContext();

  const {
    isSideFilterHidden,
    selectedViewTypeId,
    selectedViewModeId,
    sideFilterValue,
    setSideFilterValue,
    setApplyItemTypeFilterFn,
    setApplyItemFilterFn,
    setApplyLotFilterFn,
    setApplyLocationFilterFn,
    selectedLocationId
  } = useInventoryTrackPageStateContext();
  const { sideFilterControl } = useInventoryTrackPageConfigContext();

  const { locationTreeMap } = useInventoryTrackPageDataSourceContext();

  const leafLocationIds = useMemo(() => {
    const selectedLocation = locationTreeMap[selectedLocationId];
    if (!selectedLocation) {
      return Object.keys(locationTreeMap).filter((eachLocationId) => {
        return locationTreeMap[eachLocationId].childLocations?.length === 1;
      });
    }
    return selectedLocation.childLocations.filter((eachChildLocationId) => {
      return locationTreeMap[eachChildLocationId].childLocations.length === 1;
    });
  }, [selectedLocationId, locationTreeMap]);

  const currentSideFilterControl = useMemo(() => {
    return (
      sideFilterControl?.filter(({ tableViewType, viewMode }) => {
        return (
          (!tableViewType || tableViewType.includes("all") || tableViewType.includes(selectedViewTypeId)) &&
          (!viewMode || viewMode.includes("all") || viewMode.includes(selectedViewModeId))
        );
      }) || []
    );
  }, [sideFilterControl, selectedViewTypeId, selectedViewModeId]);

  const { availableOptionTemplateIds, optionTemplateParamsMap } = useMemo(() => {
    return sideFilterControl
      .filter((eachFilterConfig) => {
        return eachFilterConfig.optionTemplate;
      })
      .reduce(
        (accumulator, eachFilterConfig) => {
          let eachOptionParams = {};

          switch (eachFilterConfig.optionTemplate) {
            case "itemTypeMetrics":
              eachOptionParams = { locationIds: leafLocationIds, filterString: "" };
              break;
            case "customerPartNumber":
              eachOptionParams = {
                partnerIdentifiers: cognitoUser?.attributes?.["custom:partner"]
                  ? [cognitoUser.attributes["custom:partner"]]
                  : []
              };
              break;
            default:
              break;
          }

          return {
            availableOptionTemplateIds: [
              ...accumulator.availableOptionTemplateIds,
              { id: eachFilterConfig.optionTemplate }
            ],
            optionTemplateParamsMap: {
              ...accumulator.optionTemplateParamsMap,
              [eachFilterConfig.optionTemplate]: {
                ...eachOptionParams,
                ...(eachFilterConfig.optionTemplateParam || {})
              }
            }
          };
        },
        { availableOptionTemplateIds: [], optionTemplateParamsMap: {} }
      );
  }, [sideFilterControl, leafLocationIds, cognitoUser]);

  const customSideFilterOptionsFetcherMap = useCustomSideFilterOptionFetcher(
    availableOptionTemplateIds,
    optionTemplateParamsMap
  );

  const customGetOptionFn = useCallback(
    (optionTemplateId) => {
      return customSideFilterOptionsFetcherMap[optionTemplateId]?.dataList;
    },
    [customSideFilterOptionsFetcherMap]
  );

  const isLoading = useMemo(() => {
    return Object.values(customSideFilterOptionsFetcherMap).some((eachFetcher) => {
      return eachFetcher.isLoading || eachFetcher.hasNextPage;
    });
  }, [customSideFilterOptionsFetcherMap]);

  const {
    filterConfiguration,
    initialFilterValue,
    applySideFilters,
    getOnlyActiveValues,
    applyActiveValueOnInitialFilterValue
  } = useSideFilterConfigBuilder(currentSideFilterControl, customGetOptionFn);

  const currentSideFilterValue = useMemo(() => {
    return applyActiveValueOnInitialFilterValue(sideFilterValue);
  }, [initialFilterValue, sideFilterValue]);

  useEffect(() => {
    let filterDataSourceValueMap = {
      itemType: {},
      lot: {},
      location: {},
      item: {}
    };

    filterDataSourceValueMap = Object.keys(currentSideFilterValue).reduce((accumulator, eachFilterKey) => {
      const eachFilterConfig = currentSideFilterControl.find(({ id }) => {
        return id === eachFilterKey;
      });

      if (eachFilterConfig) {
        return {
          ...accumulator,
          [eachFilterConfig.filterSource || selectedViewModeId]: {
            ...accumulator[eachFilterConfig.filterSource || selectedViewModeId],
            [eachFilterKey]: currentSideFilterValue[eachFilterKey]
          }
        };
      }
      return accumulator;
    }, filterDataSourceValueMap);

    Object.keys(filterDataSourceValueMap).forEach((eachDataSource) => {
      let updateSideFilterFn;
      switch (eachDataSource) {
        case "itemType":
          updateSideFilterFn = setApplyItemTypeFilterFn;
          break;
        case "item":
          updateSideFilterFn = setApplyItemFilterFn;
          break;
        case "location":
          updateSideFilterFn = setApplyLocationFilterFn;
          break;
        case "lot":
          updateSideFilterFn = setApplyLotFilterFn;
          break;
        default:
          updateSideFilterFn = () => {};
      }
      updateSideFilterFn(() => {
        return Object.keys(filterDataSourceValueMap[eachDataSource] || {}).some((eachFilterKey) => {
          return sideFilterValue[eachFilterKey];
        })
          ? (eachEntry) => {
              return applySideFilters(filterConfiguration, filterDataSourceValueMap[eachDataSource], eachEntry);
            }
          : null;
      });
    });
  }, [currentSideFilterValue]);

  return (
    <CollapsibleView
      width={190}
      isCollapsed={!sideFilterControl?.length ? true : isSideFilterHidden}
    >
      <div className={Style.container}>
        {currentSideFilterControl?.length ? (
          <SideFilterFeature
            showHideFiltersControl={false}
            isLoading={isLoading}
            filterConfiguration={filterConfiguration}
            initialFilterValues={currentSideFilterValue}
            onFilterChange={({ allValues }) => {
              setSideFilterValue(getOnlyActiveValues(allValues));
            }}
          />
        ) : (
          <div className={Style.empty_filter_container}>
            <p className={Style.empty_filter_text}>Currently No Filters Available</p>
          </div>
        )}
      </div>
    </CollapsibleView>
  );
};
