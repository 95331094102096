import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ModalFormFooter } from "./components/modal-form-footer/ModalFormFooter";
import { ConfirmItemsList } from "./components/confirm-items-list/ConfirmItemsList";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import { ModalForm } from "../../components/modal-form";
import useGeneratePrintTagPayload from "../../hooks/use-generate-print-tag-payload";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { useXemelgoClient } from "../../services/xemelgo-service";
import usePrintService from "../../hooks/use-print-service";
import { PRINT_TYPES } from "../../data/constants";
import Styles from "./PrintTagsModal.module.css";
import { pluralizeWord } from "../../common/Utilities";
import PrintSection from "../../components/print-section";

const APP_ID_TO_FEATURE_ID = {
  asset: "addAsset",
  inventory: "addInventory",
  order: "addOrder"
};

const APP_ID_TO_SOLUTION = {
  asset: "asset",
  inventory: "inventory",
  order: "workOrder"
};

export const PrintTagsModal = ({ selectedItems, onCloseModal, modalTitle, appId, solutionDisplayName = appId }) => {
  const SOLUTION = APP_ID_TO_SOLUTION[appId];
  const FEATURE_ID = APP_ID_TO_FEATURE_ID[appId];

  const configProvider = useAppConfigProvider(appId);
  const client = useXemelgoClient();

  const {
    printLaterLocationId,
    customTemplate,
    tagConfig,
    autoPublishAfterPrintLocationId = ""
  } = configProvider.getValue(FEATURE_ID, "object") || {};

  const { generatePrintCommandBySolution } = useGeneratePrintTagPayload(SOLUTION, PRINT_TYPES.ZPL);

  const {
    printers,
    printerErrorMessage,
    selectedPrinterInfo,
    isLoading: isPrinterLoading,
    onSelectPrinter,
    print
  } = usePrintService(true, PRINT_TYPES.ZPL);

  const [bannerError, setBannerError] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const onLoad = () => {
    selectedItems.every((item) => {
      if (
        printLaterLocationId &&
        item.lastKnownLocation &&
        (item.lastKnownLocation === printLaterLocationId || item.lastKnownLocation.id === printLaterLocationId)
      ) {
        onCloseModal(true, false, `Cannot print tags for ${pluralizeWord(solutionDisplayName)} without tag details.`);
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  const submitPrintTags = async () => {
    setIsPrinting(true);
    const publishClient = await client.getPublishClient();

    for (const item of selectedItems) {
      const itemToPrint = {};

      Object.entries(item).forEach(([key, value]) => {
        if (key.startsWith("task-")) {
          const newKey = key.replace("task-", "");
          itemToPrint[newKey] = value;
        } else if (key === "name") {
          itemToPrint.identifier = item.identifier || value;
          itemToPrint.order_number = value;
        } else if (key === "sensor_profile_vid") {
          itemToPrint.tracker_serial = value;
          itemToPrint.sensorProfileVid = value;
        } else if (key === "represents") {
          itemToPrint.status_flags = value.status_flag;
        } else {
          itemToPrint[key] = value;
        }
      });

      const printZPLCommand = await generatePrintCommandBySolution(itemToPrint, { customTemplate, tagConfig });
      try {
        if (item?.sensorProfile?.id && autoPublishAfterPrintLocationId) {
          await publishClient.publishUserEventWithSensorProfile(
            [item.sensorProfile.id],
            autoPublishAfterPrintLocationId
          );
        }
        await print(printZPLCommand);
      } catch (e) {
        onCloseModal(true, false, "Could not print tags.");
        return;
      }
    }

    onCloseModal(
      true,
      true,
      `Tags for selected ${pluralizeWord(solutionDisplayName)} have begun to be printed.`,
      !!autoPublishAfterPrintLocationId
    );
  };

  useEffect(() => {
    if (printerErrorMessage) {
      setBannerError(true);
    }
  }, [printerErrorMessage]);

  return (
    <ModalForm
      show
      title={<div className={Styles.modal_title}>{modalTitle}</div>}
      body={
        <div className={Styles.modal_body}>
          {bannerError && (
            <DisplayBanner
              bannerError
              bannerMessage={printerErrorMessage}
              onCloseBanner={() => {
                setBannerError(false);
              }}
            />
          )}
          <div>
            {`You are about to print tags for the selected ${pluralizeWord(solutionDisplayName)}.
             Please select your printer and confirm the ${pluralizeWord(solutionDisplayName)} 
             you would like to print tags for.`}
          </div>
          <br />
          <PrintSection
            isPrintEnabled
            singlePrint
            printers={printers}
            selectedPrinter={selectedPrinterInfo}
            onSelectPrinter={onSelectPrinter}
            contentLoading={isPrinterLoading}
            printerError={null}
            disableQuantity
            printerLabel="Select your printer:"
          />
          <br />
          <ConfirmItemsList
            itemNames={selectedItems.map((item) => {
              return item.name || item.sensorProfileVid || item.rfidSerial;
            })}
            solutionDisplayName={solutionDisplayName}
          />
        </div>
      }
      footer={
        <ModalFormFooter
          onConfirm={submitPrintTags}
          onCancel={() => {
            onCloseModal(false, false, "");
          }}
          confirmDisabled={isPrinting || Object.keys(selectedPrinterInfo).length === 0}
          cancelDisabled={isPrinting}
        />
      }
      centered
    />
  );
};

PrintTagsModal.defaultProps = {
  modalTitle: "Print Tags"
};

PrintTagsModal.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  appId: PropTypes.string.isRequired,
  solutionDisplayName: PropTypes.string
};
