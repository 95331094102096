/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useStore } from "react-hookstore";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import GeneralizedModal from "../../components/modal/GeneralizedModal";
import TrackPageComponent from "../../components/TrackPageComponents/track-page-component/TrackPageComponentV2";
import TrackPageComponentStyle from "../../components/TrackPageComponents/track-page-component/TrackPageComponentV2.module.css";
import GridCardContentGroupbyItemType from "../../components/TrackPageComponents/Inventory/grid-card-contents/grid-card-content-groupby-item-type/GridCardContentGroupbyItemType";
import {
  getCustomerLogo,
  getFormattedDate,
  getStackedXemelgoLogo,
  parseQueryString,
  stringifyToQueryString,
  findThreshold,
  getValueOrDefault,
  pluralizeWord,
  naturalSort
} from "../../common/Utilities";
import GridCardComponent from "../../components/grid-card-component/GridCardComponent";
import OptionalLink from "../../components/optional-link/OptionalLink";
import { useXemelgoClient } from "../../services/xemelgo-service";
import BreadcrumbsComponent from "../../components/breadcrumbs-component/BreadcrumbsComponentV2";
import { useAppConfigProvider, useConfigurationProvider } from "../../services/soft-cache-service";
import InventoryOverviewComponent from "../../components/TrackPageComponents/Inventory/inventory-overview-component/InventoryOverviewComponent";
import CheckBoxListComponent from "../../components/checkbox-list-component/CheckBoxListComponent";
import ConfigurationService from "../../services/ConfigurationService";
import "react-loading-skeleton/dist/skeleton.css";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import xemelgoStyle from "../../styles/variable";
import AddInventoryPageFeatureV2 from "./features/add-inventory-feature-v2/AddInventoryFeatureV2";
import AddTransferOrderFeature from "./features/add-transfer-order-feature";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  INVENTORY_TRACKPAGE_V2,
  INVENTORY_TRACKPAGE_V2_STEPS
} from "../../constants/mixpanel-constant/inventoryTrackpageV2";
import UpdateTransferOrdersFeature from "../update-transfer-order-feature";
import MultiSelectActionModal from "./components/multi-select-action-modal";
import StatusPopupComponent from "../../components/status-popup-component";
import { DEFAULT_POPUP_DURATION_MS, STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";
import Style from "./InventoryTrackPageFeature.module.css";

const UNKNOWN_LOCATION_ID = "unknown-location-112358";

const APP_ID = "inventory";
const POSSIBLE_DETECTOR_LOCATIONS = "possibleDetectorLocations";
const LOCATION_DISPLAY_LABEL_MAP = "locationDisplayLabelMap";
const TRACK_PAGE_CONFIG = "trackPage";
const LOADING = "LOADING";

const INCOMING = "incoming";
const ONHAND = "onhand";
const CUSTOMER_ONHAND = "customer_onhand";
const REMOVED = "removed";
const INACTIVE = "inactive";

const mainColor = xemelgoStyle.theme.INVENTORY_PRIMARY;
const secondaryColor = xemelgoStyle.theme.INVENTORY_SECONDARY;

const CRITICAL = "Critical";
const WARNING = "Warning";
const EMPTY = "Empty";
const HEALTHY = "Healthy";
const DAY_IN_MS = 86400000;

const ADD_TRACKING_ORDER_LABEL = "+ Add Tracking Order";
const ENABLE_TRANSFER_ORDER_CSV_UPLOAD = false;

const VIEW_TABS = {
  ITEM_TYPE: "item-type",
  ALL_ITEMS: "all-items",
  ITEM_LOT: "item-lot"
};

const healthPriorityMap = {
  Critical: 0,
  Warning: 1,
  Healthy: 2,
  Empty: 2
};

const healthColorMap = {
  Critical: "#F62227",
  Warning: "#F5b052",
  Normal: "#C6CBD4",
  Healthy: "#00B200",
  Overstock: "#4298FA",
  Empty: "#D3D3D3"
};

const InventoryTrackPageFeature = ({ history }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, "object");
  const LocationRoleConfig = useConfigurationProvider().config.features.locationRole || {};

  const urlRef = useRef({ pathname: history.location.pathname, search: history.location.search });
  const popupTimeoutRef = useRef(null);

  const [showDeleteItemTypesModalFlag, setShowDeleteItemTypesModalFlag] = useState(false);
  const [showResetItemsModalFlag, setShowResetItemsModalFlag] = useState(false);
  const [loadingItemTypesFlag, setLoadingItemTypesFlag] = useState(false);
  const [itemTypeListToDelete, setItemTypeListToDelete] = useState(null);
  const [locationsListToReset, setLocationsListToReset] = useState(null);
  const [selectedItemTypesMap, setSelectedItemTypesMap] = useState({});
  const [selectedLocationsMap, setSelectedLocationsMap] = useState({});
  const [statusTab, setStatusTab] = useState("stock");
  const [loading, setLoading] = useState(true);
  const [overallOverviewData, setOverallOverviewData] = useState({});
  const [trackPageClient] = useState(useXemelgoClient().getTrackPageClient());
  const [ItemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [SensorProfileClient] = useState(useXemelgoClient().getSensorProfileClient());
  const [ItemClient] = useState(useXemelgoClient().getItemClient());
  const [PublishClient] = useState(useXemelgoClient().getPublishClient());
  const [InventoryClient] = useState(useXemelgoClient().getInventoryClient());
  const [AppSyncInventoryClient] = useState(useXemelgoAppsyncClient().getInventoryClient());

  const [logoData, setLogoData] = useState(null);
  const [itemTypeLabel, setItemTypeLabel] = useState("Item Type");
  const [addTrackingOrderLabel, setAddTrackingOrderLabel] = useState(
    TrackPageConfig.addTrackingOrderLabel || ADD_TRACKING_ORDER_LABEL
  );
  const [enableTransferOrderCsvUpload, setEnableTransferOrderCsvUpload] = useState(
    TrackPageConfig.enableTransferOrderCsvUpload || ENABLE_TRANSFER_ORDER_CSV_UPLOAD
  );
  const [metricsMap, setMetricsMap] = useState({});
  const [possibleSecondaryView, setPossibleSecondaryView] = useState({});
  const [gridViewConfig, setGridViewConfig] = useState({});
  const [listViewConfig, setListViewConfig] = useState({});

  const [sideFilterStructureMap, setSideFilterStructureMap] = useState({});
  const [listViewMode, setListViewMode] = useState(true);
  const [currentViewTab, setCurrentViewTab] = useState(TrackPageConfig.defaultViewTab || VIEW_TABS.ITEM_TYPE);
  const [mainFilterIdList, setMainFilterIdList] = useState([]);
  const [locationsMap, setLocationsMap] = useState({});
  const [targetLocationBased, setTargetLocationBased] = useState(false);
  const prevListViewModeRef = useRef();
  const prevViewTabRef = useRef();
  const [breadcrumbsDataList, setBreadcrumbsDataList] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [locationDisplayLabelMap, setLocationDisplayLabelMap] = useState({});
  const [headerMetricsMap, setHeaderMetricsMap] = useState({});
  const [showOnboardingModalV2, setShowOnboardingModalV2] = useState(false);
  const overallOverviewDataRef = useRef();
  const [listLoaded, setListLoaded] = useState(false);
  const [commonData, setCommonData] = useState();
  const [contentMetadataList, setContentMetadataList] = useState([]);
  const [contentMetricsData, setContentMetricsData] = useState({});
  const [contentListData, setContentListData] = useState([]);
  const [filterConfig, setFilterConfig] = useState({});
  const [overviewMetricsDataList, setOverviewMetricsDataList] = useState([]);
  const [overviewChartDataList, setOverviewChartDataList] = useState([]);
  const [additionalAttributeMap, setAdditionalAttributeMap] = useState({});
  const [includeItemOrder, setIncludeItemOrder] = useState(false);
  const [userProfile] = useStore(userProfileStore);
  const [resetItemsFilterInput, setResetItemsFilterInput] = useState("");
  const [deleteItemTypesFilterInput, setDeleteItemTypesFilterInput] = useState("");
  const [itemTypesAndItemsForDelete, setItemTypesAndItemsForDelete] = useState([]);
  const [defaultRowsPerListViewPage, setDefaultRowsPerListViewPage] = useState(10);
  const [isSideFilterTelemetrySent, setIsSideFilterTelemetrySent] = useState(false);
  const [addButtonTitle] = useState(
    TrackPageConfig.addButtonTitle || (TrackPageConfig.addButton && TrackPageConfig.addButton.display) || "+ Add Item"
  );
  const [disableAddButton] = useState(
    TrackPageConfig.disableAddButton ||
      (TrackPageConfig.addButton && !TrackPageConfig.addButton.roles.includes(userProfile.getRole()))
  );
  const [useSolutionMetrics] = useState(TrackPageConfig.useSolutionMetrics || false);
  const [showTransferOrderModal, setShowTransferOrderModal] = useState(false);
  const [useAggregatedLocationMetric] = useState(TrackPageConfig.useAggregatedLocationMetric || false);
  const [showUpdateTransferOrderModal, setShowUpdateTransferOrderModal] = useState(false);
  const [selectedItemMap, setSelectedItemMap] = useState({});
  const [selectedMultiSelectAction, setSelectedMultiSelectAction] = useState(false);
  const [status, setStatus] = useState(STATUS_OPTIONS.SUCCESS);
  const [statusMessage, setStatusMessage] = useState("");

  const enabledMultiSelectOptions = useMemo(() => {
    return (TrackPageConfig.multiSelectOptions || []).filter((option) => {
      return !option.adminOnly || userProfile.isSuperAdmin;
    });
  }, [TrackPageConfig]);

  const { sendMixPanelEvent } = useMixpanelContext();

  useEffect(() => {
    sendMixPanelEvent(INVENTORY_TRACKPAGE_V2, INVENTORY_TRACKPAGE_V2_STEPS.ENTRY);

    return () => {
      if (popupTimeoutRef.current) {
        clearTimeout(popupTimeoutRef.current);
      }
    };
  }, []);

  const resetInventoryButton = {
    id: "reset-items",
    display: "Reset Items",
    onClick: () => {
      return handleResetLocationsClick();
    }
  };

  const deleteItemTypesButton = {
    id: "delete-item-types",
    display: `Delete ${pluralizeWord(itemTypeLabel || "Item Type")}`,
    onClick: () => {
      return handleDeleteItemTypesClick();
    }
  };

  const bulkUpdateItemsButton = {
    id: "bulk-update-items",
    display: "Bulk Update",
    linkRoute: `${history.location.pathname}/bulk-update`
  };

  const updateTransferOrders = {
    id: "update-transfer-orders",
    display: "Update Transfer Order",
    onClick: () => {
      setShowUpdateTransferOrderModal(true);
    }
  };

  const sidebarFeatureButtonMap = {
    "reset-items": resetInventoryButton,
    "delete-item-types": deleteItemTypesButton,
    "bulk-update-items": bulkUpdateItemsButton,
    "update-transfer-orders": updateTransferOrders
  };

  const calculateLocationsMap = (itemTypes = [], tracksItem = [], locationMapOfConsumedItemType = {}) => {
    // create itemTypeMap as calculation helper
    const allItemTypeMap = itemTypes.reduce((accumulator, eachItem) => {
      const { id } = eachItem;
      accumulator[id] = {
        ...eachItem,
        incomingItemCount: 0,
        stockItemCount: 0,
        totalItemCount: 0,
        consumedItemCount: 0,
        recentlyAddedItemCount: 0,
        typeLastUpdatedTime: null,
        totalQuantity: 0,
        totalWeight: 0,
        minStockThreshold: null,
        optimalStockThreshold: null,
        expiredItemCount: 0,
        expiringSoonItemCount: 0,
        burnStockItemCount: 0,
        onHandItemCount: 0,
        stockStatus: HEALTHY,
        expiryStatus: HEALTHY
      };
      return accumulator;
    }, {});

    // create locationMap to store items/item-type
    const allLocationMap = commonData.locations.reduce((accumulator, current) => {
      const { id, name, identifier, category } = current;
      const locationIdList = [id];
      let directParentId;
      let directParentName;
      let directParentIdentifier;

      // filter the locationTree with only the trees related to each of the location
      const filteredLocationTree = commonData.locationTree.filter((eachTree) => {
        return eachTree.includes(id);
      });
      filteredLocationTree.forEach((eachTree) => {
        const findIndex = eachTree.findIndex((eachId) => {
          return eachId === id;
        });

        // find the child location ids
        if (findIndex !== eachTree.length - 1) {
          eachTree.slice(findIndex + 1, eachTree.length).forEach((eachId) => {
            if (!locationIdList.includes(eachId)) {
              locationIdList.push(eachId);
            }
          });
        }

        // find the direct parent location
        if (eachTree[eachTree.length - 1] === id) {
          const parentLocation = commonData.locations.find((each) => {
            return each.id === eachTree[eachTree.length - 2];
          });
          if (parentLocation) {
            directParentId = parentLocation.id;
            directParentIdentifier = parentLocation.identifier;
            directParentName = parentLocation.name;
          }
        }
      });
      accumulator[id] = {
        id,
        name,
        identifier,
        category,
        childLocationIds: locationIdList,
        childLocations: [],
        directParentIdentifier,
        directParentName,
        directParentId,
        totalItemCount: 0,
        totalItemTypeCount: 0,
        recentlyAddedItemCount: 0,
        typeLastUpdatedTime: null,
        totalQuantity: 0,
        totalWeight: 0,
        consumedItemCount: 0,
        incomingItemCount: 0,
        stockItemCount: 0,
        burnStockItemCount: 0,
        onHandItemCount: 0,
        outOfStockItemTypeCount: 0,
        lowStockItemTypeCount: 0,
        burnStockItemTypeCount: 0,
        expiredItemCount: 0,
        expiringSoonItemCount: 0,
        totalLotCount: 0,
        expiredLotCount: 0,
        expiringSoonLotCount: 0,
        stockStatus: id === UNKNOWN_LOCATION_ID ? EMPTY : HEALTHY,
        expiryStatus: id === UNKNOWN_LOCATION_ID ? EMPTY : HEALTHY,
        itemTypeMap: {},
        lotNumberMap: {}
      };
      return accumulator;
    }, {});

    const filteredLocationMapOfConsumedItemType = _.cloneDeep(locationMapOfConsumedItemType);
    // filter locationMapOfConsumedItemType with side filter
    Object.keys(filteredLocationMapOfConsumedItemType).forEach((eachLocationKey) => {
      filteredLocationMapOfConsumedItemType[eachLocationKey] = filteredLocationMapOfConsumedItemType[
        eachLocationKey
      ].filter((eachItemTypeId) => {
        return (
          !sideFilterStructureMap["item-type"].idList.length ||
          sideFilterStructureMap["item-type"].idList.includes(eachItemTypeId)
        );
      });
    });

    // filter Items based on the main filter and side filter
    const allFilters = {
      ...sideFilterStructureMap,
      main: {
        idList: mainFilterIdList
      }
    };
    let filteredItems = tracksItem;
    Object.keys(allFilters).forEach((eachFilterKey) => {
      const { idList } = allFilters[eachFilterKey];
      filteredItems = filteredItems.filter((eachItem) => {
        const { tags, properties = {} } = eachItem;
        const { targetLocationId, lastKnownLocationId, isConsumed, processState } = properties;
        // Don't filter consumed items in target location mode if process state is present
        if (targetLocationBased && isConsumed && targetLocationId !== lastKnownLocationId && processState === null) {
          return false;
        }
        if (eachFilterKey === "main") {
          return !idList.length
            ? true
            : idList.every((eachId) => {
                return tags.includes(eachId);
              });
        }
        return !idList.length
          ? true
          : idList.some((eachId) => {
              return tags.includes(eachId);
            });
      });
    });

    const today = new Date().setHours(0, 0, 0, 0);
    const week = 7 * 24 * 60 * 60 * 1000;

    // put item and item type into the corresponding location
    filteredItems.forEach((eachItem) => {
      const { properties } = eachItem;
      const {
        lastKnownLocationId,
        targetLocationId,
        processStateInitialLocationId,
        processStateDetectedAtId,
        processState,
        itemTypeIdentifier,
        itemTypeId,
        hasExited,
        isConsumed,
        consumedTime,
        isRecentlyAdded,
        expiryDate,
        lotNumber = "",
        quantity,
        pounds_ts,
        lastUpdatedTime
      } = properties;

      // If the item has a process state, it is in process state mode regardless of whether target location mode is enabled
      const processStateBased = processState !== null;

      // Items with an incoming process state will be shown in their initial location as incoming. Otherwise, they will
      // be shown at the last location they were detected at that was part of the process state tracking.
      // Process state will override the target based location mode.  If neither are present, the
      // it will default to the last known location.
      const locationId = processStateDetectedAtId
        ? processState === INCOMING
          ? processStateInitialLocationId
          : processStateDetectedAtId
        : targetLocationBased
        ? targetLocationId
        : lastKnownLocationId;

      if (allLocationMap[locationId]) {
        const currentLocationObject = allLocationMap[locationId];

        // Processing Lot Number View
        const lotNumberIdentifier = `${itemTypeId},${lotNumber}`;
        if (!currentLocationObject.lotNumberMap[lotNumberIdentifier]) {
          // We are making a logic assumption that every item of the same lot and same itemType
          // would have the same expiration date
          currentLocationObject.lotNumberMap[lotNumberIdentifier] = {
            ...properties,
            id: lotNumberIdentifier,
            consumedItemCount: 0,
            totalItemCount: 0
          };
          currentLocationObject.totalLotCount += 1;

          // if Lot Number not provided, it means it is a grouping of items without lot number
          // remove value of node_start_time, expiryDate, daysToExpiry
          if (!lotNumber) {
            currentLocationObject.lotNumberMap[lotNumberIdentifier].node_start_time = null;
            currentLocationObject.lotNumberMap[lotNumberIdentifier].expiryDate = null;
            currentLocationObject.lotNumberMap[lotNumberIdentifier].daysToExpiry = null;
          }

          if (lotNumber) {
            if (expiryDate && expiryDate < today) {
              currentLocationObject.expiredLotCount += 1;
            } else if (expiryDate && expiryDate < today + week) {
              currentLocationObject.expiringSoonLotCount += 1;
            }
          }
        }

        const currentItemLotObject = currentLocationObject.lotNumberMap[lotNumberIdentifier];
        if (isConsumed) {
          currentItemLotObject.consumedItemCount += 1;
        }

        // Proccessing ItemType View
        if (!currentLocationObject.itemTypeMap[itemTypeId]) {
          currentLocationObject.itemTypeMap[itemTypeId] = {
            ...allItemTypeMap[itemTypeId]
          };
          currentLocationObject.itemTypeMap[itemTypeId].items = [];
          currentLocationObject.itemTypeMap[itemTypeId].minStockThreshold = findThreshold(
            itemTypeIdentifier,
            commonData.ruleConditions,
            locationId
          );
          currentLocationObject.totalItemTypeCount += 1;
        }

        const currentItemTypeObject = currentLocationObject.itemTypeMap[itemTypeId];

        if (processStateBased) {
          if (processState !== REMOVED) {
            currentItemTypeObject.items.push(properties);
            currentItemTypeObject.totalItemCount += 1;
            currentItemLotObject.totalItemCount += 1;

            if (quantity) {
              currentItemTypeObject.totalQuantity += quantity;
            }

            if (pounds_ts) {
              currentItemTypeObject.totalWeight += pounds_ts;
            }

            if (currentItemTypeObject.typeLastUpdatedTime < lastUpdatedTime) {
              currentItemTypeObject.typeLastUpdatedTime = lastUpdatedTime;
            }

            if (expiryDate && expiryDate < today) {
              currentItemTypeObject.expiredItemCount += 1;
            } else if (expiryDate && expiryDate < today + week) {
              currentItemTypeObject.expiringSoonItemCount += 1;
            }
          }

          switch (processState) {
            case INCOMING:
              currentItemTypeObject.incomingItemCount += 1;
              break;
            case ONHAND:
              currentItemTypeObject.stockItemCount += 1;
              break;
            case CUSTOMER_ONHAND:
              currentItemTypeObject.burnStockItemCount += 1;
              break;
          }

          if (isConsumed && consumedTime > today - week) {
            currentItemTypeObject.consumedItemCount += 1;
          }
        } else {
          if (!hasExited && (targetLocationBased || !isConsumed)) {
            currentItemTypeObject.items.push(properties);
            currentItemTypeObject.totalItemCount += 1;
            currentItemLotObject.totalItemCount += 1;

            if (quantity) {
              currentItemTypeObject.totalQuantity += quantity;
            }

            if (pounds_ts) {
              currentItemTypeObject.totalWeight += pounds_ts;
            }

            if (currentItemTypeObject.typeLastUpdatedTime < lastUpdatedTime) {
              currentItemTypeObject.typeLastUpdatedTime = lastUpdatedTime;
            }
          }

          if (!hasExited) {
            if (locationId !== UNKNOWN_LOCATION_ID) {
              // calculate counts relative to stock
              if (!isConsumed && lastKnownLocationId !== locationId) {
                currentItemTypeObject.incomingItemCount += 1;
              } else if (isConsumed) {
                if (targetLocationBased) {
                  currentItemTypeObject.burnStockItemCount += 1;
                }

                if (consumedTime > today - week) {
                  currentItemTypeObject.consumedItemCount += 1;
                }
              } else {
                currentItemTypeObject.stockItemCount += 1;
              }

              // calculate counts relative to expiry
              if (targetLocationBased || !isConsumed) {
                if (expiryDate && expiryDate < today) {
                  currentItemTypeObject.expiredItemCount += 1;
                } else if (expiryDate && expiryDate < today + week) {
                  currentItemTypeObject.expiringSoonItemCount += 1;
                }
              }
            }
          } else if (consumedTime > today - week) {
            currentItemTypeObject.consumedItemCount += 1;
          }
        }

        if (currentItemLotObject.totalItemCount == 0) {
          delete currentLocationObject.lotNumberMap[lotNumberIdentifier];

          currentLocationObject.totalLotCount--;

          if (lotNumber) {
            if (expiryDate && expiryDate < today) {
              currentLocationObject.expiredLotCount--;
            } else if (expiryDate && expiryDate < today + week) {
              currentLocationObject.expiringSoonLotCount--;
            }
          }
        }

        if (!currentLocationObject.items) {
          currentLocationObject.items = [];
        }
        currentLocationObject.items.push(properties);

        if (isRecentlyAdded) {
          currentItemTypeObject.recentlyAddedItemCount += 1;
        }
      }
    });

    let processedChildLocationsList = Object.keys(allLocationMap)
      .filter((eachLocationKey) => {
        return allLocationMap[eachLocationKey].childLocationIds.length === 1;
      })
      .map((eachLocationKey) => {
        if (filteredLocationMapOfConsumedItemType[eachLocationKey]) {
          filteredLocationMapOfConsumedItemType[eachLocationKey].forEach((eachItemTypeId) => {
            if (!allLocationMap[eachLocationKey].itemTypeMap) {
              allLocationMap[eachLocationKey].itemTypeMap = {};
            }
            if (!allLocationMap[eachLocationKey].itemTypeMap[eachItemTypeId]) {
              allLocationMap[eachLocationKey].itemTypeMap[eachItemTypeId] = {
                ...allItemTypeMap[eachItemTypeId]
              };
              allLocationMap[eachLocationKey].totalItemTypeCount += 1;
            }
          });
        }

        const { itemTypeMap = {} } = allLocationMap[eachLocationKey];

        Object.keys(itemTypeMap).forEach((eachItemTypeId) => {
          const {
            expiredItemCount,
            expiringSoonItemCount,
            stockItemCount,
            incomingItemCount,
            minStockThreshold,
            totalItemCount,
            consumedItemCount,
            recentlyAddedItemCount,
            burnStockItemCount
          } = itemTypeMap[eachItemTypeId];

          itemTypeMap[eachItemTypeId].onHandItemCount += stockItemCount + burnStockItemCount;
          const { onHandItemCount } = itemTypeMap[eachItemTypeId];

          calculateStatus(itemTypeMap[eachItemTypeId], eachLocationKey, allLocationMap);

          if (eachLocationKey !== UNKNOWN_LOCATION_ID) {
            allLocationMap[eachLocationKey].recentlyAddedItemCount += recentlyAddedItemCount;
            allLocationMap[eachLocationKey].burnStockItemCount += burnStockItemCount;
            allLocationMap[eachLocationKey].onHandItemCount += onHandItemCount;
            allLocationMap[eachLocationKey].expiringSoonItemCount += expiringSoonItemCount;
            allLocationMap[eachLocationKey].expiredItemCount += expiredItemCount;
            allLocationMap[eachLocationKey].stockItemCount += stockItemCount;
            allLocationMap[eachLocationKey].incomingItemCount += incomingItemCount;

            if (!onHandItemCount) {
              allLocationMap[eachLocationKey].outOfStockItemTypeCount += 1;
            } else if (minStockThreshold && onHandItemCount <= minStockThreshold) {
              allLocationMap[eachLocationKey].lowStockItemTypeCount += 1;
            }

            if (burnStockItemCount > 0) {
              allLocationMap[eachLocationKey].burnStockItemTypeCount += 1;
            }
          }

          allLocationMap[eachLocationKey].consumedItemCount += consumedItemCount;
          allLocationMap[eachLocationKey].totalItemCount += totalItemCount;
        });
        return allLocationMap[eachLocationKey];
      });

    while (processedChildLocationsList.length) {
      let parentLocationsList = [];
      processedChildLocationsList.forEach((eachChildLocation) => {
        const { id: childLocationId, directParentId } = eachChildLocation;
        let parentIndex = parentLocationsList.findIndex((each) => {
          return each.id === directParentId;
        });
        if (parentIndex === -1) {
          if (allLocationMap[directParentId]) {
            parentLocationsList.push(allLocationMap[directParentId]);
            parentIndex = parentLocationsList.length - 1;
          }
        }

        if (parentIndex !== -1) {
          const {
            expiredItemCount,
            expiringSoonItemCount,
            outOfStockItemTypeCount,
            onHandItemCount,
            burnStockItemTypeCount,
            lowStockItemTypeCount,
            incomingItemCount,
            stockItemCount,
            totalItemCount,
            totalItemTypeCount,
            expiryStatus,
            stockStatus,
            consumedItemCount,
            recentlyAddedItemCount,
            burnStockItemCount,
            totalLotCount,
            expiredLotCount,
            expiringSoonLotCount
          } = eachChildLocation;

          if (healthPriorityMap[expiryStatus] < healthPriorityMap[parentLocationsList[parentIndex].expiryStatus]) {
            parentLocationsList[parentIndex].expiryStatus = expiryStatus;
          }

          if (healthPriorityMap[stockStatus] < healthPriorityMap[parentLocationsList[parentIndex].stockStatus]) {
            parentLocationsList[parentIndex].stockStatus = stockStatus;
          }

          parentLocationsList[parentIndex].burnStockItemCount += burnStockItemCount;
          parentLocationsList[parentIndex].recentlyAddedItemCount += recentlyAddedItemCount;
          parentLocationsList[parentIndex].consumedItemCount += consumedItemCount;
          parentLocationsList[parentIndex].expiredItemCount += expiredItemCount;
          parentLocationsList[parentIndex].expiringSoonItemCount += expiringSoonItemCount;
          parentLocationsList[parentIndex].outOfStockItemTypeCount += outOfStockItemTypeCount;
          parentLocationsList[parentIndex].burnStockItemTypeCount += burnStockItemTypeCount;
          parentLocationsList[parentIndex].onHandItemCount += onHandItemCount;
          parentLocationsList[parentIndex].lowStockItemTypeCount += lowStockItemTypeCount;
          parentLocationsList[parentIndex].incomingItemCount += incomingItemCount;
          parentLocationsList[parentIndex].stockItemCount += stockItemCount;
          parentLocationsList[parentIndex].totalItemCount += totalItemCount;
          parentLocationsList[parentIndex].totalItemTypeCount += totalItemTypeCount;
          parentLocationsList[parentIndex].totalLotCount += totalLotCount;
          parentLocationsList[parentIndex].expiredLotCount += expiredLotCount;
          parentLocationsList[parentIndex].expiringSoonLotCount += expiringSoonLotCount;

          if (directParentId === parentLocationsList[parentIndex].id) {
            parentLocationsList[parentIndex].childLocations.push(eachChildLocation);
          }
        }
        allLocationMap[childLocationId] = eachChildLocation;
      });
      processedChildLocationsList = parentLocationsList;
      parentLocationsList = [];
    }

    return allLocationMap;
  };

  const convertItemTypeProperties = (itemType, itemTypeRefMap) => {
    const { properties: propertiesJson, result: resultJson, param: paramJson } = itemType;
    const properties = JSON.parse(propertiesJson);
    const result = JSON.parse(resultJson);
    const param = JSON.parse(paramJson);
    const itemTypeId = properties.id;

    if (!itemTypeRefMap[itemTypeId]) {
      itemTypeRefMap[itemTypeId] = properties;
    }

    return {
      ...properties,
      optimalStockThreshold: param.optimalStockThreshold,
      minStockThreshold: param.minStockThreshold,
      refillQuantity: result.refillQuantityCount,
      expiredItemCount: result.expiredCount,
      expiringSoonItemCount: result.expiringSoonCount,
      incomingItemCount: result.incomingCount,
      stockItemCount: result.stockCount,
      burnStockItemCount: result.customerOnHandCount,
      totalItemCount: result.totalCount,
      consumedItemCount: result.consumedCount,
      onHandItemCount: result.onHandCount,
      missingItemCount: result.missingCount,
      recentlyAddedItemCount: result.recentlyAddedCount
    };
  };

  const convertLotProperties = (lot) => {
    const { properties: propertiesJson, result: resultJson } = lot;
    const properties = JSON.parse(propertiesJson);
    const result = JSON.parse(resultJson);
    return {
      id: properties.id,
      expiredItemCount: result.expiredCount,
      expiringSoonItemCount: result.expiringSoonCount,
      itemTypeId: properties.itemTypeId,
      itemTypeIdentifier: properties.itemTypeIdentifier,
      lotNumber: properties.lotNumber,
      totalItemCount: result.totalCount,
      consumedItemCount: result.consumedCount,
      expiryDate: properties.expiryDate,
      daysToExpiry: properties.expiryDate ? Math.floor((properties.expiryDate - Date.now()) / DAY_IN_MS) : null
    };
  };

  const convertItemProperties = (item, allLocationMap, locationTree, itemTypeRefMap, filterMap) => {
    const { properties: propertiesJson, result: resultJson, groupKey } = item;
    const properties = JSON.parse(propertiesJson);
    const {
      nodeStartTime,
      id: itemId,
      identifier: itemIdentifier,
      expiryDate,
      sensorProfileVid,
      isConsumed,
      state: processState,
      eventTime,
      initialLocationId: processStateInitialLocationId,
      itemTypeId,
      ...extraProperties
    } = properties;
    const result = JSON.parse(resultJson);
    const detectedAtLocation = allLocationMap[properties.detectedAtId];
    const initialLocation = allLocationMap[properties.initialLocationId];
    const tags = getGroupByTags(groupKey, locationTree, itemTypeId);

    const itemProperties = {
      node_start_time: nodeStartTime,
      id: itemId,
      identifier: itemIdentifier,
      expiryDate,
      daysToExpiry: expiryDate ? Math.floor((expiryDate - Date.now()) / DAY_IN_MS) : null,
      rfidSerial: sensorProfileVid,
      lot_number: properties.lotNumber, // Backwards compat so customer config doesn't have to change
      locationId: groupKey,
      itemTypeId,
      eventTime,
      ...extraProperties,
      lastKnownLocationId: detectedAtLocation?.id,
      lastKnownLocationName: detectedAtLocation?.name,
      lastKnownLocationIdentifier: detectedAtLocation?.identifier,
      targetLocationId: initialLocation?.id,
      targetLocationName: initialLocation?.name,
      targetLocationIdentifier: initialLocation?.identifier,
      processStateDetectedAtId: detectedAtLocation?.id,
      processStateDetectedAtName: detectedAtLocation?.name,
      processStateDetectedAtIdentifier: detectedAtLocation?.identifier,
      processState,
      processStateInitialLocationId,
      isConsumed,
      isConsumedDisplay: isConsumed ? "Yes" : "No",
      isRecentlyAdded: result.isRecentlyAdded,
      lastUpdatedTime: eventTime
    };

    Object.keys(filterConfig).forEach((view) => {
      const viewConfig = filterConfig[view];
      viewConfig.forEach((property) => {
        const { id: propertyName, propertyFor } = property;

        if (!filterMap[propertyName]) {
          filterMap[propertyName] = [];
        }

        switch (propertyFor) {
          case "itemTypes":
            const currItemType = itemTypeRefMap[itemTypeId];
            if (currItemType[propertyName]) {
              if (!filterMap[propertyName].includes(currItemType[propertyName])) {
                filterMap[propertyName].push(currItemType[propertyName]);
              }
              tags.push(propertyName + currItemType[propertyName]);
            }
            break;
          default:
            if (itemProperties[propertyName]) {
              if (!filterMap[propertyName].includes(itemProperties[propertyName])) {
                filterMap[propertyName].push(itemProperties[propertyName]);
              }
              tags.push(propertyName + itemProperties[propertyName]);
            }
            break;
        }
      });
    });

    return {
      properties: itemProperties,
      tags
    };
  };

  const calculateLocationsMapFromMetrics = (params) => {
    const { items = [], itemTypes = [], lots = [], item = [], itemType = [], lot = [], filterMap = {} } = params;

    const itemsMap = items.reduce((accum, metrics) => {
      accum[metrics.groupKey] = metrics;
      return accum;
    }, {});

    const itemTypesMap = itemTypes.reduce((accum, metrics) => {
      accum[metrics.groupKey] = metrics;
      return accum;
    }, {});

    const lotsMap = lots.reduce((accum, metrics) => {
      accum[metrics.groupKey] = metrics;
      return accum;
    }, {});

    const { locations, locationTree } = commonData;

    const allLocationMap = commonData.locations.reduce((accumulator, current) => {
      const { id, name, identifier, category } = current;
      const { directParentId, directParentIdentifier, directParentName, locationIdList } = getDirectParent(
        current,
        locations,
        locationTree
      );

      const itemMetrics = itemsMap[id];
      const itemTypeMetrics = itemTypesMap[id];
      const lotMetrics = lotsMap[id];

      const { result: itemResult = "{}" } = itemMetrics || {};
      const {
        incomingCount: incomingItemCount,
        recentlyAddedCount: recentlyAddedItemCount,
        expiringSoonCount: expiringSoonItemCount,
        expiredCount: expiredItemCount,
        totalCount: totalItemCount,
        customerOnHandCount: burnStockItemCount,
        consumedCount: consumedItemCount,
        onHandCount: onHandItemCount,
        stockCount: stockItemCount,
        missingCount: missingItemCount,
        totalQuantity,
        totalWeight
      } = JSON.parse(itemResult);

      const { result: itemTypeResult = "{}" } = itemTypeMetrics || {};
      const {
        totalCount: totalItemTypeCount,
        outOfStockCount: outOfStockItemTypeCount,
        lowStockCount: lowStockItemTypeCount,
        customerOnHandCount: burnStockItemTypeCount
      } = JSON.parse(itemTypeResult);

      const { result: lotResult = "{}" } = lotMetrics || {};

      const {
        totalCount: totalLotCount,
        expiredCount: expiredLotCount,
        expiringSoonCount: expiringSoonLotCount
      } = JSON.parse(lotResult);

      accumulator[id] = {
        id,
        name,
        identifier,
        category,
        childLocationIds: locationIdList,
        childLocations: [],
        directParentIdentifier,
        directParentName,
        directParentId,
        totalItemCount,
        totalItemTypeCount,
        recentlyAddedItemCount,
        typeLastUpdatedTime: null,
        totalQuantity,
        totalWeight,
        consumedItemCount,
        incomingItemCount,
        stockItemCount,
        burnStockItemCount,
        onHandItemCount,
        missingItemCount,
        outOfStockItemTypeCount,
        lowStockItemTypeCount,
        burnStockItemTypeCount,
        expiredItemCount,
        expiringSoonItemCount,
        totalLotCount,
        expiredLotCount,
        expiringSoonLotCount,
        stockStatus: id === UNKNOWN_LOCATION_ID ? EMPTY : HEALTHY,
        expiryStatus: id === UNKNOWN_LOCATION_ID ? EMPTY : HEALTHY,
        itemTypeMap: {},
        lotNumberMap: {}
      };

      return accumulator;
    }, {});

    const itemTypeRefMap = {};
    itemType.forEach((metrics) => {
      const locationId = metrics.groupKey;
      const convertedItemType = convertItemTypeProperties(metrics, itemTypeRefMap);
      const itemTypeId = convertedItemType.id;

      if (filterItemType(itemTypeId)) {
        return;
      }

      if (allLocationMap[locationId]) {
        allLocationMap[locationId].itemTypeMap[itemTypeId] = convertedItemType;
      }
    });

    lot.forEach((metrics) => {
      const locationId = metrics.groupKey;
      const convertedLot = convertLotProperties(metrics, allLocationMap);
      const { id: lotId, itemTypeId } = convertedLot;

      if (filterItemType(itemTypeId)) {
        return;
      }

      // Empty lots are not displayed
      if (convertedLot.totalItemCount === 0) {
        return;
      }

      if (allLocationMap[locationId]) {
        allLocationMap[locationId].lotNumberMap[lotId] = convertedLot;
      }
    });

    const allItems = item.map((metrics) => {
      return convertItemProperties(metrics, allLocationMap, locationTree, itemTypeRefMap, filterMap);
    });

    filterItems(allItems).forEach((item) => {
      const { properties } = item;
      const { locationId, itemTypeId } = properties;

      if (allLocationMap[locationId]) {
        if (!allLocationMap[locationId].items) {
          allLocationMap[locationId].items = [];
        }
        allLocationMap[locationId].items.push(properties);
        if (allLocationMap[locationId].itemTypeMap[itemTypeId]) {
          if (!allLocationMap[locationId].itemTypeMap[itemTypeId].items) {
            allLocationMap[locationId].itemTypeMap[itemTypeId].items = [];
          }
          allLocationMap[locationId].itemTypeMap[itemTypeId].items.push(properties);
        }
      }
    });

    Object.keys(allLocationMap).forEach((locationId) => {
      Object.keys(allLocationMap[locationId].itemTypeMap).forEach((itemTypeId) => {
        const itemType = allLocationMap[locationId].itemTypeMap[itemTypeId];
        calculateStatus(itemType, locationId, allLocationMap);
      });
    });

    let processedChildLocationsList = Object.keys(allLocationMap)
      .filter((eachLocationKey) => {
        return allLocationMap[eachLocationKey].childLocationIds.length === 1;
      })
      .map((eachLocationKey) => {
        return allLocationMap[eachLocationKey];
      });

    if (useAggregatedLocationMetric) {
      Object.keys(allLocationMap)
        .filter((eachLocationId) => {
          return allLocationMap[eachLocationId].childLocationIds.length > 1;
        })
        .forEach((eachLocationId) => {
          allLocationMap[eachLocationId] = {
            ...allLocationMap[eachLocationId],
            totalItemCount: 0,
            totalItemTypeCount: 0,
            recentlyAddedItemCount: 0,
            totalQuantity: 0,
            totalWeight: 0,
            consumedItemCount: 0,
            incomingItemCount: 0,
            stockItemCount: 0,
            burnStockItemCount: 0,
            onHandItemCount: 0,
            missingItemCount: 0,
            outOfStockItemTypeCount: 0,
            lowStockItemTypeCount: 0,
            burnStockItemTypeCount: 0,
            expiredItemCount: 0,
            expiringSoonItemCount: 0,
            totalLotCount: 0,
            expiredLotCount: 0,
            expiringSoonLotCount: 0
          };
        });
    }

    while (processedChildLocationsList.length) {
      let parentLocationsList = [];
      processedChildLocationsList.forEach((eachChildLocation) => {
        const { id: childLocationId, directParentId } = eachChildLocation;
        let parentIndex = parentLocationsList.findIndex((each) => {
          return each.id === directParentId;
        });

        if (parentIndex === -1) {
          if (allLocationMap[directParentId]) {
            parentLocationsList.push(allLocationMap[directParentId]);
            parentIndex = parentLocationsList.length - 1;
          }
        }

        if (parentIndex !== -1) {
          const { expiryStatus, stockStatus } = eachChildLocation;

          if (healthPriorityMap[expiryStatus] < healthPriorityMap[parentLocationsList[parentIndex].expiryStatus]) {
            parentLocationsList[parentIndex].expiryStatus = expiryStatus;
          }

          if (healthPriorityMap[stockStatus] < healthPriorityMap[parentLocationsList[parentIndex].stockStatus]) {
            parentLocationsList[parentIndex].stockStatus = stockStatus;
          }

          if (directParentId === parentLocationsList[parentIndex].id) {
            parentLocationsList[parentIndex].childLocations.push(eachChildLocation);
          }
          if (useAggregatedLocationMetric) {
            const newParentLocation = {
              ...allLocationMap[directParentId],
              totalItemCount: allLocationMap[directParentId].totalItemCount + eachChildLocation.totalItemCount,
              totalItemTypeCount:
                allLocationMap[directParentId].totalItemTypeCount + eachChildLocation.totalItemTypeCount,
              recentlyAddedItemCount:
                allLocationMap[directParentId].recentlyAddedItemCount + eachChildLocation.recentlyAddedItemCount,
              totalQuantity: allLocationMap[directParentId].totalQuantity + eachChildLocation.totalQuantity,
              totalWeight: allLocationMap[directParentId].totalWeight + eachChildLocation.totalWeight,
              consumedItemCount: allLocationMap[directParentId].consumedItemCount + eachChildLocation.consumedItemCount,
              incomingItemCount: allLocationMap[directParentId].incomingItemCount + eachChildLocation.incomingItemCount,
              stockItemCount: allLocationMap[directParentId].stockItemCount + eachChildLocation.stockItemCount,
              burnStockItemCount:
                allLocationMap[directParentId].burnStockItemCount + eachChildLocation.burnStockItemCount,
              onHandItemCount: allLocationMap[directParentId].onHandItemCount + eachChildLocation.onHandItemCount,
              missingItemCount: allLocationMap[directParentId].missingItemCount + eachChildLocation.missingItemCount,
              outOfStockItemTypeCount:
                allLocationMap[directParentId].outOfStockItemTypeCount + eachChildLocation.outOfStockItemTypeCount,
              lowStockItemTypeCount:
                allLocationMap[directParentId].lowStockItemTypeCount + eachChildLocation.lowStockItemTypeCount,
              burnStockItemTypeCount:
                allLocationMap[directParentId].burnStockItemTypeCount + eachChildLocation.burnStockItemTypeCount,
              expiredItemCount: allLocationMap[directParentId].expiredItemCount + eachChildLocation.expiredItemCount,
              expiringSoonItemCount:
                allLocationMap[directParentId].expiringSoonItemCount + eachChildLocation.expiringSoonItemCount,
              totalLotCount: allLocationMap[directParentId].totalLotCount + eachChildLocation.totalLotCount,
              expiredLotCount: allLocationMap[directParentId].expiredLotCount + eachChildLocation.expiredLotCount,
              expiringSoonLotCount:
                allLocationMap[directParentId].expiringSoonLotCount + eachChildLocation.expiringSoonLotCount
            };
            parentLocationsList[parentIndex] = newParentLocation;
            allLocationMap[directParentId] = newParentLocation;
          }
        }

        allLocationMap[childLocationId] = eachChildLocation;
      });
      processedChildLocationsList = parentLocationsList;
      parentLocationsList = [];
    }

    return allLocationMap;
  };

  const getGroupByTags = (locationId, locationTree, itemTypeId) => {
    let tags = [];
    if (locationId) {
      const eligibleTrees = [];
      // check if the current location has parents
      locationTree.forEach((locationTree) => {
        if (locationTree.includes(locationId)) {
          eligibleTrees.push(locationTree);
        }
      });
      if (eligibleTrees.length) {
        // find the lowest possible tier (highest index) of the location in eligible trees
        let highestIdx = 0;
        let treeIdx = 0;
        eligibleTrees.forEach((eligibleTree, eligibleTreeIdx) => {
          const idx = eligibleTree.indexOf(locationId);
          if (idx > highestIdx) {
            highestIdx = idx;
            treeIdx = eligibleTreeIdx;
          }
        });
        // push current location and all locations above it
        tags = tags.concat(eligibleTrees[treeIdx].slice(0, highestIdx + 1));
      }
      // orphan location
      else {
        tags.push(locationId);
      }
    } else {
      tags.push(UNKNOWN_LOCATION_ID);
    }

    if (itemTypeId) {
      tags.push(itemTypeId);
    }

    return tags;
  };

  const findItemTypeInMetrics = (itemTypeId, metrics) => {
    const { itemType } = metrics;
    const foundType = itemType?.find((eachItemType) => {
      const { properties: propertiesJson } = eachItemType;
      const properties = JSON.parse(propertiesJson);
      return properties.id === itemTypeId;
    });
    if (foundType) {
      return {
        ...foundType,
        properties: JSON.parse(foundType.properties)
      };
    }
    return null;
  };

  const getItemTypeCheckBoxList = (data, filterIds) => {
    const { itemTypes = [], itemType = [] } = data;
    if (useSolutionMetrics) {
      const itemTypesMap = {};
      itemType.forEach((eachItemType) => {
        const { properties: propertiesJson } = eachItemType;
        const properties = JSON.parse(propertiesJson);
        const { id, identifier } = properties;
        if (!itemTypesMap[id]) {
          itemTypesMap[id] = { id, label: identifier, value: filterIds.includes(id) };
        }
      });
      return Object.values(itemTypesMap);
    }
    return itemTypes.map((each) => {
      const { id, identifier } = each;
      return { id, label: identifier, value: filterIds.includes(id) };
    });
  };

  const getExportCsvItemTypeHeaders = () => {
    /* to use item type list view config to get item type headers to be sent
     * to InventoryTrackPageComponentV2 as additional attributes to export in CSV
     * ONLY when in the "Item" view.
     */
    if (
      currentViewTab === VIEW_TABS.ALL_ITEMS &&
      TrackPageConfig &&
      TrackPageConfig.exportItemTypeAttributesInCSV &&
      TrackPageConfig.listViewConfig &&
      TrackPageConfig.listViewConfig["item-type"] &&
      TrackPageConfig.listViewConfig["item-type"].stock
    ) {
      const itemTypeListViewHeaderConfig = TrackPageConfig.listViewConfig["item-type"].stock || {};
      const itemListViewConfig = TrackPageConfig.listViewConfig["all-items"] || {};
      /* offset is used to set the index of the item type attributes so they appear
         after the item attributes when exported as CSV */
      const offset = Object.keys(itemListViewConfig).length;

      return Object.keys(itemTypeListViewHeaderConfig || {})
        .sort((a, b) => {
          return itemTypeListViewHeaderConfig[a].index - itemTypeListViewHeaderConfig[b].index;
        })
        .map((eachId, index) => {
          const itemTypeId = `itemType-${eachId}`;
          const tempObject = { ...itemTypeListViewHeaderConfig[eachId] };
          // set new index value to order the columns in CSV file
          tempObject.index = index + offset;
          return { id: itemTypeId, ...tempObject };
        });
    }
    return null;
  };

  const calculateData = (sourceData = null) => {
    const overviewHelperData = {
      totalItemCount: 0,
      lowStockItemTypeCount: 0,
      outOfStockItemTypeCount: 0,
      burnStockItemTypeCount: 0,
      expiredItemCount: 0,
      expiringSoonItemCount: 0,
      consumedItemCount: 0,
      recentlyAddedItemCount: 0,
      stockItemCount: 0,
      totalItemTypeCount: 0
    };
    // calculate the health color indicator for header
    const getHealthColor = (eachLocationData) => {
      const { stockStatus, expiryStatus } = eachLocationData;
      let healthColor;
      if (statusTab === "stock") {
        healthColor = healthColorMap[stockStatus];
      } else if (statusTab === "expiration") {
        healthColor = healthColorMap[expiryStatus];
      }
      return healthColor;
    };

    // calculate the header title for header
    const getHeaderTitle = (eachLocationData) => {
      const { name, directParentName, category } = eachLocationData;
      const locationName = eachLocationData[locationDisplayLabelMap[category]];
      return `${locationName || name}${directParentName ? ` (${directParentName})` : ""}`;
    };

    const getDataList = (eachLocationData, locationFilterKey) => {
      const { items = [], itemTypeMap = {}, lotNumberMap = {} } = eachLocationData;

      if (currentViewTab === VIEW_TABS.ALL_ITEMS) {
        const itemTypeListViewConfig = getValueOrDefault(() => {
          return TrackPageConfig.listViewConfig["item-type"].stock;
        }, {});
        const tempItems = [];
        items.forEach((item, i) => {
          if (!item.hasExited && (targetLocationBased || item.processState !== null || !item.isConsumed)) {
            /* add item type attributes into the item object if config specifies
               to include item type attributes while exporting CSV */
            if (
              TrackPageConfig &&
              TrackPageConfig.exportItemTypeAttributesInCSV &&
              item.itemTypeId &&
              itemTypeMap[item.itemTypeId] &&
              typeof itemTypeMap[item.itemTypeId] === "object"
            ) {
              const tempItemObject = { ...item };
              Object.keys(itemTypeListViewConfig).forEach((attributeId, i) => {
                /* To prevent item object's values being overwritten by item type
                 * object's values when both objects have one or more same keys,
                 * append "itemType-" prefix to the item type object's keys
                 * while adding them to the item object. Ex: "identifier" */
                tempItemObject[`itemType-${attributeId}`] = itemTypeMap[item.itemTypeId][attributeId];
              });
              tempItems.push({ ...tempItemObject });
            } else {
              tempItems.push({ ...item });
            }
          }
        });

        return [...tempItems];
      }

      if (currentViewTab === VIEW_TABS.ITEM_LOT) {
        return Object.keys(lotNumberMap).map((eachLotIdenitifer) => {
          return { ...lotNumberMap[eachLotIdenitifer], locationFilterKey };
        });
      }

      return Object.keys(itemTypeMap).map((eachItemTypeId) => {
        return { ...itemTypeMap[eachItemTypeId], locationFilterKey };
      });
    };

    const getSubHeaderList = (eachLocationData, parentIdList) => {
      const { childLocations } = eachLocationData;
      return childLocations.map((eachChildLocation) => {
        const { id } = eachChildLocation;
        const headerId = `${!!parentIdList.length && `${parentIdList.join()},`}${id}`;

        return {
          id: headerId,
          headerTitle: getHeaderTitle(eachChildLocation),
          healthColor: getHealthColor(eachChildLocation),
          metricsList: getContentMetricsMetadata(eachChildLocation),
          subHeaderList: getSubHeaderList(eachChildLocation, parentIdList.concat(id)),
          dataList: getDataList(eachChildLocation, headerId)
        };
      });
    };

    const { idList: locationIdList = [] } = sideFilterStructureMap.location || {};
    const currentLocationId = mainFilterIdList.length && mainFilterIdList[0];
    const { childLocationIds = [] } = sourceData[currentLocationId] || {};
    const dataList = Object.keys(sourceData)
      .filter((eachLocationKey) => {
        return (
          !mainFilterIdList.length ||
          (childLocationIds.includes(eachLocationKey) &&
            (childLocationIds.length === 1 || eachLocationKey !== currentLocationId))
        );
      })
      .filter((eachLocationKey) => {
        return !locationIdList.length || locationIdList.includes(eachLocationKey);
      })
      .map((eachLocationKey) => {
        const locationData = sourceData[eachLocationKey];
        const { id } = locationData;

        let hide = false;
        if (!mainFilterIdList && locationData.directParentId) {
          hide = true;
        } else if (mainFilterIdList[0] !== locationData.directParentId && mainFilterIdList[0] !== locationData.id) {
          hide = true;
        }
        if (locationIdList.includes(eachLocationKey)) {
          hide = false;
        }

        return {
          id,
          headerTitle: getHeaderTitle(locationData),
          healthColor: getHealthColor(locationData),
          metricsList: getContentMetricsMetadata(locationData),
          subHeaderList: getSubHeaderList(locationData, [id]),
          dataList: getDataList(locationData, id),
          hide
        };
      });

    Object.keys(sourceData)
      .filter((eachLocationKey) => {
        return !mainFilterIdList.length || childLocationIds.includes(eachLocationKey);
      })
      .filter((eachLocationKey) => {
        if (locationIdList.length) {
          return locationIdList.includes(eachLocationKey);
        }
        return sourceData[eachLocationKey].childLocationIds.length === 1 && eachLocationKey !== UNKNOWN_LOCATION_ID;
      })
      .forEach((eachLocationKey) => {
        const {
          consumedItemCount,
          expiredItemCount,
          stockItemCount,
          totalItemTypeCount,
          recentlyAddedItemCount,
          expiringSoonItemCount,
          lowStockItemTypeCount,
          outOfStockItemTypeCount,
          burnStockItemTypeCount,
          totalItemCount
        } = sourceData[eachLocationKey];

        if (eachLocationKey !== UNKNOWN_LOCATION_ID) {
          overviewHelperData.lowStockItemTypeCount += lowStockItemTypeCount;
          overviewHelperData.outOfStockItemTypeCount += outOfStockItemTypeCount;
          overviewHelperData.burnStockItemTypeCount += burnStockItemTypeCount;
          overviewHelperData.expiringSoonItemCount += expiringSoonItemCount;
          overviewHelperData.consumedItemCount += consumedItemCount;
          overviewHelperData.expiredItemCount += expiredItemCount;
          overviewHelperData.stockItemCount += stockItemCount;
        }
        overviewHelperData.recentlyAddedItemCount += recentlyAddedItemCount;
        overviewHelperData.totalItemTypeCount += totalItemTypeCount;
        overviewHelperData.totalItemCount += totalItemCount;
      });

    const {
      totalItemCount,
      lowStockItemTypeCount,
      outOfStockItemTypeCount,
      burnStockItemTypeCount,
      expiredItemCount,
      expiringSoonItemCount,
      consumedItemCount,
      stockItemCount,
      totalItemTypeCount,
      recentlyAddedItemCount
    } = overviewHelperData;

    const overviewData = {
      averageConsumptionRate: `${(consumedItemCount / 7).toFixed(2)} unit(s)/day`,
      averageStockCount: totalItemTypeCount ? (stockItemCount / totalItemTypeCount).toFixed(2) : 0,
      expiredCount: expiredItemCount,
      totalStockCount: stockItemCount,
      totalItemCount,
      lowStockItemTypeCount,
      expiredItemCount,
      expiringSoonItemCount,
      outOfStockItemTypeCount,
      burnStockItemTypeCount,
      consumedItemCount,
      recentlyAddedItemCount,
      totalItemTypeCount
    };

    const overviewDataList = (Object.keys(metricsMap) || [])
      .sort((a, b) => {
        return metricsMap[a].index - metricsMap[b].index;
      })
      .map((eachKey) => {
        return {
          id: eachKey,
          title: metricsMap[eachKey].label,
          value: overviewData[eachKey] || "--"
        };
      });

    let chartDataList =
      statusTab === "expiration"
        ? [
            ["Status", "Items"],
            ["Expired", expiredItemCount],
            ["Expiring Soon", expiringSoonItemCount],
            ["Good", totalItemCount - expiredItemCount - expiringSoonItemCount]
          ]
        : [
            ["Status", "Items"],
            ["Out of Stock", outOfStockItemTypeCount],
            ["Low Stock", lowStockItemTypeCount],
            ["Good", totalItemTypeCount - outOfStockItemTypeCount - lowStockItemTypeCount]
          ];

    if (
      currentLocationId === UNKNOWN_LOCATION_ID ||
      (locationIdList.length === 1 && locationIdList.includes(UNKNOWN_LOCATION_ID))
    ) {
      chartDataList = [
        ["Status", "items"],
        ["", 0],
        ["", 0],
        ["", 0],
        ["Not Applicable", 1]
      ];
    }

    return { dataList, overviewDataList, chartDataList };
  };

  useEffect(() => {
    let locations = commonData?.locations.filter((location) => {
      return location.id !== UNKNOWN_LOCATION_ID;
    });
    if (locations) {
      locations = naturalSort(locations, "name");
    }
    if (resetItemsFilterInput) {
      const lowerCaseFilterInput = resetItemsFilterInput.toLowerCase();
      locations = locations.filter((location) => {
        return location.name.toLowerCase().includes(lowerCaseFilterInput);
      });
    }
    setLocationsListToReset(locations);
  }, [resetItemsFilterInput, commonData, showResetItemsModalFlag]);

  useEffect(() => {
    if (showDeleteItemTypesModalFlag) {
      setLoadingItemTypesFlag(true);
      loadItemTypesAndItemsToDelete();
    }
  }, [showDeleteItemTypesModalFlag]);

  const loadItemTypesAndItemsToDelete = async () => {
    if (!itemTypesAndItemsForDelete.length) {
      let itemTypesListToDelete = await ItemTypeClient.listItemTypesAndItems("Inventory", ["Inventory"]);
      itemTypesListToDelete = naturalSort(itemTypesListToDelete, "identifier");
      setItemTypesAndItemsForDelete(itemTypesListToDelete);
    }
  };

  useEffect(() => {
    const lowerCaseFilterInput = deleteItemTypesFilterInput.toLowerCase();
    const filteredItemTypesAndItemsForDelete = itemTypesAndItemsForDelete.filter((itemType) => {
      return itemType.identifier.toLowerCase().includes(lowerCaseFilterInput);
    });
    setItemTypeListToDelete(filteredItemTypesAndItemsForDelete);
    setLoadingItemTypesFlag(false);
  }, [itemTypesAndItemsForDelete, deleteItemTypesFilterInput]);

  /**
   * Once the overview/metrics response data is processed, calculate the display data.
   * Update the header metrics with the final values and status color.
   *
   * The header and overall metrics are calculated base on the currentData which never gets updated
   * I believe that everywhere in the metrics works is using the overallOverviewData object
   * TODO: Merge maybe have the metrics calculate base on the overallOverviewData.
   */
  useEffect(() => {
    if (Object.keys(currentData).length) {
      const { dataList, overviewDataList, chartDataList } = calculateData(currentData);
      const getMetrics = (headers) => {
        let newMetrics = {};
        (headers || []).forEach((header) => {
          newMetrics[header.id] = { healthColor: header.healthColor };
          header.metricsList.forEach((eachMetric) => {
            newMetrics[header.id][eachMetric.id] = eachMetric?.value;
          });
          newMetrics = { ...newMetrics, ...getMetrics(header.subHeaderList) };
        });
        return newMetrics;
      };
      setContentMetricsData(getMetrics(dataList));
      setOverviewMetricsDataList(overviewDataList);
      setOverviewChartDataList(chartDataList);
    }
  }, [headerMetricsMap, targetLocationBased, currentViewTab, mainFilterIdList, currentData]);

  /**
   * Once the overview/metrics data is loaded, process the data into the locations map
   */
  useEffect(() => {
    if (Object.keys(overallOverviewData).length && commonData && Object.keys(sideFilterStructureMap).length) {
      if (useSolutionMetrics) {
        setCurrentData(calculateLocationsMapFromMetrics(overallOverviewData));
      } else {
        const { itemTypes = [], tracksItem = [], locationMapOfConsumedItemType = {} } = overallOverviewData;
        setCurrentData(calculateLocationsMap(itemTypes, tracksItem, locationMapOfConsumedItemType));
      }
    }
  }, [mainFilterIdList, sideFilterStructureMap, currentViewTab, overallOverviewData, commonData, metricsMap]);

  useEffect(() => {
    const { pathname, search } = urlRef.current;

    if (history.location.pathname !== pathname || history.location.search !== search) {
      if (!history.location.search) {
        setLoading(true);
        onLoad();
      } else if (
        Object.keys(overallOverviewData).length &&
        commonData &&
        Object.keys(locationsMap).length &&
        Object.keys(locationDisplayLabelMap).length
      ) {
        const queryPayload = parseQueryString(history.location.search);
        try {
          processQueryPayload(
            queryPayload,
            overallOverviewData,
            commonData.locations,
            commonData.locationTree,
            locationsMap,
            locationDisplayLabelMap
          );
        } catch {
          onLoad();
        }
      }
      urlRef.current = history.location;
    }
    // eslint-disable-next-line
  }, [history.location, loading]);

  useEffect(() => {
    if (!showOnboardingModalV2) {
      window.fcWidget.show();
      setLoading(true);
      onLoad();
    }
  }, [showOnboardingModalV2]);

  const onLoad = async () => {
    const newLocationDisplayLabelMap = configProvider.getValue(LOCATION_DISPLAY_LABEL_MAP, "object");

    const possibleLocationCategories = configProvider.getValue(POSSIBLE_DETECTOR_LOCATIONS, "array");

    const {
      targetLocationBased: newTargetLocationBased,
      metricsMap: newMetricsMap,
      possibleSecondaryView: newPossibleSecondaryView,
      gridViewConfig: newGridViewConfig,
      listViewConfig: newListViewConfig,
      headerMetricsMap: newHeaderMetricsMap,
      additionalAttributeMap: newAdditionalAttributeMap = { items: [], itemTypes: [] },
      filterConfig: newFilterConfig = { "main-view": [], "selected-view": [] },
      itemTypeLabel: newItemTypeLabel = "Item Type",
      defaultRowsPerListViewPage: newDefaultRowsPerListViewPage = 10,
      addTrackingOrderLabel: newAddTrackingOrderLabel = "+ Add Tracking Order",
      enableTransferOrderCsvUpload: newEnableTransferOrderCsvUpload = false
    } = TrackPageConfig;

    // TODO: Don't hard to tenant specific logic in code, include as a config option
    const tenant = await ConfigurationService.getTenantId();
    const isHisco = tenant.includes("hisco");
    const includeItemOrder = !isHisco;

    setItemTypeLabel(newItemTypeLabel);
    setHeaderMetricsMap(newHeaderMetricsMap);
    setTargetLocationBased(newTargetLocationBased);
    setFilterConfig(newFilterConfig);
    setAdditionalAttributeMap(newAdditionalAttributeMap);
    setIncludeItemOrder(includeItemOrder);
    setMetricsMap(newMetricsMap);
    setPossibleSecondaryView(newPossibleSecondaryView);
    setGridViewConfig(newGridViewConfig);
    setListViewConfig(newListViewConfig);
    setLocationDisplayLabelMap(newLocationDisplayLabelMap);
    setDefaultRowsPerListViewPage(newDefaultRowsPerListViewPage);
    setAddTrackingOrderLabel(newAddTrackingOrderLabel);
    setEnableTransferOrderCsvUpload(newEnableTransferOrderCsvUpload);

    getCustomerLogo().then((logo) => {
      if (!logo) {
        logo = getStackedXemelgoLogo();
      }
      setLogoData(logo);
    });

    loadLocations(isHisco, possibleLocationCategories).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (commonData) {
      loadListHeaderContent();
    }
  }, [commonData, sideFilterStructureMap, statusTab, currentViewTab, listViewMode]);

  /**
   * Load the location data used by the overview and listing
   */
  const loadLocations = async (isHisco, possibleLocationCategories) => {
    // get possible locations for inventory solution
    const locationRoleClasses = TrackPageConfig.locationRole?.classes || ["Inventory"];
    const locationRoleNames = TrackPageConfig.locationRole?.names || [];
    const locationRoleActions = TrackPageConfig.locationRole?.actions || ["add"];
    const hideUnknown = TrackPageConfig.hideUnknown || false;
    const unknownName = TrackPageConfig.unknownName || "Unknown";

    const inventoryPageData = await InventoryClient.getInventoryTrackPageCommonData(
      possibleLocationCategories,
      { classes: locationRoleClasses, names: locationRoleNames, actions: locationRoleActions },
      LocationRoleConfig.enabled,
      isHisco,
      hideUnknown,
      unknownName
    );

    const { locations, locationTree } = inventoryPageData;

    const newLocationsMap = locations.reduce((accumulator, current) => {
      const { id, name, identifier, category } = current;
      const { directParentId, directParentIdentifier, directParentName, locationIdList } = getDirectParent(
        current,
        locations,
        locationTree
      );
      accumulator[id] = {
        name,
        identifier,
        category,
        items: [],
        childLocations: locationIdList,
        directParentIdentifier,
        directParentName,
        directParentId
      };
      return accumulator;
    }, {});

    setLocationsMap(newLocationsMap);
    setCommonData(inventoryPageData);
  };

  useEffect(() => {
    if (commonData) {
      loadOverviewContent();
    }
  }, [commonData]);

  /**
   * Load the overview/metrics data
   */
  const loadOverviewContent = async () => {
    let inventoryPageData;

    if (useSolutionMetrics) {
      const metrics = await AppSyncInventoryClient.queryInventoryMetrics();
      inventoryPageData = {
        ...metrics,
        locations: commonData.locations,
        locationTree: commonData.locationTree,
        filterMap: {}
      };
    } else {
      inventoryPageData = await InventoryClient.getInventoryTrackPageData(
        targetLocationBased,
        commonData.locations,
        commonData.locationTree,
        additionalAttributeMap,
        filterConfig,
        includeItemOrder,
        TrackPageConfig.itemClasses,
        LocationRoleConfig.enabled
      );
    }

    overallOverviewDataRef.current = inventoryPageData;
    setOverallOverviewData(inventoryPageData);
    sendMixPanelEvent(INVENTORY_TRACKPAGE_V2, INVENTORY_TRACKPAGE_V2_STEPS.LOADED);
  };

  /**
   * Load the headers for the list view.
   */
  const loadListHeaderContent = async () => {
    const { dataList } = calculateData(calculateLocationsMap());

    setContentListData([]);
    setContentMetadataList(dataList);
    setListLoaded(true);
  };

  /**
   * Build the header metrics
   *
   * @param {*} eachLocationData If location data is provided, the header metrics
   * will include the current value of the metric. Otherwise, it will have a
   * placeholder. This is used prior to the data being available.
   * @returns List of header metrics
   */
  const getContentMetricsMetadata = (eachLocationData = null) => {
    let metricsList;
    if (Object.keys(headerMetricsMap) && headerMetricsMap[currentViewTab]) {
      const currentMetricsMap = headerMetricsMap[currentViewTab][statusTab] || {};
      metricsList = Object.keys(currentMetricsMap)
        .sort((a, b) => {
          const aIndex = currentMetricsMap[a].index;
          const bIndex = currentMetricsMap[b].index;
          return aIndex - bIndex;
        })
        .map((eachMetricsKey) => {
          const { label } = currentMetricsMap[eachMetricsKey];
          return {
            id: eachMetricsKey,
            label,
            value: eachLocationData ? eachLocationData[eachMetricsKey] : "..."
          };
        });
    }

    return metricsList;
  };

  useEffect(() => {
    if (Object.keys(listViewConfig).length && commonData && Object.keys(locationsMap).length) {
      const { defaultViewMode } = TrackPageConfig;

      let queryPayload = parseQueryString(history.location.search);

      const breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];

      let { status, listView = defaultViewMode === "listview" } = queryPayload;

      prevListViewModeRef.current = listView;

      if ((typeof status === "object" && !status) || typeof status === "undefined") {
        status = possibleSecondaryView.tabs[0].id;
      }

      queryPayload = parseQueryString(history.location.search);
      const queryString = stringifyToQueryString({
        ...queryPayload,
        listView,
        breadcrumbIds: JSON.stringify(breadcrumbIds.length ? breadcrumbIds : [possibleSecondaryView.tabs[0].id]),
        status
      });
      history.replace(`${history.location.pathname}?${queryString}`);

      queryPayload = parseQueryString(history.location.search);

      processQueryPayload(
        queryPayload,
        overallOverviewData,
        commonData.locations,
        commonData.locationTree,
        locationsMap,
        locationDisplayLabelMap
      );
    }
  }, [overallOverviewData, commonData, locationsMap, locationDisplayLabelMap, listViewConfig]);

  const getSidebarFeatureButtons = () => {
    const { sidebarFeatureButtons } = TrackPageConfig;

    return (
      sidebarFeatureButtons &&
      sidebarFeatureButtons.reduce((accum, featureButton) => {
        // Only show buttons allowed by the users role
        if (featureButton.roles && !featureButton.roles.includes(userProfile.getRole())) {
          return accum;
        }

        const featureButtonDetail = sidebarFeatureButtonMap[featureButton.id];

        accum.push(
          featureButtonDetail && (
            <OptionalLink
              key={featureButton.id}
              active={!!featureButtonDetail.linkRoute}
              route={featureButtonDetail.linkRoute}
            >
              <div
                name={featureButton.display}
                className={TrackPageComponentStyle.create_button}
                onClick={featureButtonDetail.onClick}
              >
                {featureButton.display}
              </div>
            </OptionalLink>
          )
        );
        return accum;
      }, [])
    );
  };

  const resetItemsFn = async () => {
    setLoading(true);
    let locationIds;
    try {
      locationIds = locationsListToReset
        .filter((location) => {
          return selectedLocationsMap[location.id];
        })
        .map((location) => {
          return location.id;
        });
      const { lastOperationTime } = await trackPageClient.resetInactiveTrackingSessions(
        LocationRoleConfig.enabled,
        locationIds
      );
      if (useSolutionMetrics) {
        await AppSyncInventoryClient.waitForMetricsUpdate(locationIds, lastOperationTime, 180000);
      }
      sendMixPanelEvent(INVENTORY_TRACKPAGE_V2, INVENTORY_TRACKPAGE_V2_STEPS.DEMO_RESET_SUCCESS);
    } catch (e) {
      console.error("Error Resetting Items", e);
      sendMixPanelEvent(INVENTORY_TRACKPAGE_V2, INVENTORY_TRACKPAGE_V2_STEPS.DEMO_RESET_FAILURE, {
        error_message: e.message,
        locationIdsToReset: locationIds.join(",")
      });
    } finally {
      setLoading(true);
      setShowResetItemsModalFlag(false);
      setSelectedLocationsMap({});
      setLocationsListToReset([]);
      setResetItemsFilterInput("");
      await onLoad();
    }
  };

  const resetItemsModalButtons = () => {
    return [
      {
        id: "cancel-button",
        title: "Cancel",
        onClick: () => {
          setShowResetItemsModalFlag(false);
          setSelectedLocationsMap({});
          setLocationsListToReset([]);
          setResetItemsFilterInput("");
        },
        className: "cancel-button"
      },
      {
        id: "submit-button",
        disabled: loading,
        title: "Confirm",
        onClick: () => {
          resetItemsFn();
        },
        className: "confirm-delete-button"
      }
    ];
  };

  const resetItemsModalBody = () => {
    return commonData && commonData?.locations.length > 0 ? (
      <div className={TrackPageComponentStyle.modal_body}>
        <p className={TrackPageComponentStyle.modal_body_heading}>Select the location that you want to reset:</p>
        <div className={`filter_bar_container ${TrackPageComponentStyle.modal_body_filter}`}>
          <span className="fa fa-search filter_bar_icon icon_offset" />
          <input
            title="Filter Input Field"
            placeholder="Type to Filter"
            onChange={({ currentTarget }) => {
              setResetItemsFilterInput(currentTarget.value || "");
            }}
            className="filter_bar"
          />
        </div>
        {locationsListToReset && locationsListToReset.length ? (
          <div className={TrackPageComponentStyle.modal_checkbox_container}>
            <label key="select-all-option">
              <input
                id="select-all-option"
                name="select-all-option"
                className={TrackPageComponentStyle.checkbox_style}
                checked={selectedLocationsMap["select-all-option"]}
                onChange={handleResetCheckBoxClick}
                type="checkbox"
              />
              Select All
            </label>

            {locationsListToReset.length > 0 &&
              locationsListToReset.map((location) => {
                return (
                  <label key={location.id}>
                    <input
                      id={location.id}
                      name={location.id}
                      className={TrackPageComponentStyle.checkbox_style}
                      checked={selectedLocationsMap[location.id]}
                      onChange={handleResetCheckBoxClick}
                      type="checkbox"
                    />
                    {location.name} ({location.identifier})
                  </label>
                );
              })}
          </div>
        ) : (
          <p>{` No locations found. `}</p>
        )}
      </div>
    ) : (
      <p>{` No locations found. `}</p>
    );
  };

  const renderResetItemsModal = () => {
    return (
      <GeneralizedModal
        title="Reset Items"
        modalBodyComponent={resetItemsModalBody()}
        modalFooterButtons={resetItemsModalButtons()}
        showModal={showResetItemsModalFlag}
      />
    );
  };

  const handleResetLocationsClick = async () => {
    setShowResetItemsModalFlag(true);
    setResetItemsFilterInput("");
    sendMixPanelEvent(INVENTORY_TRACKPAGE_V2, INVENTORY_TRACKPAGE_V2_STEPS.DEMO_RESET_ENTRY);
  };

  const handleDeleteItemTypesClick = async () => {
    setShowDeleteItemTypesModalFlag(true);
    setDeleteItemTypesFilterInput("");
  };

  const handleResetCheckBoxClick = (event) => {
    const selectedLocationId = event.target.name;
    const value = event.target.checked;
    const { ...selectedLocationsMapCopy } = selectedLocationsMap;

    if (selectedLocationId === "select-all-option") {
      locationsListToReset.forEach(async (location) => {
        selectedLocationsMapCopy[location.id] = value;
      });
    }
    selectedLocationsMapCopy[selectedLocationId] = value;

    setSelectedLocationsMap(selectedLocationsMapCopy);
  };

  const handleDeleteCheckBoxClick = (event) => {
    const selectedItemTypeId = event.target.name;
    const value = event.target.checked;
    const { ...selecteditemTypesMapCopy } = selectedItemTypesMap;

    if (selectedItemTypeId === "select-all-option") {
      itemTypeListToDelete.forEach(async (itemType) => {
        selecteditemTypesMapCopy[itemType.id] = value;
      });
    }
    selecteditemTypesMapCopy[selectedItemTypeId] = value;

    setSelectedItemTypesMap(selecteditemTypesMapCopy);
  };

  const removeItemTypesFn = async () => {
    setLoading(true);
    const trackingSessionIds = [];
    const itemTypeIds = [];
    const itemIds = [];
    const sensorProfileIds = [];

    itemTypeListToDelete.forEach((itemType) => {
      if (selectedItemTypesMap[itemType.id]) {
        itemTypeIds.push(itemType.id);
        if (itemType.customFields.hasInstance && itemType.customFields.hasInstance.length > 0) {
          const items = itemType.customFields.hasInstance;
          items.forEach((item) => {
            itemIds.push(item.id);
            if (item.associatedWithSession && item.associatedWithSession.length > 0) {
              trackingSessionIds.push(item.associatedWithSession[0].id);
            }
            if (item.hasSensorProfile && item.hasSensorProfile.length > 0) {
              sensorProfileIds.push(item.hasSensorProfile[0].id);
            }
          });
        }
      }
    });

    try {
      const context = { actions: { endTrackingSession: true } };
      while (trackingSessionIds.length) {
        const trackingSessionIdsPartial = trackingSessionIds.splice(0, 40);
        await PublishClient.userEvent(trackingSessionIdsPartial, null, context);
      }

      for (const spId of sensorProfileIds) {
        try {
          await SensorProfileClient.removeSensorProfile(spId, false);
        } catch (e) {
          console.log("e", e);
          return;
        }
      }

      for (const itemId of itemIds) {
        try {
          await ItemClient.removeItem(itemId, false);
        } catch (e) {
          console.log("e", e);
          return;
        }
      }

      for (const itemTypeId of itemTypeIds) {
        try {
          await ItemTypeClient.removeItemType(itemTypeId, false);
        } catch (e) {
          console.log("e", e);
          return;
        }
      }
    } catch (e) {
      console.error("Error removing item types!", e);
    } finally {
      setLoading(true);
      setShowDeleteItemTypesModalFlag(false);
      setSelectedItemTypesMap({});
      setItemTypesAndItemsForDelete([]);
      setDeleteItemTypesFilterInput("");
      await onLoad();
    }
  };

  const deleteItemTypesModalButtons = () => {
    return [
      {
        id: "cancel-button",
        title: "Cancel",
        onClick: () => {
          setShowDeleteItemTypesModalFlag(false);
          setSelectedItemTypesMap({});
          setItemTypesAndItemsForDelete([]);
          setDeleteItemTypesFilterInput("");
        },
        className: "cancel-button"
      },
      {
        id: "submit-button",
        title: "Delete",
        disabled:
          loadingItemTypesFlag ||
          !itemTypeListToDelete ||
          itemTypeListToDelete.length === 0 ||
          !Object.values(selectedItemTypesMap).includes(true) ||
          loading,
        onClick: () => {
          removeItemTypesFn();
        },
        className: "confirm-delete-button"
      }
    ];
  };

  const deleteItemTypesModalBody = () => {
    return loadingItemTypesFlag ? (
      <p>Loading all item types...</p>
    ) : itemTypesAndItemsForDelete && itemTypesAndItemsForDelete.length > 0 ? (
      <div className={TrackPageComponentStyle.modal_body}>
        <p className={TrackPageComponentStyle.modal_body_heading}>
          {`Select the ${pluralizeWord(itemTypeLabel || "Item Type")} that you want to delete:`}
        </p>
        <div className={`filter_bar_container ${TrackPageComponentStyle.modal_body_filter}`}>
          <span className="fa fa-search filter_bar_icon icon_offset" />
          <input
            title="Filter Input Field"
            placeholder="Type to Filter"
            onChange={({ currentTarget }) => {
              setDeleteItemTypesFilterInput(currentTarget.value || "");
            }}
            className="filter_bar"
          />
        </div>
        {itemTypeListToDelete && itemTypeListToDelete.length > 0 ? (
          <div className={TrackPageComponentStyle.modal_checkbox_container}>
            <label key="select-all-option">
              <input
                id="select-all-option"
                name="select-all-option"
                className={TrackPageComponentStyle.checkbox_style}
                checked={selectedItemTypesMap["select-all-option"]}
                onChange={handleDeleteCheckBoxClick}
                type="checkbox"
              />
              Select All
            </label>

            {itemTypeListToDelete.length > 0 &&
              itemTypeListToDelete.map((itemType) => {
                return (
                  <label key={itemType.id}>
                    <input
                      id={itemType.id}
                      name={itemType.id}
                      className={TrackPageComponentStyle.checkbox_style}
                      checked={selectedItemTypesMap[itemType.id]}
                      onChange={handleDeleteCheckBoxClick}
                      type="checkbox"
                    />
                    {itemType.identifier}
                    {` (${itemType.customFields.hasInstance && itemType.customFields.hasInstance.length} Units)`}
                  </label>
                );
              })}
          </div>
        ) : (
          <p>{` No ${pluralizeWord(itemTypeLabel || "Item Type")} found. `}</p>
        )}
      </div>
    ) : (
      <p>{` No ${pluralizeWord(itemTypeLabel || "Item Type")} found. `}</p>
    );
  };

  const renderRemoveItemTypesModal = () => {
    return (
      <GeneralizedModal
        title={`Delete ${pluralizeWord(itemTypeLabel || "Item Type")}`}
        modalBodyComponent={deleteItemTypesModalBody()}
        modalFooterButtons={deleteItemTypesModalButtons()}
        showModal={showDeleteItemTypesModalFlag}
      />
    );
  };

  const onBreadcrumbsClick = (id) => {
    const queryPayload = parseQueryString(history.location.search);
    delete queryPayload.filterIds;
    let { locationId, typeId } = queryPayload;

    let breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];
    const index = breadcrumbIds.findIndex((eachId) => {
      return eachId === id;
    });
    if (index !== -1) {
      breadcrumbIds = breadcrumbIds.slice(0, index + 1);

      typeId = (
        overallOverviewDataRef.current.itemTypes.find((eachItem) => {
          return eachItem.id === breadcrumbIds[breadcrumbIds.length - 1];
        }) || {}
      ).id;

      if (typeId) {
        locationId = breadcrumbIds[breadcrumbIds.length - 2];
      } else {
        locationId = (
          overallOverviewDataRef.current.locations.find((eachLocation) => {
            return eachLocation.id === breadcrumbIds[breadcrumbIds.length - 1];
          }) || {}
        ).id;
      }
    }
    const queryString = stringifyToQueryString({
      ...queryPayload,
      breadcrumbIds: JSON.stringify(breadcrumbIds),
      viewTab: prevViewTabRef.current,
      listView: prevListViewModeRef.current,
      locationId,
      typeId
    });

    history.push(`${history.location.pathname}?${queryString}`);
  };

  const processQueryPayload = (
    payload,
    data,
    locations,
    locationTree,
    locationsMapParam,
    locationDisplayLabelMapParam
  ) => {
    const { locationId, typeId, viewTab, listView: currentListViewMode, status } = payload;
    const { itemTypeLabel: newItemTypeLabel } = TrackPageConfig;
    const { itemTypes = [] } = data;

    const breadcrumbIds = payload.breadcrumbIds ? JSON.parse(payload.breadcrumbIds) : [];

    if (!breadcrumbIds.length) {
      breadcrumbIds.push("home");
    }

    const newBreadcrumbsDataList = breadcrumbIds.map((eachId) => {
      const itemTypeResult = useSolutionMetrics
        ? findItemTypeInMetrics(eachId, data)
        : itemTypes.find((eachType) => {
            return eachType.id === eachId;
          });

      const locationResult = locations.find((eachLocation) => {
        return eachLocation.id === eachId;
      });

      if (itemTypeResult) {
        const itemTypeObj = {
          id: eachId,
          value: useSolutionMetrics ? itemTypeResult.properties.identifier : itemTypeResult.identifier,
          onClick: onBreadcrumbsClick,
          linkIconTitle: "Click to view item type details"
        };

        if (!useSolutionMetrics) {
          itemTypeObj.linkIconClickFn = (id) => {
            history.push(`${history.location.pathname}/itemType/detail?id=${id}`);
          };
        }
        return itemTypeObj;
      }

      if (locationResult) {
        return { id: eachId, value: locationResult.name, onClick: onBreadcrumbsClick };
      }

      return {
        id: eachId,
        onClick: onBreadcrumbsClick,
        home: true
      };
    });

    setBreadcrumbsDataList(newBreadcrumbsDataList);

    const filterIds = Array.isArray(payload.filterIds)
      ? payload.filterIds
      : payload.filterIds
      ? [payload.filterIds]
      : [];

    setStatusTab(status);
    setCurrentViewTab(viewTab || TrackPageConfig.defaultViewTab || VIEW_TABS.ITEM_TYPE);
    setListViewMode(currentListViewMode);

    const location = locations.find((each) => {
      return each.id === locationId;
    });
    let itemType;

    if (useSolutionMetrics) {
      const foundType = findItemTypeInMetrics(typeId, data);
      itemType = foundType ? foundType?.properties : null;
    } else {
      itemType = itemTypes.find((each) => {
        return each.id === typeId;
      });
    }

    const newMainFilterIdList = [];
    if (location) {
      newMainFilterIdList.push(location.id);
    }
    if (itemType) {
      newMainFilterIdList.push(itemType.id);
    }

    setMainFilterIdList(newMainFilterIdList);

    // calculating side filter structure map

    const newFilterMap = {};
    newFilterMap["item-type"] = {
      id: "item-type",
      label: newItemTypeLabel || itemTypeLabel,
      idList: [],
      checkboxList: getItemTypeCheckBoxList(data, filterIds)
    };
    newFilterMap["item-type"].checkboxList.forEach((each) => {
      if (each.value) {
        newFilterMap["item-type"].idList.push(each.id);
      }
    });

    const locationIdList = locationId
      ? [locationId]
      : locations.map((eachLocation) => {
          return eachLocation.id;
        });
    const filteredLocationTree = locationTree.filter((eachTree) => {
      return eachTree.includes(locationId);
    });
    filteredLocationTree.forEach((eachTree) => {
      const findIndex = eachTree.findIndex((eachId) => {
        return eachId === locationId;
      });
      if (findIndex !== eachTree.length - 1) {
        eachTree.slice(findIndex, eachTree.length).forEach((eachId) => {
          if (!locationIdList.includes(eachId)) {
            locationIdList.push(eachId);
          }
        });
      }
    });

    newFilterMap.location = {
      id: "location",
      label: "Location",
      idList: [],
      checkboxList: locationId
        ? locationsMapParam[locationId].childLocations.reduce((accumulator, eachId) => {
            if (!newMainFilterIdList.includes(eachId) && locationsMapParam[eachId]) {
              accumulator.push({
                id: eachId,
                label: getLocationName(eachId, locationsMapParam, locationDisplayLabelMapParam),
                value: filterIds.includes(eachId)
              });
            }
            return accumulator;
          }, [])
        : locations.map((each) => {
            const { id } = each;
            return {
              id,
              label: getLocationName(id, locationsMapParam, locationDisplayLabelMapParam),
              value: filterIds.includes(id)
            };
          })
    };
    const filter = typeId && locationId ? "selected-view" : "main-view";
    const currentFilters = filterConfig[filter];
    const { filterMap = {} } = data;

    if (currentFilters) {
      currentFilters.forEach((filter) => {
        const { id, label } = filter;
        newFilterMap[id] = {
          id,
          label,
          idList: [],
          checkboxList: filterMap[id]
            ? filterMap[id].map((each) => {
                const formattedValue = id + each;
                return {
                  id: formattedValue,
                  label: `${each}`,
                  value: filterIds.includes(`${formattedValue}`)
                };
              })
            : []
        };
        newFilterMap[id].checkboxList.forEach((each) => {
          if (each.value) {
            newFilterMap[id].idList.push(each.id);
          }
        });
      });
    }

    newFilterMap.location.checkboxList.forEach((each) => {
      if (each.value) {
        newFilterMap.location.idList.push(each.id);
      }
    });

    newFilterMap.location.checkboxList.sort((a, b) => {
      if (a.id === UNKNOWN_LOCATION_ID || b.id === UNKNOWN_LOCATION_ID) {
        return a.id === UNKNOWN_LOCATION_ID ? 1 : -1;
      }
      return a.label.localeCompare(b.label, undefined, {
        numeric: true,
        sensitivity: "base"
      });
    });
    setSideFilterStructureMap(newFilterMap);
  };

  const getSortSchema = () => {
    return [
      {
        id: "identifier",
        value: itemTypeLabel,
        compareFunc: (a, b) => {
          return a.identifier.localeCompare(b.identifier, undefined, {
            numeric: true,
            sensitivity: "base"
          });
        },
        type: "character",
        default: true
      },
      {
        id: "total units",
        value: "Total Units",
        compareFunc: (a, b) => {
          return a.totalItemCount - b.totalItemCount;
        },
        type: "number"
      },
      {
        id: "status",
        value: "Status",
        compareFunc: (a, b) => {
          const statusLevelCalculator = (id = "") => {
            switch (id.toLowerCase()) {
              case CRITICAL.toLowerCase():
                return 3;
              case WARNING.toLowerCase():
                return 2;
              case HEALTHY.toLowerCase():
                return 1;
              default:
                return 0;
            }
          };
          let aLevel = 0;
          let bLevel = 0;
          if (statusTab === "stock") {
            aLevel = statusLevelCalculator(a.stockStatus);
            bLevel = statusLevelCalculator(b.stockStatus);
            return bLevel - aLevel;
          }
          aLevel = statusLevelCalculator(a.expiryStatus);
          bLevel = statusLevelCalculator(b.expiryStatus);
          return bLevel - aLevel;
        },
        type: "number"
      }
    ];
  };

  const resolveRouteWithQueryString = (typeId, locationId) => {
    const locationIdArray = locationId ? locationId.split(",") : [];
    prevViewTabRef.current = currentViewTab;
    prevListViewModeRef.current = listViewMode;

    const queryPayload = parseQueryString(history.location.search);
    delete queryPayload.filterIds;
    const breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];

    locationIdArray.forEach((eachLocationId) => {
      if (!breadcrumbIds.includes(eachLocationId)) {
        breadcrumbIds.push(eachLocationId);
      }
    });

    if (!breadcrumbIds.includes(typeId)) {
      breadcrumbIds.push(typeId);
    }

    const queryString = stringifyToQueryString({
      ...queryPayload,
      locationId: locationIdArray[locationIdArray.length - 1],
      breadcrumbIds: JSON.stringify(breadcrumbIds),
      itemType: true,
      typeId,
      viewTab: VIEW_TABS.ALL_ITEMS,
      listView: true
    });

    return { route: `${history.location.pathname}?${queryString}`, isReplace: false };
  };

  const renderGridCardComponentItemType = (eachItemData, id, containerStyle) => {
    let color;
    const { expiryStatus, stockStatus, locationFilterKey } = eachItemData;
    if (statusTab === "expiration") {
      color = healthColorMap[expiryStatus];
    } else if (statusTab === "stock") {
      color = healthColorMap[stockStatus];
    }

    if (locationFilterKey === UNKNOWN_LOCATION_ID) {
      color = healthColorMap.Empty;
    }

    let currentGridViewConfig = gridViewConfig[currentViewTab] || {};
    const match = possibleSecondaryView.tabs?.find((each) => {
      return each.id === statusTab;
    });
    if (match) {
      currentGridViewConfig = currentGridViewConfig[statusTab];
    }

    const {
      title = {},
      image,
      contentList = [],
      progressBarEnabled,
      showTotalQuantity,
      showTotalWeight
    } = currentGridViewConfig || {};

    return (
      <GridCardComponent
        key={id}
        containerStyle={containerStyle}
        color={color}
      >
        <OptionalLink route={resolveRouteWithQueryString(id, locationFilterKey).route}>
          <GridCardContentGroupbyItemType
            progressBarEnabled={progressBarEnabled}
            color={color}
            statusTab={statusTab}
            data={eachItemData}
            image={image ? eachItemData[image.id] || logoData : null}
            title={{ ...title, value: eachItemData[title.id] }}
            contentList={contentList?.map((each) => {
              return { ...each, value: eachItemData[each.id] };
            })}
            showTotalQuantity={showTotalQuantity}
            showTotalWeight={showTotalWeight}
          />
        </OptionalLink>
      </GridCardComponent>
    );
  };

  const listViewHeaderStructureListControl = (groupby) => {
    let currentListViewHeaderConfig = listViewConfig[groupby] || {};

    if (
      possibleSecondaryView.tabs?.find((each) => {
        return each.id === statusTab;
      }) &&
      currentListViewHeaderConfig[statusTab]
    ) {
      currentListViewHeaderConfig = currentListViewHeaderConfig[statusTab];
    }
    return Object.keys(currentListViewHeaderConfig || {})
      .sort((a, b) => {
        return currentListViewHeaderConfig[a].index - currentListViewHeaderConfig[b].index;
      })
      .map((eachId) => {
        const { type, format = "YYYY-MM-DD", maxLength } = currentListViewHeaderConfig[eachId];
        let renderComponent;
        switch (type) {
          case "expiryDateCount":
            renderComponent = (data) => {
              return typeof data === "number" ? (data > 0 ? `${data} day(s)` : "Item has Expired") : "No Expiry Date";
            };
            break;
          case "boolean":
            renderComponent = (data) => {
              return data ? "Yes" : "No";
            };
            break;
          case "date":
            renderComponent = (data) => {
              return data ? getFormattedDate(data, format) : "-";
            };
            break;
          case "array":
            renderComponent = (data) => {
              let formattedData = data && data.length > 0 ? data.join(",") : "-";
              if (maxLength && formattedData.length > maxLength) {
                formattedData = `${formattedData.substring(0, maxLength - 1)}...`;
              }
              return formattedData;
            };
            break;
          default:
            renderComponent = (data) => {
              return data || typeof data === "number" ? data : "-";
            };
            break;
        }
        return { id: eachId, ...currentListViewHeaderConfig[eachId], renderComponent };
      });
  };

  const handleViewTabClick = (id) => {
    const queryPayload = parseQueryString(history.location.search);
    if (id === VIEW_TABS.ALL_ITEMS || id === VIEW_TABS.ITEM_LOT) {
      queryPayload.listView = true;
      prevListViewModeRef.current = listViewMode;
    } else {
      queryPayload.listView = prevListViewModeRef.current;
    }
    const queryString = stringifyToQueryString({
      ...queryPayload,
      viewTab: id
    });
    history.replace(`${history.location.pathname}?${queryString}`);
  };

  const getLocationName = (locationId, map, locationDisplayLabelMapParam) => {
    const { name, directParentName, category } = map[locationId];
    const locationName = map[locationId][locationDisplayLabelMapParam[category]];
    return `${locationName || name}${directParentName ? ` (${directParentName})` : ""}`;
  };

  const getStatusViewTabStructure = () => {
    if (TrackPageConfig.hidePossibleSecondaryView) {
      return [];
    }
    const { label, tabs = [] } = possibleSecondaryView;
    const statusViewTabStructure = [
      {
        id: label,
        display: label,
        tabStructure: tabs.map((each) => {
          const { id, label: eachLabel } = each;
          return {
            id,
            display: eachLabel,
            action: () => {
              setStatusTab(id);
              const queryPayload = parseQueryString(history.location.search);

              if (id === "stock" && currentViewTab === VIEW_TABS.ITEM_LOT) {
                queryPayload.viewTab = VIEW_TABS.ITEM_TYPE;
              }

              const queryString = stringifyToQueryString({
                ...queryPayload,
                status: id
              });
              history.replace(`${history.location.pathname}?${queryString}`);
            }
          };
        })
      }
    ];
    return statusViewTabStructure;
  };

  const gridViewfilterFunction = (input, each = {}) => {
    let currentGridViewConfig = gridViewConfig[currentViewTab] || {};
    if (currentViewTab !== VIEW_TABS.ALL_ITEMS) {
      currentGridViewConfig = currentGridViewConfig[statusTab] || {};
    }

    const keysToCheckList = [currentGridViewConfig.title.id];

    const { contentList } = currentGridViewConfig;
    if (contentList) {
      const additionalkeysToCheckList = currentGridViewConfig.contentList?.map((key) => {
        return key.id;
      });
      keysToCheckList.push(...additionalkeysToCheckList);
    }

    const lowerCaseInput = input.toLowerCase();
    let match = false;
    keysToCheckList.forEach((eachKey) => {
      let eachValue = each[eachKey];
      if (typeof eachValue !== "string") {
        eachValue = String(eachValue);
      }
      if (eachValue && eachValue.toLowerCase().includes(lowerCaseInput)) {
        match = true;
      }
    });

    return match;
  };

  const listViewFilterFunction = (input, each) => {
    let currentListViewConfig = listViewConfig[currentViewTab] || {};
    if (currentViewTab !== VIEW_TABS.ALL_ITEMS) {
      currentListViewConfig = currentListViewConfig[statusTab] || {};
    }

    const keysToCheckList = Object.keys(currentListViewConfig).reduce((accumulator, currentKey) => {
      if (
        !currentListViewConfig[currentKey].type ||
        currentListViewConfig[currentKey].type === "string" ||
        currentListViewConfig[currentKey].type === "array"
      ) {
        accumulator.push(currentKey);
      }
      return accumulator;
    }, []);

    const lowerCaseInput = input.toLowerCase();
    let match = false;
    keysToCheckList.forEach((eachKey) => {
      let eachValue = each[eachKey];
      if (typeof eachValue !== "string") {
        eachValue = String(eachValue);
      }
      if (eachValue && eachValue.toLowerCase().includes(lowerCaseInput)) {
        match = true;
      }
    });

    return match;
  };

  /**
   * In header based mode, the track page component needs to be provided the data
   *
   * @returns item/item type data
   */
  const exportCSV = async () => {
    setLoading(true);
    const { dataList } = calculateData(currentData);
    setLoading(false);
    return dataList;
  };

  /**
   * Callback from the TrackPageComponent when the headers are expanded.
   *
   * @param {string} locationId location id
   * @param {boolean} newIsExpanded true if header is expanded
   */
  const generateUpdateExpansionStateCallback = (locationId, newIsExpanded = true) => {
    const getLocationData = (headerLocationId, dataList) => {
      const locationData = dataList.find((data) => {
        // Need to filter for both header location id and the comma-separeted list of id combining parent and header location id
        return data.id === locationId || data.id === headerLocationId;
      });

      if (locationData) {
        return locationData;
      }

      for (const eachLocation of dataList) {
        const res = getLocationData(headerLocationId, eachLocation.subHeaderList);
        if (res) {
          return res;
        }
      }

      return null;
    };
    if (newIsExpanded) {
      // Put a loading circle while we lookup the data
      const item = {};
      item[locationId] = LOADING;
      item.time = Date.now();
      setContentListData((prevContentListData) => {
        return [
          ...prevContentListData.filter((data) => {
            return !Object.keys(data).includes(locationId);
          }),
          item
        ];
      });

      const headerLocationId = locationId.split(",").pop();
      const { dataList } = calculateData(currentData);

      const locationData = getLocationData(headerLocationId, dataList);

      if (locationData) {
        item[locationId] = locationData?.dataList || [];
        item.time = Date.now();
        setContentListData((prevContentListData) => {
          return [
            ...prevContentListData.filter((data) => {
              return !Object.keys(data).includes(locationId);
            }),
            item
          ];
        });
      }
    }
  };

  /**
   * This method will return the route information to use when generating
   * the route for the links in the child list views. The route may be
   * generated differently depending on the tab and format of the {@link id}
   * provided. For example, if the user is in the Lot view and accesses a nested item,
   * the {@link currentViewTab} would be 'item-lot', the {@link id} would would possibly contain multiple
   * pieces of data ("1abdbd0e-ca59-5485-0124-fb17b0233df6,") and the {@link locationFilterKey}
   * would have a value ("fabe81e8-f082-5371-cbe2-02b0694749e2"), all of which are used
   * in constructing the correct route with breadcrumbs included.
   * @param {string} id - the identifier of the resource to link to
   * @param {string} locationFilterKey - the current location to be used in route generation and
   * breadcrumb construction
   * @returns {{route: String, isReplace:boolean}} Object containing the route to link to and
   * whether or not the navigation should replace the currnet page in history or push onto the
   * history stack
   */
  const getListViewLinkDetails = ({ id, locationFilterKey }) => {
    if (currentViewTab === VIEW_TABS.ALL_ITEMS) {
      return { route: `${history.location.pathname}/item/detail?itemId=${id}`, isReplace: false };
    }
    if (currentViewTab === VIEW_TABS.ITEM_LOT) {
      id = id.split(",")[0];
    }
    return resolveRouteWithQueryString(id, locationFilterKey);
  };

  const getDirectParent = (location, locations, locationTree) => {
    const { id } = location;
    const locationIdList = [id];

    let directParentId;
    let directParentName;
    let directParentIdentifier;
    const filteredLocationTree = locationTree.filter((eachTree) => {
      return eachTree.includes(id);
    });
    filteredLocationTree.forEach((eachTree) => {
      const findIndex = eachTree.findIndex((eachId) => {
        return eachId === id;
      });
      if (findIndex !== eachTree.length - 1) {
        eachTree.slice(findIndex + 1, eachTree.length).forEach((eachId) => {
          if (!locationIdList.includes(eachId)) {
            locationIdList.push(eachId);
          }
        });
      }

      if (eachTree[eachTree.length - 1] === id) {
        const parentLocation = locations.find((each) => {
          return each.id === eachTree[eachTree.length - 2];
        });
        if (parentLocation) {
          directParentId = parentLocation.id;
          directParentIdentifier = parentLocation.identifier;
          directParentName = parentLocation.name;
        }
      }
    });

    return { directParentId, directParentIdentifier, directParentName, locationIdList };
  };

  const calculateStatus = (itemType, locationId, allLocationMap) => {
    const { expiredItemCount, expiringSoonItemCount, stockItemCount, onHandItemCount, minStockThreshold } = itemType;

    if (onHandItemCount <= 0) {
      itemType.stockStatus = CRITICAL;
    } else if (minStockThreshold && onHandItemCount <= minStockThreshold) {
      itemType.stockStatus = WARNING;
    } else {
      itemType.stockStatus = locationId === UNKNOWN_LOCATION_ID ? EMPTY : HEALTHY;
    }

    if (expiredItemCount > 0) {
      itemType.expiryStatus = CRITICAL;
    } else if (expiringSoonItemCount > 0) {
      itemType.expiryStatus = WARNING;
    } else if (!stockItemCount) {
      itemType.expiryStatus = EMPTY;
    } else {
      itemType.expiryStatus = locationId === UNKNOWN_LOCATION_ID ? EMPTY : HEALTHY;
    }

    if (locationId === UNKNOWN_LOCATION_ID) {
      allLocationMap[locationId].expiryStatus = EMPTY;
      allLocationMap[locationId].stockStatus = EMPTY;
    } else {
      if (healthPriorityMap[itemType.expiryStatus] < healthPriorityMap[allLocationMap[locationId].expiryStatus]) {
        allLocationMap[locationId].expiryStatus = itemType.expiryStatus;
      }

      if (healthPriorityMap[itemType.stockStatus] < healthPriorityMap[allLocationMap[locationId].stockStatus]) {
        allLocationMap[locationId].stockStatus = itemType.stockStatus;
      }
    }
  };

  const filterItems = (allItems) => {
    // filter Items based on the main filter and side filter
    const allFilters = {
      ...sideFilterStructureMap,
      main: {
        idList: mainFilterIdList
      }
    };

    let filteredItems = allItems;
    Object.keys(allFilters).forEach((eachFilterKey) => {
      const { idList } = allFilters[eachFilterKey];
      filteredItems = filteredItems.filter((eachItem) => {
        const { properties, tags } = eachItem;
        if ([REMOVED, INACTIVE].includes(properties.processState)) {
          return false;
        }

        if (eachFilterKey === "main") {
          return !idList.length
            ? true
            : idList.every((eachId) => {
                return tags.includes(eachId);
              });
        }

        return !idList.length
          ? true
          : idList.some((eachId) => {
              return tags.includes(eachId);
            });
      });
    });

    return filteredItems;
  };

  const filterItemType = (itemTypeId) => {
    return (
      sideFilterStructureMap["item-type"].idList.length &&
      !sideFilterStructureMap["item-type"].idList.includes(itemTypeId)
    );
  };

  const hideLocation = (dataList) => {
    if (dataList.length === 0) {
      return {
        currentlyContainsItems: false,
        newSubHeaderList: []
      };
    }

    let currentlyContainsItems = false;

    const res = dataList.map((eachLocation) => {
      const copy = { ...eachLocation };
      const { id, subHeaderList } = eachLocation;
      const headerLocationId = id.split(",").pop();

      const { currentlyContainsItems: isChildrenContainItems, newSubHeaderList } = hideLocation(subHeaderList);

      copy.subHeaderList = newSubHeaderList;

      const { items = [], itemTypeMap = {} } = currentData[headerLocationId] || {};
      const totalItemCount = items.length;
      const totalItemTypeCount = Object.keys(itemTypeMap).length;

      if (totalItemCount === 0 && totalItemTypeCount === 0 && !isChildrenContainItems) {
        copy.hide = true;
      } else {
        currentlyContainsItems = true;
      }

      return copy;
    });

    return {
      currentlyContainsItems,
      newSubHeaderList: res
    };
  };

  const getDataListWithLocationHiddenIfNoItemExist = useMemo(() => {
    const keys = Object.keys(sideFilterStructureMap);
    let isFilterToggled = false;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const filter = sideFilterStructureMap[key];
      for (const checkboxList of filter.checkboxList) {
        if (checkboxList.value) {
          isFilterToggled = true;
        }
      }
      if (isFilterToggled) {
        break;
      }
    }

    if (isFilterToggled) {
      const { newSubHeaderList: processedResult } = hideLocation(contentMetadataList);
      return processedResult;
    }

    return contentMetadataList;
  }, [contentMetadataList, sideFilterStructureMap]);

  return (
    <>
      <TrackPageComponent
        additionalExportCsvHeaders={getExportCsvItemTypeHeaders()}
        filterFunc={gridViewfilterFunction}
        listViewFilterFunc={listViewFilterFunction}
        sidebarFeatureButtons={getSidebarFeatureButtons()}
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        sortSchema={getSortSchema()}
        statusTab={statusTab}
        numOfItemsPerPage={defaultRowsPerListViewPage}
        statusViewTabStructure={getStatusViewTabStructure()}
        removeFilterClick={(ids) => {
          const queryPayload = parseQueryString(history.location.search);
          const filterIds = Array.isArray(queryPayload.filterIds)
            ? queryPayload.filterIds
            : queryPayload.filterIds
            ? [queryPayload.filterIds]
            : [];
          const queryString = stringifyToQueryString({
            ...queryPayload,
            filterIds: filterIds.filter((id) => {
              return !ids.includes(id);
            })
          });
          history.replace(`${history.location.pathname}?${queryString}`);
        }}
        currentFilterList={Object.keys(sideFilterStructureMap).reduce((accumulator, eachKey) => {
          const { checkboxList } = sideFilterStructureMap[eachKey];
          checkboxList.forEach((eachCheckbox) => {
            const { id, label, value } = eachCheckbox;
            if (value) {
              accumulator.push({ id, label, sort: eachKey });
            }
          });
          return accumulator;
        }, [])}
        backComponentProps={{
          enabled: !!mainFilterIdList.length,
          title: (breadcrumbsDataList[breadcrumbsDataList.length - 2] || {}).value || "Home",
          onClick: () => {
            const queryPayload = parseQueryString(history.location.search);
            const breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];
            onBreadcrumbsClick(breadcrumbIds[breadcrumbIds.length - 2]);
          }
        }}
        getHeaderLinkDetails={(id) => {
          prevViewTabRef.current = currentViewTab;
          prevListViewModeRef.current = listViewMode;
          const queryPayload = parseQueryString(history.location.search);
          const breadcrumbIds = queryPayload.breadcrumbIds ? JSON.parse(queryPayload.breadcrumbIds) : [];

          const idArray = id.split(",");

          idArray.forEach((eachId) => {
            if (!breadcrumbIds.includes(eachId)) {
              breadcrumbIds.push(eachId);
            }
          });

          if (queryPayload.locationId !== idArray[idArray.length - 1]) {
            delete queryPayload.filterIds;
            const queryString = stringifyToQueryString({
              ...queryPayload,
              locationId: idArray[idArray.length - 1],
              breadcrumbIds: JSON.stringify(breadcrumbIds)
            });
            return { route: `${history.location.pathname}?${queryString}`, isReplace: false };
          }
        }}
        listViewMode={listViewMode}
        setListViewMode={(enabled) => {
          setListViewMode(enabled);
          const queryPayload = parseQueryString(history.location.search);
          const queryString = stringifyToQueryString({ ...queryPayload, listView: enabled });
          history.replace(`${history.location.pathname}?${queryString}`);
        }}
        canSwitchListViewMode={currentViewTab !== VIEW_TABS.ALL_ITEMS && currentViewTab !== VIEW_TABS.ITEM_LOT}
        renderDataList={getDataListWithLocationHiddenIfNoItemExist}
        title="Inventory"
        buttonTitle={addButtonTitle}
        buttonFunction={
          TrackPageConfig.onboardingVersion === "V2"
            ? () => {
                window.fcWidget.hide();
                setShowOnboardingModalV2(true);
              }
            : null
        }
        disableAddButton={disableAddButton}
        titleIcon={
          <InventoryIcon
            width={25}
            height={25}
            style={{ color: mainColor }}
          />
        }
        filterComponent={
          !listLoaded ? (
            <Skeleton count={5} />
          ) : (
            <CheckBoxListComponent
              filterStructureMap={sideFilterStructureMap}
              onChange={(newSideFilterStructureMap) => {
                if (!isSideFilterTelemetrySent) {
                  setIsSideFilterTelemetrySent(true);
                  sendMixPanelEvent(INVENTORY_TRACKPAGE_V2, INVENTORY_TRACKPAGE_V2_STEPS.SIDE_FILTER_APPLIED);
                }
                const queryPayload = parseQueryString(history.location.search);
                const filterIds = Object.keys(newSideFilterStructureMap).reduce((accumulator, eachKey) => {
                  const { idList } = newSideFilterStructureMap[eachKey];
                  idList.forEach((eachId) => {
                    accumulator.push(eachId);
                  });
                  return accumulator;
                }, []);
                const queryString = stringifyToQueryString({ ...queryPayload, filterIds });
                history.replace(`${history.location.pathname}?${queryString}`);
              }}
            />
          )
        }
        overviewComponent={
          <InventoryOverviewComponent
            overviewDataList={overviewMetricsDataList}
            itemTypeLabel={itemTypeLabel}
            chartDataList={overviewChartDataList}
            title={
              possibleSecondaryView
                ? possibleSecondaryView.tabs?.find((each) => {
                    return each.id === statusTab;
                  }).label
                : "Stock"
            }
          />
        }
        breadcrumbsComponent={<BreadcrumbsComponent dataList={breadcrumbsDataList} />}
        currentViewTab={currentViewTab}
        hideViewButtons={TrackPageConfig.hideViewButtons}
        viewTabStructure={
          mainFilterIdList.length !== 2
            ? [
                {
                  id: VIEW_TABS.ALL_ITEMS,
                  label: "Item",
                  onClick: () => {
                    handleViewTabClick(VIEW_TABS.ALL_ITEMS);
                  }
                },
                {
                  id: VIEW_TABS.ITEM_TYPE,
                  label: itemTypeLabel,
                  onClick: () => {
                    handleViewTabClick(VIEW_TABS.ITEM_TYPE);
                  }
                },
                statusTab === "expiration"
                  ? {
                      id: VIEW_TABS.ITEM_LOT,
                      label: "Lot",
                      onClick: () => {
                        handleViewTabClick(VIEW_TABS.ITEM_LOT);
                      }
                    }
                  : {}
              ]
            : []
        }
        gridCardComponent={renderGridCardComponentItemType}
        listViewStructure={listViewHeaderStructureListControl(currentViewTab)}
        getListViewLinkDetails={getListViewLinkDetails}
        generateExpansionUpdateFunc={generateUpdateExpansionStateCallback}
        contentLoading={loading}
        headerMetricsList={getContentMetricsMetadata()}
        headerMetricsData={contentMetricsData}
        headerBasedContentList={contentListData}
        onExportCSV={exportCSV}
        shouldAutoExpandFirstRow={false}
        useSkeleton
        exportCsvButtonReady={Object.keys(currentData).length}
        addTransferOrderButton={enableTransferOrderCsvUpload}
        addTransferOrderButtonTitle={addTrackingOrderLabel}
        addTransferOrderButtonFunction={() => {
          setShowTransferOrderModal(true);
        }}
        multiSelectEnabled={currentViewTab === VIEW_TABS.ALL_ITEMS && enabledMultiSelectOptions.length > 0}
        selectedItemMap={selectedItemMap}
        onSelectItem={setSelectedItemMap}
        multiSelectOptions={enabledMultiSelectOptions}
        onMultiSelectOptionClick={setSelectedMultiSelectAction}
      />
      {renderRemoveItemTypesModal()}
      {renderResetItemsModal()}

      {showOnboardingModalV2 && (
        <AddInventoryPageFeatureV2
          onClose={() => {
            return setShowOnboardingModalV2(false);
          }}
        />
      )}

      {showTransferOrderModal && (
        <AddTransferOrderFeature
          onClose={() => {
            return setShowTransferOrderModal(false);
          }}
        />
      )}

      {showUpdateTransferOrderModal && (
        <UpdateTransferOrdersFeature
          onClose={() => {
            return setShowUpdateTransferOrderModal(false);
          }}
        />
      )}
      {selectedMultiSelectAction && (
        <MultiSelectActionModal
          action={selectedMultiSelectAction}
          selectedItems={Object.values(selectedItemMap)}
          onModalClose={(actionAttempted, success, message) => {
            setSelectedMultiSelectAction(false);
            if (actionAttempted) {
              setSelectedItemMap({});
              clearTimeout(popupTimeoutRef.current);
              setStatusMessage(message);
              setStatus(success ? STATUS_OPTIONS.SUCCESS : STATUS_OPTIONS.ERROR);
              popupTimeoutRef.current = setTimeout(() => {
                setStatusMessage("");
              }, DEFAULT_POPUP_DURATION_MS);
            }
          }}
        />
      )}
      {statusMessage && (
        <div className={Style.status_popup_container}>
          <StatusPopupComponent
            showPopup={!!statusMessage}
            message={statusMessage}
            status={status}
          />
        </div>
      )}
    </>
  );
};
export default withRouter(InventoryTrackPageFeature);
