import { ACTIONS_MAP } from "../../data/constants";
import generateAddPayload from "../generate-add-payload";
import generateCheckoutPayload from "../generate-checkout-payload";
import generateConsumePayload from "../generate-consume-payload";
import generateMovePayload from "../generate-move-payload";

/**
 * @typedef {Object} Item
 * @property {string} trackingSessionId
 * @property {boolean} is_consumed
 * @property {string} vid
 */

/**
 * @typedef {Object} Location
 * @property {string} id
 * @property {boolean} identifier
 */

/**
 * This function generate the payload for the execution by each action type.
 * @param {string} actionType
 * @param {Location} targetLocation
 * @param {{[vid: string]: Item}} scannedItemsMap
 * @returns
 */
export const getSubmitPayloadByAction = async (actionType, targetLocation, scannedItemsMap) => {
  const { id: locationId, identifier: locationIdentifier } = targetLocation || {};
  const payloadsMap = {};
  const tagsToSubmit = [];

  const activeItems = Object.values(scannedItemsMap).filter((item) => {
    return item.trackingSessionId;
  });

  switch (actionType) {
    case ACTIONS_MAP.CHECK_OUT:
      const { checkoutPayload, tagsToCheckout } = generateCheckoutPayload(activeItems);
      payloadsMap[actionType] = checkoutPayload;
      tagsToSubmit.push(...tagsToCheckout);
      break;

    case ACTIONS_MAP.MOVE:
      const { movePayload, tagsToMove } = generateMovePayload(activeItems, locationId);
      payloadsMap[actionType] = movePayload;
      tagsToSubmit.push(...tagsToMove);
      break;

    case ACTIONS_MAP.ADD:
      const { createPayload, unconsumePayload, allTagsToAdd } = generateAddPayload(scannedItemsMap, locationIdentifier);
      payloadsMap[actionType] = createPayload;
      payloadsMap.unconsume = unconsumePayload;
      tagsToSubmit.push(...allTagsToAdd);
      break;

    case ACTIONS_MAP.CONSUME:
      const { consumePayload, tagsToConsume } = generateConsumePayload(activeItems);
      payloadsMap[actionType] = consumePayload;
      tagsToSubmit.push(...tagsToConsume);
      break;

    default:
      break;
  }
  return { payloadsMap, tagsToSubmit };
};
