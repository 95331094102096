import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import CheckOutTable from "../../features/check-out-table";
import useMixpanelContext from "../../context/mixpanel-context";
import { KIOSK, KIOSK_STEPS } from "../../constants/mixpanel-constant/kiosk";

export const CheckOutTableStackNavigation = () => {
  const { sendMixPanelEvent } = useMixpanelContext();

  useEffect(() => {
    sendMixPanelEvent(KIOSK, KIOSK_STEPS.ENTRY);
  }, []);
  return (
    <Route
      path="/check-out"
      component={CheckOutTable}
    />
  );
};
