import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MultiInputForm from "../../../../../../components/multi-input-form";
import Style from "./TopPanel.module.css";
import useCheckOutTableConfigContext from "../../../../contexts/check-out-table-config-context";
import useCheckOutTableStateContext from "../../../../contexts/check-out-table-state-context";
import { naturalSort } from "../../../../../../common/Utilities";

export const TopPanel = ({ onClear }) => {
  const { topPanelAttributes, readerLocationOptionCategory, locationOptionCategory } = useCheckOutTableConfigContext();
  const {
    locationOptionsMap = {},
    readerLocationOptionsMap = {},
    panelValues,
    setPanelValues,
    itemByTag
  } = useCheckOutTableStateContext();

  const locationOptions = useMemo(() => {
    const tempOptions = Object.values(locationOptionsMap).filter((location) => {
      return location.category === locationOptionCategory;
    });
    return naturalSort(tempOptions, "label");
  }, [locationOptionsMap, locationOptionCategory]);

  const readerLocationOptions = useMemo(() => {
    const tempOptions = Object.values(readerLocationOptionsMap).filter((location) => {
      return location.category === readerLocationOptionCategory;
    });
    return naturalSort(tempOptions, "label");
  }, [readerLocationOptionsMap, readerLocationOptionCategory]);

  const topPanelForm = useMemo(() => {
    return topPanelAttributes.map((eachField) => {
      const { id } = eachField;
      if (id === "location") {
        return { ...eachField, value: panelValues[id], options: locationOptions };
      }

      if (id === "readerLocation") {
        return { ...eachField, value: panelValues[id], options: readerLocationOptions };
      }
      return { ...eachField, value: panelValues[id] };
    });
  }, [topPanelAttributes, readerLocationOptions, locationOptions, panelValues]);

  const onTopPanelChange = (id, value) => {
    const clonedTopPanelValueMap = { ...panelValues };
    setPanelValues({ ...clonedTopPanelValueMap, [id]: value });
  };

  const itemCount = useMemo(() => {
    return Object.keys(itemByTag).length;
  }, [itemByTag]);

  return (
    <div className={Style.top_panel_container}>
      <div className={Style.status_count_section}>
        <p className={Style.status_count_label}>Items Read: </p>
        <p className={Style.status_count_value}>{itemCount}</p>
      </div>
      <MultiInputForm
        formFields={topPanelForm}
        onChange={onTopPanelChange}
        fieldContainerClassName={Style.top_panel_field_container}
        containerClassName={Style.input_fields_container}
      />
      <div className={Style.status_section}>
        {panelValues.readerLocation && (
          <div className={Style.status_section_content}>
            <div className={Style.status_text}>Reading in Progress...</div>
          </div>
        )}
      </div>
      <button
        type="button"
        disabled={!Object.keys(itemByTag).length}
        className={Style.clear_all_button}
        onClick={onClear}
      >
        Clear All
      </button>
    </div>
  );
};

TopPanel.defaultProps = {
  onClear: () => {}
};

TopPanel.propTypes = {
  onClear: PropTypes.func
};
