import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { CheckboxListFilter } from "../../../../components/checkbox-list-filter/CheckboxListFilter";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { DatePicker } from "../../../../components/DateTimePicker/DateTimePicker";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import { applySideFilters } from "../../services/side-filter-configuration-provider/sideFilterConfigurationProvider";
import { TEMPLATE_OPTIONS, STATUS_MAP, STATUS_TITLE_MAP, STATUS_SEVERITY_MAP } from "../../data/constants";
import { FILTER_TYPE } from "../../../../components/filters";
import Style from "../../components/side-filter/SideFilter.module.css";
import TagInput from "../../../../components/tag-input";

export const useSideFilterConfigBuilder = (filterConfig, getCustomTemplateOptionsFn) => {
  const [initialFilterValue, setInitialFilterValue] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const xemelgoClient = useXemelgoClient();

  const getOptionsFromParentCheckboxes = (filter, parentFilter, allOptions = []) => {
    let isOptionSelected = false;
    const selectedCategoryChildren = parentFilter.reduce((acc, eachCategory) => {
      if (eachCategory.value) {
        isOptionSelected = true;
        const subOptions = eachCategory.subOptions?.[filter.optionTemplate];

        if (subOptions) {
          acc.push(
            ...allOptions.filter((eachOption) => {
              return subOptions.has(eachOption.id);
            })
          );
        }
      }
      return acc;
    }, []);

    return isOptionSelected ? selectedCategoryChildren : undefined;
  };

  const getOptionsFromParentDropdown = (filter, parentFilter, allOptions) => {
    const isOptionSelected = parentFilter.value && Object.keys(parentFilter.value).length;
    const subOptions = isOptionSelected && parentFilter.value.subOptions?.[filter.optionTemplate];
    return isOptionSelected && subOptions
      ? allOptions.filter((eachOption) => {
          return subOptions.has(eachOption.id);
        })
      : undefined;
  };

  const getFilterOptions = (filter, sideFilterValues) => {
    let defaultOptions;
    switch (filter.type) {
      case FILTER_TYPE.checkboxes:
        defaultOptions = sideFilterValues[filter.id];
        break;
      case FILTER_TYPE.searchDropdown:
        defaultOptions = sideFilterValues[filter.id]?.options;
        break;
      default:
        defaultOptions = [];
        break;
    }

    const { parentFilterIds } = filter;

    if (!parentFilterIds || !parentFilterIds.length) {
      return defaultOptions;
    }

    // Use the first selected parent filter from parentFilterIds to determine the options
    const optionsFromParentFilter = parentFilterIds.reduce((options, parentFilterId) => {
      const parentFilter = sideFilterValues[parentFilterId];
      if (!options && parentFilter) {
        return parentFilter.options
          ? getOptionsFromParentDropdown(filter, parentFilter, defaultOptions)
          : getOptionsFromParentCheckboxes(filter, parentFilter, defaultOptions);
      }

      return options;
    }, undefined);

    return optionsFromParentFilter || defaultOptions;
  };

  const getDateRangeConfigStructure = (eachFilter) => {
    return {
      id: eachFilter.id,
      title: eachFilter.title,
      filters: [
        {
          id: "fromDateTime",
          title: "From:",
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <DatePicker
                color="#4A90FF"
                maxDate={sideFilterValues[eachFilter.id]?.endTime}
                placeholder="Start date"
                value={sideFilterValues[eachFilter.id]?.startTime}
                onTimeChange={(timestamp) => {
                  updateFilterValues({ [eachFilter.id]: { ...sideFilterValues[eachFilter.id], startTime: timestamp } });
                }}
                inputContainerClassName={Style.start_date_picker_input_container}
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            return (
              !sideFilterValues[eachFilter.id] ||
              !sideFilterValues[eachFilter.id].startTime ||
              (eachItem[eachFilter.id] && sideFilterValues[eachFilter.id].startTime <= eachItem[eachFilter.id])
            );
          }
        },
        {
          id: "toDateTime",
          title: "To:",
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <DatePicker
                color="#4A90FF"
                minDate={sideFilterValues[eachFilter.id]?.startTime}
                placeholder="End date"
                value={sideFilterValues[eachFilter.id]?.endTime}
                onTimeChange={(timestamp) => {
                  updateFilterValues({ [eachFilter.id]: { ...sideFilterValues[eachFilter.id], endTime: timestamp } });
                }}
                inputContainerClassName={Style.date_picker_input_container}
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            return (
              !sideFilterValues[eachFilter.id] ||
              !sideFilterValues[eachFilter.id].endTime ||
              (eachItem[eachFilter.id] && sideFilterValues[eachFilter.id]?.endTime >= eachItem[eachFilter.id])
            );
          }
        }
      ]
    };
  };

  const getCheckboxesConfigStructure = (eachFilter) => {
    return {
      id: eachFilter.id,
      title: eachFilter.title,
      filters: [
        {
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <CheckboxListFilter
                onChange={(newCheckboxList) => {
                  const checkedCheckboxSet = new Set(
                    newCheckboxList
                      .filter((eachCheckbox) => {
                        return eachCheckbox.value;
                      })
                      .map((eachCheckbox) => {
                        return eachCheckbox.id;
                      })
                  );

                  const updatedCheckboxList = sideFilterValues[eachFilter.id].map((eachCheckbox) => {
                    return {
                      ...eachCheckbox,
                      value: checkedCheckboxSet.has(eachCheckbox.id)
                    };
                  });
                  updateFilterValues({ [eachFilter.id]: updatedCheckboxList });
                }}
                checkboxList={getFilterOptions(eachFilter, sideFilterValues)}
                containerClassName={Style.checkbox_list_container}
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            switch (eachFilter.id) {
              case TEMPLATE_OPTIONS.status:
                const selectedStatusList = (sideFilterValues[eachFilter.id] || []).filter((eachCheckbox) => {
                  return eachCheckbox.value;
                });
                let hasStatus = !selectedStatusList.length || false;
                (eachItem[eachFilter.id] || []).forEach((eachStatus) => {
                  const { status, severity } = eachStatus;
                  selectedStatusList.forEach((eachSelectedStatus) => {
                    const { status: selectedStatus, severity: selectedSeverity } = eachSelectedStatus;
                    if (status === selectedStatus && severity === selectedSeverity) {
                      hasStatus = true;
                    }
                  });
                });
                return hasStatus;
              default:
                const selectedList = [];
                (sideFilterValues[eachFilter.id] || []).forEach((eachCheckbox) => {
                  if (eachCheckbox.value) {
                    selectedList.push(eachCheckbox.id?.toLowerCase?.());
                  }
                });

                if (!selectedList.length) {
                  return true;
                }
                if (Array.isArray(eachItem[eachFilter.id])) {
                  return eachItem[eachFilter.id].some((eachItemValue) => {
                    return selectedList.includes(eachItemValue?.toLowerCase?.());
                  });
                }
                return selectedList.includes(eachItem[eachFilter.id]?.toLowerCase?.());
            }
          }
        }
      ]
    };
  };

  const getSearchDropdownConfigStructure = (eachFilter) => {
    return {
      id: eachFilter.id,
      title: eachFilter.title,
      filters: [
        {
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <SearchDropdown
                placeholder="Search"
                options={getFilterOptions(eachFilter, sideFilterValues)}
                onItemSelected={(newSelectedItem) => {
                  updateFilterValues({
                    [eachFilter.id]: { value: newSelectedItem, options: sideFilterValues[eachFilter.id]?.options || [] }
                  });
                }}
                selectedItem={sideFilterValues[eachFilter.id]?.value || {}}
                showIcon
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            if (sideFilterValues[eachFilter.id]?.value === null || !sideFilterValues[eachFilter.id]?.value?.id) {
              return true;
            }

            if (Array.isArray(eachItem[eachFilter.id])) {
              return eachItem[eachFilter.id].some((eachItemValue) => {
                return sideFilterValues[eachFilter.id].value.id === eachItemValue;
              });
            }
            return sideFilterValues[eachFilter.id].value.id === eachItem[eachFilter.id];
          }
        }
      ]
    };
  };

  const getInputConfigStructure = (eachFilter) => {
    return {
      id: eachFilter.id,
      title: eachFilter.title,
      filters: [
        {
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <TagInput
                values={sideFilterValues[eachFilter.id]?.value}
                onChange={(newValue) => {
                  updateFilterValues({ [eachFilter.id]: { value: newValue } });
                }}
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            return (
              !sideFilterValues[eachFilter.id]?.value?.length ||
              sideFilterValues[eachFilter.id].value.some((eachFilterValue) => {
                if (Array.isArray(eachItem[eachFilter.id])) {
                  return eachItem[eachFilter.id]?.some((eachItemValue) => {
                    return eachItemValue?.toLowerCase()?.includes(eachFilterValue?.toLowerCase());
                  });
                }
                return eachItem[eachFilter.id]?.toLowerCase()?.includes(eachFilterValue?.toLowerCase());
              })
            );
          }
        }
      ]
    };
  };

  const getFilterStructureBuilderByType = (type) => {
    switch (type) {
      case FILTER_TYPE.checkboxes:
        return getCheckboxesConfigStructure;
      case FILTER_TYPE.dateRange:
        return getDateRangeConfigStructure;
      case FILTER_TYPE.searchDropdown:
        return getSearchDropdownConfigStructure;
      case FILTER_TYPE.input:
        return getInputConfigStructure;
      default:
        return () => {};
    }
  };

  const filterConfiguration = useMemo(() => {
    const config = { categories: [] };
    filterConfig.forEach((eachFilter) => {
      config.type = eachFilter.type;
      const filterStructureBuilder = getFilterStructureBuilderByType(eachFilter.type);
      const newCategory = filterStructureBuilder(eachFilter);
      if (newCategory) {
        config.categories.push(newCategory);
      }
    });
    return config;
  }, [filterConfig]);

  const getTemplateOptionsByType = (optionId, eachFilterConfig) => {
    if (typeof getCustomTemplateOptionsFn === "function") {
      const response = getCustomTemplateOptionsFn(optionId, eachFilterConfig);
      if (response !== undefined) {
        return response;
      }
    }
    switch (optionId) {
      case TEMPLATE_OPTIONS.location:
        return fetchLocation(eachFilterConfig.locationCategories);
      case TEMPLATE_OPTIONS.itemType:
        return fetchItemType(eachFilterConfig.itemTypeCategories);
      case TEMPLATE_OPTIONS.status:
        return fetchStatus();
      case TEMPLATE_OPTIONS.itemTypeCategory:
        return fetchCategoriesOfDepth(eachFilterConfig.categoryDepth);
      default:
        return [];
    }
  };

  const fetchLocation = async (categories) => {
    const locationClient = xemelgoClient.getLocationClient();
    const locationTreeMap = await locationClient.getLocationTree([], categories);
    return Object.values(locationTreeMap)
      .map((eachLocation) => {
        return { id: eachLocation.id, label: eachLocation.name };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  };

  const fetchItemType = async (categories) => {
    const itemTypeClient = xemelgoClient.getItemTypeClient();
    const itemTypes = await itemTypeClient.getItemTypes(["id", "identifier"], categories);
    return itemTypes
      .map((eachItemType) => {
        return { id: eachItemType.identifier, label: eachItemType.identifier };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  };

  const fetchStatus = () => {
    return [
      {
        id: `${STATUS_MAP.timeExceeded} - ${STATUS_SEVERITY_MAP.critical}`,
        label: `${STATUS_TITLE_MAP[STATUS_MAP.timeExceeded]} - ${STATUS_SEVERITY_MAP.critical}`,
        status: STATUS_MAP.timeExceeded,
        severity: STATUS_SEVERITY_MAP.critical
      },
      {
        id: `${STATUS_MAP.timeExceeded} - ${STATUS_SEVERITY_MAP.warning}`,
        label: `${STATUS_TITLE_MAP[STATUS_MAP.timeExceeded]} - ${STATUS_SEVERITY_MAP.warning}`,
        status: STATUS_MAP.timeExceeded,
        severity: STATUS_SEVERITY_MAP.warning
      },
      {
        id: `${STATUS_MAP.lateToStart} - ${STATUS_SEVERITY_MAP.critical}`,
        label: `${STATUS_TITLE_MAP[STATUS_MAP.lateToStart]} - ${STATUS_SEVERITY_MAP.critical}`,
        status: STATUS_MAP.lateToStart,
        severity: STATUS_SEVERITY_MAP.critical
      },
      {
        id: `${STATUS_MAP.lateToStart} - ${STATUS_SEVERITY_MAP.warning}`,
        label: `${STATUS_TITLE_MAP[STATUS_MAP.lateToStart]} - ${STATUS_SEVERITY_MAP.warning}`,
        status: STATUS_MAP.lateToStart,
        severity: STATUS_SEVERITY_MAP.warning
      },
      {
        id: STATUS_MAP.expedited,
        label: STATUS_TITLE_MAP[STATUS_MAP.expedited],
        status: STATUS_MAP.expedited,
        severity: STATUS_SEVERITY_MAP.expedited
      },
      {
        id: STATUS_MAP.inProgress,
        label: STATUS_TITLE_MAP[STATUS_MAP.inProgress],
        status: STATUS_MAP.inProgress,
        severity: STATUS_SEVERITY_MAP.healthy
      },
      {
        id: STATUS_MAP.planned,
        label: STATUS_TITLE_MAP[STATUS_MAP.planned],
        status: STATUS_MAP.planned,
        severity: STATUS_SEVERITY_MAP.healthy
      }
    ];
  };

  const fetchCategoriesOfDepth = async (depth = 0) => {
    const categoryClient = xemelgoClient.getCategoryClient();

    const flatCategoryTrees = await categoryClient.getFlatCategoryTrees();
    return Object.values(flatCategoryTrees)
      .reduce((acc, category) => {
        const { depth: categoryDepth, childCategoryIds, identifier, hasInstance } = category;
        if (categoryDepth !== depth) {
          return acc;
        }

        const categoryOption = {
          id: identifier,
          label: identifier,
          subOptions: {
            [TEMPLATE_OPTIONS.itemTypeCategory]: new Set(
              childCategoryIds.map((childCategoryId) => {
                return flatCategoryTrees[childCategoryId]?.identifier || "";
              })
            ),
            [TEMPLATE_OPTIONS.itemType]: new Set(
              hasInstance.map((itemType) => {
                return itemType.identifier;
              })
            )
          }
        };

        return [...acc, categoryOption];
      }, [])
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  };

  const updateInitialFilterValue = async () => {
    setIsLoading(true);
    const initialFilterValueObj = {};
    await Promise.all(
      filterConfig.map(async (eachFilter) => {
        switch (eachFilter.type) {
          case FILTER_TYPE.checkboxes:
            initialFilterValueObj[eachFilter.id] = eachFilter.optionTemplate
              ? await getTemplateOptionsByType(eachFilter.optionTemplate, eachFilter)
              : [...(eachFilter.options || [])];
            break;
          case FILTER_TYPE.searchDropdown:
            initialFilterValueObj[eachFilter.id] = {
              options: eachFilter.optionTemplate
                ? await getTemplateOptionsByType(eachFilter.optionTemplate, eachFilter)
                : [...(eachFilter.options || [])],
              value: eachFilter.defaultValue || null
            };
            break;
          case FILTER_TYPE.dateRange:
            initialFilterValueObj[eachFilter.id] = { startTime: null, endTime: null };
            break;
          default:
            break;
        }
      })
    );
    setInitialFilterValue(initialFilterValueObj);
    setIsLoading(false);
  };

  useEffect(() => {
    updateInitialFilterValue();
  }, [filterConfig, getCustomTemplateOptionsFn]);

  const getOnlyActiveValues = useCallback(
    (filterValue) => {
      const clonedFilterValue = _.cloneDeep(filterValue);
      return filterConfig.reduce((acc, { type: eachFilterType, id: eachFilterId }) => {
        let eachFinalValue = null;
        switch (eachFilterType) {
          case FILTER_TYPE.checkboxes:
            eachFinalValue = clonedFilterValue[eachFilterId]?.filter((eachValue) => {
              return eachValue.value;
            });

            if (!eachFinalValue?.length) {
              eachFinalValue = null;
            }
            break;

          case FILTER_TYPE.dateRange:
            eachFinalValue = clonedFilterValue[eachFilterId];
            if (!eachFinalValue?.startTime && !eachFinalValue?.endTime) {
              eachFinalValue = null;
            }
            break;
          case FILTER_TYPE.searchDropdown:
            eachFinalValue = clonedFilterValue[eachFilterId]?.value;
            if (
              !Object.values(eachFinalValue || {}).filter((eachAttributeValue) => {
                return !!eachAttributeValue;
              }).length
            ) {
              eachFinalValue = null;
            }
            break;
          case FILTER_TYPE.input:
            eachFinalValue = clonedFilterValue[eachFilterId]?.value;
            break;
          default:
            break;
        }
        if (eachFinalValue) {
          return { ...acc, [eachFilterId]: eachFinalValue };
        }
        return acc;
      }, {});
    },
    [filterConfig]
  );

  const applyActiveValueOnInitialFilterValue = useCallback(
    (activeValue) => {
      const clonedInitialFilterValue = _.cloneDeep(initialFilterValue);
      return filterConfig.reduce((acc, { type: eachFilterType, id: eachFilterId }) => {
        switch (eachFilterType) {
          case FILTER_TYPE.checkboxes:
            return {
              ...acc,
              [eachFilterId]: clonedInitialFilterValue?.[eachFilterId]?.map?.((eachOption) => {
                return {
                  ...eachOption,
                  value:
                    activeValue?.[eachFilterId]
                      ?.filter(({ value }) => {
                        return value;
                      })
                      .map(({ id }) => {
                        return id;
                      })
                      .includes(eachOption.id) || eachOption.value
                };
              })
            };
          case FILTER_TYPE.dateRange:
            return {
              ...acc,
              [eachFilterId]: {
                startTime:
                  activeValue?.[eachFilterId]?.startTime || clonedInitialFilterValue?.[eachFilterId]?.startTime,
                endTime: activeValue?.[eachFilterId]?.endTime || clonedInitialFilterValue?.[eachFilterId]?.endTime
              }
            };
          case FILTER_TYPE.searchDropdown:
            return {
              ...acc,
              [eachFilterId]: {
                ...(clonedInitialFilterValue?.[eachFilterId] || {}),
                value: activeValue?.[eachFilterId] || clonedInitialFilterValue?.[eachFilterId]?.value
              }
            };
          case FILTER_TYPE.input:
            return {
              ...acc,
              [eachFilterId]: { value: activeValue?.[eachFilterId] || clonedInitialFilterValue?.[eachFilterId]?.value }
            };
          default:
            return acc;
        }
      }, {});
    },
    [initialFilterValue, filterConfig]
  );

  return {
    filterConfiguration,
    initialFilterValue,
    applySideFilters,
    isLoading,
    getOnlyActiveValues,
    applyActiveValueOnInitialFilterValue
  };
};
