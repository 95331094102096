import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import xemelgoStyle from "../../../../styles/variable";
import Selector from "../../../selector";
import Style from "../../PrinterSelectionComponent.module.css";

export const PrinterSelection = ({
  isLoading,
  printersList,
  handleOnSelectPrinter,
  errorMessage,
  selectedPrinter,
  printerInfo
}) => {
  return (
    <div className={Style.body_child_container}>
      <p className={`${xemelgoStyle.subtextBold} ${Style.sub_header}`}>
        Printer
        <span className={Style.asterisk}> *</span>
      </p>
      <div className={Style.dropdown_input_container}>
        <Selector
          disabled={isLoading}
          disableTabFocus
          options={printersList}
          onSelect={handleOnSelectPrinter}
          value={Object.keys(selectedPrinter).length ? selectedPrinter : printerInfo.name}
          placeholder="Select a printer"
          error={!!errorMessage}
          errorMessage={errorMessage}
        />
      </div>
      <div className={Style.info_card}>
        <div className={Style.info_card_row}>
          <div className={Style.info_card_row_label_container}>
            <p className={Style.info_card_row_label}>Status: </p>
          </div>
          {isLoading ? (
            <Skeleton containerClassName={Style.skeleton_loading} />
          ) : (
            <div className={Style.info_card_row_value_container}>
              {printerInfo?.message?.statusColor && (
                <div className={`${Style.status_icon} ${Style[printerInfo?.message?.statusColor]}`} />
              )}
              <p className={Style.info_card_row_value}>{printerInfo?.message?.value || "-"}</p>
            </div>
          )}
        </div>
        <div className={Style.info_card_row}>
          <div className={Style.info_card_row_label_container}>
            <p className={Style.info_card_row_label}>IP: </p>
          </div>
          {isLoading ? (
            <Skeleton containerClassName={Style.skeleton_loading} />
          ) : (
            <div className={Style.info_card_row_value_container}>
              <p className={Style.info_card_row_value}>{printerInfo?.id?.value || "-"}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PrinterSelection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  printersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleOnSelectPrinter: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  selectedPrinter: PropTypes.shape({}).isRequired,
  printerInfo: PropTypes.shape({
    message: PropTypes.shape({
      statusColor: PropTypes.string,
      value: PropTypes.string
    }),
    id: PropTypes.shape({
      value: PropTypes.string
    })
  }).isRequired
};
