export const TITLE = "Goods Receipt";

export const TABLE_COLUMN_SIZE_MAP = {
  small: 2,
  medium: 5,
  large: 8,
  default: 1
};

export const ROW_COUNT_HEADER_LABEL = "#";

export const ROW_COUNT_ATTRIBUTE = "rowCount";

export const CLEAR_BUTTON_ID = "clearButton";

export const CLEAR_BUTTON_SECTION = {
  attributes: [
    {
      id: CLEAR_BUTTON_ID
    }
  ]
};

export const ACTIONS_MAP = {
  CHECK_OUT: "check-out",
  ADD: "add",
  MOVE: "move",
  CONSUME: "consume",
  UNCONSUME: "unconsume"
};

export const OPTION_TEMPLATE_OPTIONS = {
  USERS: "users"
};

export const QUERY_PAGE = 50;
