import React from "react";
import Skeleton from "react-loading-skeleton";
import useAssetTrackPageConfigContext from "../../contexts/asset-track-page-config-context";
import useAssetTrackPageStateContext from "../../contexts/asset-track-page-state-context";
import useAssetTrackPageDataSourceContext from "../../contexts/asset-track-page-data-source-context";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import Style from "./MetricsOverview.module.css";

export const MetricsOverview = () => {
  const { locationToMetricFieldsMap } = useAssetTrackPageDataSourceContext();
  const { locationMetricsLoading, selectedLocationId } = useAssetTrackPageStateContext();
  const { locationMetricsFields } = useAssetTrackPageConfigContext();

  return (
    <div className={Style.metrics_container}>
      <p className={Style.title}>Metrics</p>
      <div className={Style.grid_container}>
        {locationMetricsLoading
          ? locationMetricsFields.map((metric) => {
              return (
                <Skeleton
                  key={metric.id}
                  className={Style.loading_box}
                />
              );
            })
          : locationToMetricFieldsMap[selectedLocationId || "root"]?.map((metric) => {
              return (
                <div
                  key={metric.id}
                  className={Style.metric_box}
                  style={{ backgroundColor: metric.backgroundColor }}
                  data-cy={`metrics-overview-${metric.id}`}
                >
                  <div
                    className={Style.metric_value}
                    style={{ color: metric.textColor }}
                  >
                    {metric.value.toLocaleString()}
                  </div>
                  <div className={Style.metric_label_container}>
                    <div className={Style.metric_label}>{metric.label}</div>
                    {metric.description && (
                      <div className={Style.info_container}>
                        <InfoIcon
                          strokeWidth={20}
                          className={Style.info_icon}
                        />
                        <div className={Style.metric_description_container}>{metric.description}</div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};
