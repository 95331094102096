import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { DropdownItem } from "mdbreact";
import { Modal } from "react-bootstrap";
import { InputLabel } from "@material-ui/core";
import TextareaAutosize from "react-textarea-autosize";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import { multipleSortComparison } from "../../../utils";
import { useXemelgoClient } from "../../../services/xemelgo-service";
import {
  getNumberOfDaysToDate,
  getFormattedDate,
  msToTime,
  getStatusFlags,
  getCurrentTimestamp,
  formatProcessStateRoute,
  getAttributeValue,
  uploadImagesToS3
} from "../../../common/Utilities";
import LoadingCircle from "../../loading/LoadingCircle";
import DetailCard from "../DetailCard";
import Style from "./css/ItemDetailPage.module.css";
import DataTable from "../DataTable";
import { ReactComponent as InventoryIcon } from "../../../assets/icons/inventory.svg";
import ScreenFrame from "../../ScreenFrame/ScreenFrame";
import xemelgoStyle from "../../../styles/variable";
import { useAppConfigProvider, useConfigurationProvider } from "../../../services/soft-cache-service";
import { LocalCacheService } from "../../../services/local-cache-service";
import { ModalForm } from "../../modal-form";
import ModalFormFooter from "../../modal-form/components/modal-form-footer";

const APP_ID = "inventory";
const ITEM_CONFIG = "attributeMap";
const DETECTOR_LOCATION_CONFIG = "possibleDetectorLocations";
const STATUS_MAP = "statusFlagMap";
const IS_GOODS_RECEIPT_ENABLED = "goodsReceiptEnabled";
const DETAILS_PAGE = "detailsPage";

const ItemDetailPage = ({ history }) => {
  const [ItemClient] = useState(useXemelgoClient().getItemClient());
  const [InventoryClient] = useState(useXemelgoClient().getInventoryClient());
  const [SensorProfileClient] = useState(useXemelgoClient().getSensorProfileClient());
  const [PublishClient] = useState(useXemelgoClient().getPublishClient());
  const [LocationClient] = useState(useXemelgoClient().getLocationClient());
  const [PurchaseOrderClient] = useState(useXemelgoClient().getPurchaseOrderClient());

  const configProvider = useAppConfigProvider(APP_ID);
  const SolutionTypes = useConfigurationProvider().config.solutionType;
  const SolutionConfig = useConfigurationProvider().config.solutionConfiguration;
  const LocationRoleConfig = useConfigurationProvider().config.features.locationRole || {};
  const InventoryAttributeConfig = configProvider.getValue(ITEM_CONFIG, "object");
  const DetectorLocations = configProvider.getValue(DETECTOR_LOCATION_CONFIG, "array");
  const SolutionStatuses = configProvider.getValue(STATUS_MAP, "object");
  const IsGoodsReceiptEnabled = configProvider.getValue(IS_GOODS_RECEIPT_ENABLED, "boolean");
  const DetailsPageConfig = configProvider.getValue(DETAILS_PAGE, "object") || {};
  const {
    consumeItemOptions = {},
    processStateHistory: ProcessStateHistory = true,
    processStates: ProcessStates = []
  } = DetailsPageConfig;
  const [inventoryAttributes, setInventoryAttributes] = useState([]);
  const [objectAttributeInfo, setObjectAttributeInfo] = useState({});
  const [itemInfo, setItemInfo] = useState({});
  const [shipmentHistory, setShipmentHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [titleStructure, setTitleStructure] = useState(null);
  const [contentStructureList, setContentStructureList] = useState(null);
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [possibleDetectorLocations, setPossibleDetectorLocations] = useState([]);
  const [locationToAdd, setLocationToAdd] = useState({});
  const [showShipmentData, setShowShipmentData] = useState(false);
  const [showShipmentIssueModal, setShowShipmentIssueModal] = useState(false);
  const [showConsumeModal, setShowConsumeModal] = useState(false);
  const [shipmentIssueOptions, setShipmentIssueOptions] = useState([]);
  const [shipmentIssue, setShipmentIssue] = useState({});
  const [orderFulfillmentQuantityKey, setOrderFulfillmentQuantityKey] = useState(null);

  const routeTitleList = [
    {
      key: "location",
      value: "Location"
    },
    {
      key: "entryTime",
      value: "Entry Time"
    },
    { key: "exitTime", value: "Exit Time" },
    { key: "timeDuration", value: "Time Duration" }
  ];
  const processProcessStateRouteTitleList = [
    {
      key: "location",
      value: "Location"
    },
    { key: "state", value: "State" },
    {
      key: "eventTime",
      value: "Time"
    },
    { key: "timeDuration", value: "Time Duration" }
  ];
  const shipmentTitleList = [
    {
      key: "shipmentLocation",
      value: "Location"
    },
    {
      key: "eventTime",
      value: "Event Time"
    },
    { key: "eventDetails", value: "Event Details", extraData: true },
    { key: "detector", value: "Detector" },
    { key: "shipmentTimeDuration", value: "Time Duration" }
  ];
  const defaultShipmentIssueOptionsList = [
    {
      key: "broken",
      label: "Broken Part(s)",
      value: "broken"
    }
  ];

  const customerSpecificShipmentIssueOptionsList = [
    {
      key: "damaged",
      label: "Fallout",
      value: "damaged"
    }
  ];

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = async () => {
    const payload = queryString.parse(history.location.search);
    const { itemId } = payload;
    const trackingLocation = DetectorLocations.length ? DetectorLocations[0] : "Department";
    const showShipmentData =
      SolutionTypes.includes("shipments") &&
      SolutionConfig.shipments.shipmentsTrackingItemClasses.includes("Inventory");
    const { defaultAttributeMap } = InventoryAttributeConfig;
    const data = await InventoryClient.getItemDetailsById(
      itemId,
      showShipmentData,
      defaultAttributeMap,
      LocationRoleConfig.enabled
    );

    // if the url provided is invalid, redirect back to the track page
    // this shouldn't really happen unless the user is trying to mess with the url
    if (!data) {
      const fullPath = history.location.pathname;
      const parentPath = fullPath.slice(0, fullPath.indexOf("/item"));
      history.replace(parentPath);
      return;
    }
    const locations = await LocationClient.getLocationsOfCategory(trackingLocation);
    const { item, shipmentHistory } = data;
    const inventoryAttributes = getAttributeMap(item);
    const titleStructure = {
      key: inventoryAttributes[0].key,
      name: inventoryAttributes[0].name,
      value: inventoryAttributes[0].value,
      editable: inventoryAttributes[0].editable
    };

    const contentStructureList = inventoryAttributes.slice(1);

    const shipmentIssueOptions = [...defaultShipmentIssueOptionsList, ...customerSpecificShipmentIssueOptionsList].sort(
      multipleSortComparison([{ id: "label" }])
    );

    item.route.forEach((each) => {
      const duration = each.exitTime ? msToTime(each.exitTime - each.entryTime) : msToTime(Date.now() - each.entryTime);

      each.timeDuration = duration;
      each.entryTime = getFormattedDate(each.entryTime, "hh:mm A MMM D");
      each.exitTime = each.exitTime ? getFormattedDate(each.exitTime, "hh:mm A MMM D") : "-";
    });

    item.processStateRoute = formatProcessStateRoute(item.processStateRoute, ProcessStates);

    item.status_flags = getStatusFlags(item.status_flags, SolutionStatuses);

    const filteredLocations = locations
      .filter((eachLocation) => {
        return eachLocation.id !== item.data.last_known_location_id;
      })
      .sort(multipleSortComparison([{ id: "name" }]));
    const newLocations = [];
    filteredLocations.map((each) => {
      return newLocations.push({
        key: each.id,
        label: each.name,
        value: each.id
      });
    });
    // Process event time and duration for each event
    if (showShipmentData) {
      shipmentHistory.forEach((row, index) => {
        const currEvent = row.eventTime;
        const prevEvent = shipmentHistory[index + 1] && shipmentHistory[index + 1].eventTime;
        row.shipmentTimeDuration = msToTime((prevEvent || Date.now()) - currEvent);
        row.eventTime = getFormattedDate(row.eventTime, "hh:mm A MMM D");
        const { condition, comment, submittedBy } = row.eventDetails;
        if (condition || comment || submittedBy) {
          row.extraData = [
            { key: "condition", value: "Condition", data: condition },
            { key: "comment", value: "Comment", data: comment },
            { key: "submittedBy", value: "Submitted By", data: submittedBy }
          ];
        }
        row.eventDetails = row.eventDetails.class;
      });
    }

    if (consumeItemOptions.enabled) {
      const isConsumed = item.data.is_consumed;
      contentStructureList.push({
        key: "consuemItem",
        name: isConsumed ? "Unconsume Item" : "Consume Item",
        type: "button",
        light: true,
        className: Style.consume_button,
        onClick: () => {
          setShowConsumeModal(true);
        }
      });
    }

    // extract edit-related fields for each attribute from config for EditForm
    const object_attribute_info = {};
    inventoryAttributes.forEach((inventoryAttributeObject) => {
      object_attribute_info[inventoryAttributeObject.key] = {
        name: inventoryAttributeObject?.name,
        editable: inventoryAttributeObject?.editable,
        numberOnly: inventoryAttributeObject?.numberOnly,
        type: inventoryAttributeObject?.type || "input"
      };
    });

    setItemInfo(item);
    setTitleStructure(titleStructure);
    setContentStructureList(contentStructureList);
    setShipmentIssueOptions(shipmentIssueOptions);
    setShowShipmentData(showShipmentData);
    setShipmentHistory(shipmentHistory);
    setPossibleDetectorLocations(newLocations);
    setInventoryAttributes(inventoryAttributes);
    setObjectAttributeInfo(object_attribute_info);
    setLoading(false);
  };

  const getAttributeMap = (item) => {
    const { customFields } = item;
    let defaultMap = InventoryAttributeConfig.defaultAttributeMap;
    let customMap = InventoryAttributeConfig.customAttributeMap;
    defaultMap = Object.keys(defaultMap).map((eachId) => {
      const { type, format } = defaultMap[eachId];
      if (defaultMap[eachId].propertyForOrderFulfillmentQuantity) {
        setOrderFulfillmentQuantityKey(eachId);
      }
      const attributeInfo = {
        index: defaultMap[eachId].index,
        key: eachId,
        name: `${defaultMap[eachId].label}`,
        numberOnly: defaultMap[eachId]?.numberOnly,
        value: item[eachId] === 0 ? item[eachId] : getAttributeValue(defaultMap[eachId], item, eachId),
        editable: defaultMap[eachId].editable.single,
        type,
        format
      };
      // if there is special formatting/info that needs to attached to attribute
      switch (type) {
        case "datepicker":
          if (eachId === "expiry_date") {
            const { expiryColor, expiryLabel } = getExpiryInfo(item[eachId]);
            attributeInfo.format = "MM/DD/YYYY";
            attributeInfo.color = expiryColor;
            attributeInfo.additional = {
              expiryLabel
            };
          }
          break;
        default:
          break;
      }
      return attributeInfo;
    });

    customMap = Object.keys(customMap).map((eachId) => {
      const { type } = customMap[eachId];
      const attributeInfo = {
        index: customMap[eachId].index,
        key: eachId,
        name: `${customMap[eachId].label}`,
        value: getAttributeValue(customMap[eachId], item, eachId),
        editable: customMap[eachId].editable.single,
        type
      };
      // if there is special formatting/info that needs to attached to attribute
      switch (type) {
        default:
          break;
      }
      return attributeInfo;
    });

    let inventoryAttributes = [...defaultMap, ...customMap];

    inventoryAttributes = inventoryAttributes.sort((a, b) => {
      return a.index - b.index;
    });

    return inventoryAttributes;
  };

  const getExpiryInfo = (date) => {
    let expiryColor = null;
    let expiryLabel = null;
    if (date) {
      const daysToExpiry = getNumberOfDaysToDate(date);
      expiryLabel = daysToExpiry > 0 ? "Expires In " : "Expired ";
      expiryLabel +=
        daysToExpiry > 0
          ? `${daysToExpiry} day(s)`
          : daysToExpiry === 0
          ? "Today"
          : `${Math.abs(daysToExpiry)} day(s) ago`;

      expiryColor =
        daysToExpiry > 0 && daysToExpiry <= 7 ? "orange_text" : daysToExpiry <= 0 ? "red_text" : "green_text";
    }
    return { expiryColor, expiryLabel };
  };

  const getAdditionalMoreButtonOptions = () => {
    return (
      <>
        <DropdownItem
          onClick={() => {
            setShowDeleteModal(true);
          }}
          data-cy-inventory-detail-card__more-button-option
        >
          <div className={Style.red_text}>Delete Item</div>
        </DropdownItem>
      </>
    );
  };

  const getPurchaseOrderUpdatePayload = (purchaseOrderInfo = {}, changeInCost = 0) => {
    if (purchaseOrderInfo && typeof changeInCost === "number") {
      const { fulfillment_status, total_cost_by_quantities_fulfilled, includes, order_status } = purchaseOrderInfo;
      const purchaseOrderUpdatePayload = {
        total_cost_by_quantities_fulfilled: (total_cost_by_quantities_fulfilled || 0) + changeInCost
      };

      let shouldClosePO = true;
      let shouldOpenPO = true;
      let shouldFulfillPO = true;
      let shouldUnfulfillPO = true;

      if (includes.length > 0) {
        for (const itemOrder of includes) {
          // if at least one non-cancelled item order
          if (itemOrder.order_status !== "Cancelled") {
            if (!purchaseOrderUpdatePayload.fulfillment_status) {
              // is partially fulfilled
              if (itemOrder.fulfillment_status === "PartiallyFulfilled") {
                purchaseOrderUpdatePayload.fulfillment_status = "PartiallyFulfilled";
              }
              // is not unfulfilled
              if (itemOrder.fulfillment_status !== "Unfulfilled") {
                shouldUnfulfillPO = false;
              }
              // is not fulfilled
              if (itemOrder.fulfillment_status !== "Fulfilled") {
                shouldFulfillPO = false;
              }
            }
            // is not closed
            if (itemOrder.order_status !== "Closed") {
              shouldClosePO = false;
            }
            // is not open
            if (itemOrder.order_status !== "Open") {
              shouldOpenPO = false;
            }
          }
        }

        // do not update a cancelled order_status
        if (shouldClosePO && order_status !== "Cancelled") {
          purchaseOrderUpdatePayload.order_status = "Closed";
        }
        if (shouldFulfillPO) {
          purchaseOrderUpdatePayload.fulfillment_status = "Fulfilled";
        }
        // do not update a cancelled order_status
        if (shouldOpenPO && order_status !== "Cancelled") {
          purchaseOrderUpdatePayload.order_status = "Open";
        }
        if (shouldUnfulfillPO) {
          purchaseOrderUpdatePayload.fulfillment_status = "Unfulfilled";
        }
        return purchaseOrderUpdatePayload;
      }
      return purchaseOrderUpdatePayload;
    }
    return null;
  };

  const handleUpdateItem = async (updatedInfo) => {
    const { identifier, expiry_date } = updatedInfo;
    const titleLabel = inventoryAttributes[0].name;
    if (identifier === "") {
      const errorObject = {
        title: `Empty ${titleLabel}`,
        message: `Please enter a value for the ${titleLabel}`
      };
      setError(errorObject);
      setShowError(true);
    }

    setLoading(true);

    const propertiesToUpdate = updatedInfo;

    // Change numbers to int for BE:
    Object.keys(propertiesToUpdate).forEach((attributeKey) => {
      const info = objectAttributeInfo[attributeKey];
      if (info?.numberOnly) {
        let tmp = parseInt(propertiesToUpdate[attributeKey]);
        // must alert user that we're not saving their invalid input
        if (isNaN(tmp)) {
          const errorObject = {
            title: `Type Check Failed`,
            message: `Please enter a numerical value for ${info?.name}`
          };
          setError(errorObject);
          setShowError(true);
          setLoading(false);

          inventoryAttributes.forEach((inventoryAttribute) => {
            if (inventoryAttribute.key === attributeKey) {
              tmp = inventoryAttribute.value;
            }
          });
        }
        propertiesToUpdate[attributeKey] = tmp;
      }
    });

    if (Object.keys(propertiesToUpdate).length === 0) {
      setLoading(false);
      return;
    }
    try {
      if (IsGoodsReceiptEnabled && !orderFulfillmentQuantityKey) {
        throw "There seems to be an issue with the app configuration, please contact Xemelgo Support.";
      }
      // if goods receipt is enabled, update order fulfillment, item order and purchase order
      if (IsGoodsReceiptEnabled && orderFulfillmentQuantityKey && orderFulfillmentQuantityKey in propertiesToUpdate) {
        if (
          !typeof propertiesToUpdate[orderFulfillmentQuantityKey] === "number" ||
          propertiesToUpdate[orderFulfillmentQuantityKey] <= 0
        ) {
          throw "Please check the values entered and try again.";
        }
        // get existing order fulfillment info
        const itemOrderFulfillmentInfo = await PurchaseOrderClient.getItemOrderFulfillmentInfoByItemId(itemInfo.id);
        const itemOrderObject = itemOrderFulfillmentInfo.acquiredThrough.length
          ? itemOrderFulfillmentInfo.acquiredThrough[0]
          : null;
        if (itemOrderObject) {
          const {
            id,
            direct_unit_cost,
            quantity_fulfilled,
            shipping_charge,
            total_cost_by_quantity_fulfilled,
            quantity_ordered,
            partOfOrder,
            order_status
          } = itemOrderObject;

          const purchaseOrderId = partOfOrder && partOfOrder.length > 0 ? partOfOrder[0].id : null;
          if (
            direct_unit_cost === null ||
            shipping_charge === null ||
            quantity_ordered === null ||
            purchaseOrderId === null
          ) {
            throw "Some required data seems to be missing. Please contact Xemelgo support for further help.";
          }

          await InventoryClient.updateItemV2(itemInfo.id, propertiesToUpdate);

          const orderFulfillmentObject =
            itemOrderObject && itemOrderObject.fulfilledThrough.length > 0
              ? itemOrderObject.fulfilledThrough[itemOrderObject.fulfilledThrough.length - 1]
              : null;

          if (orderFulfillmentObject) {
            const currentFulfilledQuantity = orderFulfillmentObject.quantity;
            // item's quantity before update
            const currentItemQuantity =
              itemInfo[orderFulfillmentQuantityKey] || itemInfo?.customFields[orderFulfillmentQuantityKey] || 0;
            const newQuantity = propertiesToUpdate[orderFulfillmentQuantityKey];
            // change in value
            const delta = newQuantity - currentItemQuantity;
            const new_total_cost_by_quantity_fulfilled =
              ((quantity_fulfilled || 0) + delta) * direct_unit_cost + shipping_charge;

            if (delta !== 0) {
              if (currentFulfilledQuantity + delta >= 0 && new_total_cost_by_quantity_fulfilled >= 0) {
                const updateOrderFulfillmentPayload = {
                  fulfillment_date: getCurrentTimestamp(),
                  quantity: currentFulfilledQuantity + delta
                };
                // 1. Update order fulfillment quantity
                const updatedOrderFulfillment = await PurchaseOrderClient.updateOrderFulfillment(
                  orderFulfillmentObject.id,
                  updateOrderFulfillmentPayload
                );
                if (updatedOrderFulfillment) {
                  const changeInCost = new_total_cost_by_quantity_fulfilled - total_cost_by_quantity_fulfilled;
                  // 2. Update item order
                  const updatedItemOrder = await PurchaseOrderClient.updateItemOrder(id, {
                    quantity_fulfilled: (quantity_fulfilled || 0) + delta,
                    fulfillment_status:
                      (quantity_fulfilled || 0) + delta >= quantity_ordered
                        ? "Fulfilled"
                        : (quantity_fulfilled || 0) + delta <= 0
                        ? "Unfulfilled"
                        : "PartiallyFulfilled",
                    total_cost_by_quantity_fulfilled: new_total_cost_by_quantity_fulfilled,
                    order_status:
                      order_status === "Cancelled"
                        ? "Cancelled"
                        : (quantity_fulfilled || 0) + delta >= quantity_ordered
                        ? "Closed"
                        : "Open"
                  });
                  if (updatedItemOrder) {
                    const purchaseOrderInfo = await PurchaseOrderClient.getPurchaseOrderInfoById(purchaseOrderId);
                    if (purchaseOrderInfo) {
                      const purchaseOrderUpdatePayload = getPurchaseOrderUpdatePayload(purchaseOrderInfo, changeInCost);
                      // 3. Update purchase order
                      if (purchaseOrderUpdatePayload) {
                        const updatedPurchaseOrder = await PurchaseOrderClient.updatePurchaseOrder(
                          purchaseOrderInfo.id,
                          purchaseOrderUpdatePayload
                        );
                        if (!updatedPurchaseOrder) {
                          throw "Purchase Order update failed! Please contact Xemelgo support for assistance.";
                        }
                      } else {
                        throw "Required information for purchase order update unavailable. Please contact Xemelgo support for assistance.";
                      }
                    } else {
                      throw "Purchase order information not found! Please contact Xemelgo support for assistance.";
                    }
                  } else {
                    throw "Item order update failed. Please contact Xemelgo support for assistance.";
                  }
                } else {
                  throw "Order fulfillment update failed. Please contact Xemelgo support for assistance.";
                }
              } else {
                throw "Updated quantity or cost cannot be lesser than 0. Please contact Xemelgo support for assistance.";
              }
            }
          } else {
            throw "Order fulfillment information not found! Please contact Xemelgo support for assistance.";
          }
        } else {
          throw "Item order information not found! Please contact Xemelgo support for assistance.";
        }
      } else {
        await InventoryClient.updateItemV2(itemInfo.id, propertiesToUpdate);
      }
    } catch (e) {
      const errorObject = {
        title: `Update Failed`,
        message: `Something went wrong updating item. ${e || ""}`
      };
      setError(errorObject);
      setShowError(true);
      setLoading(false);
    } finally {
      onLoad();
    }
  };

  const handleUpdateImages = async (imagesFilesToUpload, imagePathsToRemove) => {
    if (!imagesFilesToUpload.length && !imagePathsToRemove.length) {
      return;
    }

    setLoading(true);

    const propertiesToUpdate = {
      images: {
        flagsToAdd: [],
        flagsToRemove: []
      }
    };

    try {
      if (imagesFilesToUpload.length) {
        const tenantId = LocalCacheService.loadUserProfile().getTenantId();
        propertiesToUpdate.images.flagsToAdd = await uploadImagesToS3(imagesFilesToUpload, tenantId, APP_ID);
      }
      if (imagePathsToRemove.length) {
        propertiesToUpdate.images.flagsToRemove = imagePathsToRemove;
        // Should remove the images from S3 but this is not allowed from the client.
      }
      await InventoryClient.updateItemV2(itemInfo.id, propertiesToUpdate);
    } catch (e) {
      const errorObject = {
        title: `Update Failed`,
        message: `Something went wrong updating images. ${e || ""}`
      };
      setError(errorObject);
      setShowError(true);
      setLoading(false);
    } finally {
      onLoad();
    }
  };

  const handleConsumeItem = async () => {
    const isConsumed = itemInfo.data.is_consumed;
    setLoading(true);

    await PublishClient.publishUserEvent([itemInfo.id], null, {
      actions: {
        consumeItem: !isConsumed,
        unconsumeItem: isConsumed,
        flipHasExitState: !!consumeItemOptions.flipHasExitState,
        endTrackingSession: !!consumeItemOptions.endTrackingSession
      }
    });

    setShowConsumeModal(false);
    onLoad();
  };

  const handleDeleteItem = async () => {
    setLoading(true);
    try {
      if (IsGoodsReceiptEnabled) {
        if (!orderFulfillmentQuantityKey) {
          throw "There seems to be an issue with the app configuration, please contact Xemelgo Support.";
        }
        // use item id to get current order fulfillment quantity
        const itemOrderFulfillmentInfo = await PurchaseOrderClient.getItemOrderFulfillmentInfoByItemId(itemInfo.id);

        if (itemOrderFulfillmentInfo) {
          const itemOrderObject = itemOrderFulfillmentInfo?.acquiredThrough.length
            ? itemOrderFulfillmentInfo.acquiredThrough[0]
            : null;

          if (itemOrderObject) {
            const {
              id,
              direct_unit_cost = 0,
              quantity_fulfilled = 0,
              shipping_charge = 0,
              total_cost_by_quantity_fulfilled = 0,
              quantity_ordered = 0,
              partOfOrder,
              order_status
            } = itemOrderObject;

            const purchaseOrderId = partOfOrder && partOfOrder.length > 0 ? partOfOrder[0].id : null;
            if (
              direct_unit_cost === null ||
              shipping_charge === null ||
              quantity_ordered === null ||
              purchaseOrderId === null
            ) {
              throw "Some required data seems to be missing. Please contact Xemelgo support for further help.";
            }

            const orderFulfillmentObject =
              itemOrderObject && itemOrderObject.fulfilledThrough.length > 0
                ? itemOrderObject.fulfilledThrough[itemOrderObject.fulfilledThrough.length - 1]
                : null;

            if (orderFulfillmentObject) {
              // item order's currently fulfilled quantity
              const currentFulfilledQuantity = orderFulfillmentObject.quantity;

              // item's quantity that's getting deleted
              const currentItemQuantity =
                itemInfo[orderFulfillmentQuantityKey] || itemInfo?.customFields[orderFulfillmentQuantityKey] || 0;

              // change in quantity
              const delta = -currentItemQuantity;

              // if current item's quantity is greater than what's fulfilled
              if (Math.abs(delta) > quantity_fulfilled) {
                throw "Inconsistent data found. Please contact Xemelgo support for assistance.";
              }
              // 1. Update order fulfillment
              const updatedOrderFulfillment = PurchaseOrderClient.updateOrderFulfillment(orderFulfillmentObject.id, {
                quantity: currentFulfilledQuantity - currentItemQuantity,
                fulfillment_date: getCurrentTimestamp()
              });

              if (updatedOrderFulfillment) {
                const new_total_cost_by_quantity_fulfilled =
                  Math.abs(delta) >= quantity_fulfilled
                    ? 0
                    : ((quantity_fulfilled || 0) + delta) * direct_unit_cost + shipping_charge;
                const changeInCost = new_total_cost_by_quantity_fulfilled - total_cost_by_quantity_fulfilled;

                // 2. Update item order
                const updatedItemOrder = await PurchaseOrderClient.updateItemOrder(id, {
                  quantity_fulfilled: (quantity_fulfilled || 0) + delta <= 0 ? 0 : (quantity_fulfilled || 0) + delta,
                  fulfillment_status:
                    (quantity_fulfilled || 0) + delta >= quantity_ordered
                      ? "Fulfilled"
                      : (quantity_fulfilled || 0) + delta <= 0
                      ? "Unfulfilled"
                      : "PartiallyFulfilled",
                  total_cost_by_quantity_fulfilled: new_total_cost_by_quantity_fulfilled,
                  order_status:
                    order_status === "Cancelled"
                      ? "Cancelled"
                      : (quantity_fulfilled || 0) + delta >= quantity_ordered
                      ? "Closed"
                      : "Open"
                });
                if (updatedItemOrder) {
                  const purchaseOrderInfo = await PurchaseOrderClient.getPurchaseOrderInfoById(purchaseOrderId);
                  if (purchaseOrderInfo) {
                    const purchaseOrderUpdatePayload = getPurchaseOrderUpdatePayload(purchaseOrderInfo, changeInCost);
                    if (purchaseOrderUpdatePayload) {
                      const updatedPurchaseOrder = await PurchaseOrderClient.updatePurchaseOrder(
                        purchaseOrderInfo.id,
                        purchaseOrderUpdatePayload
                      );
                      const context = { actions: { endTrackingSession: true } };
                      await PublishClient.userEvent([itemInfo.trackingSessionId], null, context);
                      await Promise.all([
                        SensorProfileClient.removeSensorProfile(itemInfo.sensorProfile.id),
                        ItemClient.removeItem(itemInfo.id, false)
                      ]);
                      if (!updatedPurchaseOrder) {
                        throw "Purchase Order update failed! Please contact Xemelgo support for assistance.";
                      }
                    } else {
                      throw "Required information for purchase order update unavailable. Please contact Xemelgo support for assistance.";
                    }
                  } else {
                    throw "Purchase order information not found! Please contact Xemelgo support for assistance.";
                  }
                } else {
                  throw "Item order update failed! Please contact Xemelgo support for assistance.";
                }
              } else {
                throw "Order fulfillment update failed! Please contact Xemelgo support for assistance.";
              }
            } else {
              throw "Order fulfillment information not found! Please contact Xemelgo support for assistance.";
            }
          } else {
            throw "Item order information not found! Please contact Xemelgo support for assistance.";
          }
        } else {
          throw "Item order fulfillment information not found! Please contact Xemelgo supporrt for assistance.";
        }
      } else {
        const context = { actions: { endTrackingSession: true } };
        await PublishClient.userEvent([itemInfo.trackingSessionId], null, context);
        await SensorProfileClient.removeSensorProfile(itemInfo.sensorProfile.id);
        await ItemClient.removeItem(itemInfo.id, false);
      }
      const fullPath = history.location.pathname;
      const parentPath = fullPath.slice(0, fullPath.indexOf("/item/detail"));
      history.replace(parentPath);
      window.location.reload(false);
    } catch (e) {
      console.log(e);
      const errorObject = {
        title: `Delete Failed`,
        message: `Something went wrong deleting item. ${e || ""}`
      };
      setError(errorObject);
      setShowError(true);
    } finally {
      setShowDeleteModal(false);
      setLoading(false);
    }
  };

  const handleAddLocation = async () => {
    try {
      setLoading(true);
      await PublishClient.publishUserEvent([itemInfo.id], locationToAdd.value);
    } catch (e) {
      console.log(e);
    } finally {
      setShowAddLocationModal(false);
      setLocationToAdd({});
      setLoading(false);
      onLoad();
    }
  };

  const handleSubmitShipmentIssue = async () => {
    const context = {
      actions: {
        shipment: {
          value: "Shipment Issue",
          condition: shipmentIssue.issue,
          comment: shipmentIssue.comment || ""
        }
      }
    };

    try {
      setLoading(true);
      await PublishClient.publishUserEvent([itemInfo.id], null, context);
    } catch (e) {
      console.log(e);
    } finally {
      setShowShipmentIssueModal(false);
      setShipmentIssue({});
      setLoading(false);
      onLoad();
    }
  };

  const renderErrorModal = () => {
    return (
      <Modal
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showError}
      >
        <Modal.Header>
          <Modal.Title className={Style.error_modal_title}>{error && error.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{error && error.message}</div>
        </Modal.Body>
        <Modal.Footer>
          <div
            onClick={() => {
              setShowError(false);
            }}
            className={Style.error_button}
          >
            OK
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showDeleteModal}
      >
        <Modal.Header>
          <Modal.Title>{`Delete Item ${itemInfo.identifier}?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Deleting an item would permanently remove the item from Xemelgo.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="confirm-delete-button"
            onClick={() => {
              handleDeleteItem();
            }}
            data-cy-inventory-detail-page__confirm-button
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderAddLocationModal = () => {
    return (
      <Modal
        show={showAddLocationModal}
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header>
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLabel>Select a location</InputLabel>
          <SearchDropdown
            selectedItem={locationToAdd}
            showIcon
            options={possibleDetectorLocations || []}
            onItemSelected={(event) => {
              setLocationToAdd(event);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() => {
              setLocationToAdd({});
              setShowAddLocationModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={loading || Object.keys(locationToAdd).length === 0}
            type="button"
            className={`${Style.button} ${Style.save_button}`}
            onClick={() => {
              handleAddLocation();
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderShipmentIssueModal = () => {
    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showShipmentIssueModal}
      >
        <Modal.Header>
          <Modal.Title>{`Report Issue With Shipment for ${itemInfo.identifier}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputLabel>What is wrong with the shipment?</InputLabel>
            <SearchDropdown
              selectedItem={shipmentIssue.issue}
              showIcon
              options={shipmentIssueOptions}
              onItemSelected={(event) => {
                const updatedIssue = shipmentIssue;
                updatedIssue.issue = event.value;
                setShipmentIssue(updatedIssue);
              }}
            />
            <div className={Style.commentBoxContainer}>
              <InputLabel>Comment (Optional)</InputLabel>
              <TextareaAutosize
                id="commentBoxText"
                className={Style.detail_value_input}
                value={shipmentIssue.comment}
                onChange={(event) => {
                  const updatedIssue = shipmentIssue;
                  updatedIssue.comment = event.target.value;
                  setShipmentIssue(updatedIssue);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() => {
              setShipmentIssue({});
              setShowShipmentIssueModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={!shipmentIssue || loading}
            className={`${Style.button} ${Style.save_button}`}
            onClick={() => {
              handleSubmitShipmentIssue();
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderConsumeModal = () => {
    const isConsumed = itemInfo.data.is_consumed;

    return (
      <ModalForm
        show={showConsumeModal}
        className={Style.consume_modal_container}
        title={<div>{isConsumed ? "Unconsume Item" : "Consume Item"}</div>}
        body={
          <div>
            {`You are about to mark `}
            <b className={Style.bold_text}>{itemInfo.identifier}</b>
            {` as ${isConsumed ? "un" : ""}consumed. Please press "Confirm" to proceed.`}
          </div>
        }
        footer={
          <ModalFormFooter
            onConfirm={handleConsumeItem}
            onCancel={() => {
              setShowConsumeModal(false);
            }}
          />
        }
        centered
      />
    );
  };

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <>
      <ScreenFrame
        title="Inventory Details"
        color={xemelgoStyle.theme.INVENTORY_PRIMARY}
        secondaryColor={xemelgoStyle.theme.INVENTORY_SECONDARY}
        titleIconComponent={
          <InventoryIcon
            width={25}
            height={25}
            style={{ color: xemelgoStyle.theme.INVENTORY_PRIMARY }}
          />
        }
      >
        <div className={Style.content_holder}>
          <div className={Style.detail_group}>
            <DetailCard
              defaultImage={itemInfo?.type?.imagePath}
              images={itemInfo?.images || []}
              isActive={itemInfo.is_active}
              statusList={itemInfo.status_flags}
              titleStructure={titleStructure}
              detailStructureList={contentStructureList}
              onSubmit={handleUpdateItem}
              onSubmitImages={handleUpdateImages}
              getAdditionalMoreButtonOptions={itemInfo.is_active && getAdditionalMoreButtonOptions}
            />
          </div>
          <div className={Style.table_group}>
            <div className={Style.top_table}>
              <div className={Style.title_group}>
                <div className={Style.title_label}>Location history</div>
                {itemInfo.is_active && (
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                      setShowAddLocationModal(true);
                    }}
                    className={Style.part_edit_btn}
                  >
                    Add Location
                  </div>
                )}
              </div>
              {ProcessStateHistory && itemInfo.processStateRoute.length ? (
                <DataTable
                  titleList={processProcessStateRouteTitleList}
                  dataList={itemInfo.processStateRoute}
                />
              ) : (
                <DataTable
                  titleList={routeTitleList}
                  dataList={itemInfo.route}
                />
              )}
            </div>
            {showShipmentData && (
              <div className={Style.bottom_table}>
                <div className={Style.title_group}>
                  <div className={Style.title_label}>Shipping History</div>
                  {itemInfo.is_active && (
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        setShowShipmentIssueModal(true);
                      }}
                      className={Style.part_edit_btn}
                    >
                      Issue with Shipment
                    </div>
                  )}
                </div>
                <DataTable
                  titleList={shipmentTitleList}
                  dataList={shipmentHistory}
                />
              </div>
            )}
          </div>
        </div>
      </ScreenFrame>
      {renderErrorModal()}
      {renderDeleteModal()}
      {renderAddLocationModal()}
      {renderShipmentIssueModal()}
      {renderConsumeModal()}
    </>
  );
};

export default withRouter(ItemDetailPage);
