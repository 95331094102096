import React, { useMemo } from "react";
import useWorkOrderTrackPageStateContext from "features/order-track-page-feature-v2/contexts/work-order-track-page-state-context";
import PrintTagsModal from "../../../print-tags-modal";
import SetLocationModal from "../../features/set-location-modal";
import MarkAsCompleteModal from "../../features/mark-as-complete-modal";

export const useMultiSelectActionFactory = (allOrders, orderSelection, action, onModalClose) => {
  const { enabledMultiSelectOptions } = useWorkOrderTrackPageStateContext();
  const selectedItems = useMemo(() => {
    return allOrders.filter((order) => orderSelection[order.id]);
  }, [orderSelection, allOrders]);

  const orderIds = useMemo(() => {
    return Object.keys(orderSelection || {}).filter((key) => {
      return !!orderSelection[key];
    });
  }, [orderSelection]);

  const ActionComponent = () => {
    switch (action) {
      case "printTags":
        return (
          <PrintTagsModal
            onCloseModal={onModalClose}
            selectedItems={selectedItems}
            modalTitle="Reprint Tags"
            appId="order"
          />
        );
      case "setLocation":
        const selectedOption = enabledMultiSelectOptions.find((option) => option.id === action);
        const { locationCategory = "Department" } = selectedOption;
        return (
          <SetLocationModal
            onClose={onModalClose}
            locationCategory={locationCategory}
            orderIds={orderIds}
          />
        );
      case "markAsComplete":
        return (
          <MarkAsCompleteModal
            onClose={onModalClose}
            orderIds={orderIds}
          />
        );
      default:
        return null;
    }
  };

  return { MultiSelectActionComponent: ActionComponent };
};
