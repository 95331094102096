import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { LocalCacheService } from "../../../../services/local-cache-service";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { getTagsSinceTimestamp, updateDeviceState } from "../../../../services/get-recent-tags-service";

import Style from "../../CheckOutTable.module.css";
import ItemTable from "./features/item-table";
import SidePanel from "./features/side-panel";

import { analyzeItemsForItemTypeReport } from "../../utils/item-type-report/itemTypeReportHelpers";
import { queryItemsFromBasicTags } from "../../utils/query-items-from-basic-tags/queryItemsFromBasicTags";
import { queryItemsFromParsedTags } from "../../utils/query-items-from-parsed-tag/queryItemsFromParsedTags";
import { ClearScheduledInterval, ScheduledSyncWorkflowInterval } from "../../utils/scheduled-sync-workflow-interval";
import { FilterBar } from "./components/filter-bar/FilterBar";
import TopPanel from "./features/top-panel";
import useCheckOutTableConfigContext from "../../contexts/check-out-table-config-context";
import useCheckOutTableStateContext from "../../contexts/check-out-table-state-context";

export const MainPage = () => {
  const xemelgoClient = useXemelgoClient();

  const [startIntervalRequestId, setStartIntervalRequestId] = useState(Date.now());
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [clearingInProgress, setClearingInProgress] = useState(false);
  const [inventoryClient] = useState(xemelgoClient.getInventoryClient());
  const [itemTypeClient] = useState(xemelgoClient.getItemTypeClient());

  const {
    itemTypeQueryAttributes,
    apiUrl,
    queryFrequencyInSeconds,
    upcNumberOfCharactersToTrim,
    inactivityThresholdInMinutes,
    readerPowerToSet,
    hasSgtinTags,
    updateReaderStateOnClear = true,
    enableSimpleTagGrouping,
    itemClassFilters,
    sidePanelAttributes,
    readerLocationOptionCategory,
    defaultLocationIdentifierFilterBy
  } = useCheckOutTableConfigContext();

  const {
    isSubmitting,
    selectedAction,
    panelValues,
    setPanelValues,
    itemByTag,
    setItemByTag,
    itemTypeReports,
    setItemTypeReports,
    filterInput,
    setFilterInput,
    setIsSubmitting,
    readerLocationOptionsMap,
    locationOptionsMap
  } = useCheckOutTableStateContext();

  const isSubmitDisabled = useMemo(() => {
    const unfilledAttributes = sidePanelAttributes.filter((attribute) => {
      return attribute.isRequired && !panelValues[attribute.id];
    });

    return Object.keys(itemByTag).length === 0 || unfilledAttributes.length > 0;
  }, [sidePanelAttributes, panelValues, itemByTag]);

  const onClear = useCallback(() => {
    setClearingInProgress(true);
    setStartIntervalRequestId(undefined);
    setItemByTag({});
    setItemTypeReports([]);
    setShowInactivityModal(false);
    setShowConfirmModal(false);

    // TODO: potentially memory leak warning
    setTimeout(() => {
      setClearingInProgress(false);
      setStartIntervalRequestId(Date.now());
    }, 1000);
  }, [clearingInProgress]);

  const itemByTagRef = useRef(itemByTag);
  itemByTagRef.current = itemByTag;
  const lastQueryTimestampRef = useRef(Date.now());

  const queryForNewDiscoveredTags = useCallback(
    async (detectorSerial, since) => {
      const allTags = await getTagsSinceTimestamp(apiUrl, detectorSerial, since);
      const alreadyQueriedTags = Object.keys({ ...itemByTagRef.current });

      const newlyDiscoveredTags = allTags.filter((tagObject) => {
        return !alreadyQueriedTags.includes(tagObject.Name);
      });

      return newlyDiscoveredTags;
    },
    [itemByTag]
  );

  const queryItemsFromTags = useCallback(
    async (discoveredTagData) => {
      if (enableSimpleTagGrouping) {
        const firstSeenTimestamp = Date.now();
        return discoveredTagData.map((tag) => {
          return {
            vid: tag.Name,
            type: { identifier: tag.Name, id: tag.Name },
            firstSeenTimestamp
          };
        });
      }
      if (hasSgtinTags) {
        return queryItemsFromParsedTags(
          discoveredTagData,
          itemByTagRef.current,
          itemTypeClient,
          itemTypeQueryAttributes,
          upcNumberOfCharactersToTrim
        );
      }
      return queryItemsFromBasicTags(discoveredTagData, inventoryClient, itemTypeQueryAttributes, itemClassFilters);
    },
    [itemTypeReports, itemClassFilters, itemTypeQueryAttributes]
  );

  const updateReaderState = useCallback(async () => {
    const { readerLocation } = panelValues;
    if (!readerLocation || !Object.keys(readerLocation).length) {
      return;
    }
    const { detectorSerial, detectorVendor } = panelValues.readerLocation || {};
    await updateDeviceState(apiUrl, detectorSerial, detectorVendor, "restart", readerPowerToSet);
  }, [panelValues]);

  // get default location by identifier filter
  const defaultLocation = useMemo(() => {
    if (defaultLocationIdentifierFilterBy && Object.keys(locationOptionsMap).length) {
      const [firstLocationMatched = {}] = Object.values(locationOptionsMap).filter(
        ({ identifier: locationIdentifier }) => {
          return locationIdentifier?.includes(defaultLocationIdentifierFilterBy);
        }
      );
      return firstLocationMatched;
    }
    return {};
  }, [defaultLocationIdentifierFilterBy, locationOptionsMap]);

  useEffect(() => {
    if (selectedAction) {
      const newPanelValues = { ...panelValues };

      const tempOptions = Object.values(readerLocationOptionsMap).filter((location) => {
        return location.category === readerLocationOptionCategory;
      });
      if (tempOptions.length === 1) {
        newPanelValues.readerLocation = tempOptions[0];
      }
      if (defaultLocation && Object.keys(defaultLocation).length) {
        newPanelValues.location = defaultLocation;
      }

      const cachedLocation = LocalCacheService.getCheckOutTableSelectedLocation();
      if (cachedLocation && Object.keys(cachedLocation).length > 0) {
        newPanelValues.location = cachedLocation;
      }

      setPanelValues(newPanelValues);
    } else {
      setPanelValues({});
    }
  }, [selectedAction, readerLocationOptionsMap, readerLocationOptionCategory, defaultLocation]);

  useEffect(() => {
    let cancelled = false;
    const cancelCb = () => {
      cancelled = true;
    };

    if (!startIntervalRequestId || !panelValues.readerLocation || !selectedAction || isSubmitting) {
      return cancelCb;
    }

    const { detectorSerial } = panelValues.readerLocation;
    // schedule for next workflow
    const intervalId = ScheduledSyncWorkflowInterval(async () => {
      const newLastQueryTimestamp = Date.now() - 1000 * queryFrequencyInSeconds;
      const discoveredTags = await queryForNewDiscoveredTags(detectorSerial, newLastQueryTimestamp);
      lastQueryTimestampRef.current = newLastQueryTimestamp;
      try {
        const newItemByTag = await queryItemsFromTags(discoveredTags);
        if (!cancelled) {
          const newItemByTagMap = {
            ...newItemByTag,
            ...(itemByTagRef?.current || {})
          };
          const reports = analyzeItemsForItemTypeReport(Object.values(newItemByTagMap));
          setItemTypeReports(reports);
          setItemByTag(newItemByTagMap);
        }
      } finally {
        if (cancelled) {
          ClearScheduledInterval(intervalId);
        }
      }
    }, 1000 * queryFrequencyInSeconds);

    if (inactivityThresholdInMinutes <= 0) {
      // completely disable the inactivity check.
      return () => {
        cancelCb();
        ClearScheduledInterval(intervalId);
      };
    }
    // inactivity check is enabled.
    const inactiveIntervalId = setInterval(() => {
      if (!cancelled) {
        setShowInactivityModal(true);
      }
    }, 60000 * inactivityThresholdInMinutes);

    return () => {
      cancelCb();
      ClearScheduledInterval(intervalId);
      clearInterval(inactiveIntervalId);
    };
  }, [startIntervalRequestId, panelValues, selectedAction, isSubmitting]);

  useEffect(() => {
    window.fcWidget.hide();
    return () => {
      window.fcWidget.show();
    };
  }, []);

  useEffect(() => {
    if (panelValues.readerLocation) {
      setStartIntervalRequestId(Date.now());
    }
  }, [panelValues.readerLocation]);

  useEffect(() => {
    if (startIntervalRequestId && updateReaderStateOnClear) {
      updateReaderState();
    }
  }, [startIntervalRequestId, updateReaderStateOnClear]);

  useEffect(() => {
    if (showInactivityModal || showConfirmModal) {
      setStartIntervalRequestId(undefined);
    }
  }, [showInactivityModal, showConfirmModal]);

  return (
    <>
      <div className={Style.body}>
        <div className={Style.main_content_container}>
          <TopPanel onClear={onClear} />
          <FilterBar
            filterInput={filterInput}
            onFilter={setFilterInput}
          />
          <ItemTable />
        </div>
        {sidePanelAttributes?.length > 0 && <SidePanel />}
      </div>
      <div className={Style.footer}>
        <button
          type="button"
          className={Style.submit_button}
          disabled={isSubmitDisabled}
          onClick={() => {
            setIsSubmitting(true);
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};
