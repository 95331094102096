import React, { useMemo } from "react";
import { multipleSortComparison } from "../../../../utils";
import Style from "./ActionsPage.module.css";
import useCheckOutTableConfigContext from "../../contexts/check-out-table-config-context";
import useCheckOutTableStateContext from "../../contexts/check-out-table-state-context";

export const ActionsPage = () => {
  const { actionsMap } = useCheckOutTableConfigContext();
  const { setSelectedAction, setLoading } = useCheckOutTableStateContext();

  const sortedActions = useMemo(() => {
    return Object.values(actionsMap).sort((a, b) => {
      if (a.index === b.index) {
        return multipleSortComparison(["label"])(a, b);
      }
      return a.index - b.index;
    });
  }, [actionsMap]);
  return (
    <div className={Style.container}>
      <div
        className={`${Style.actions_page_container} ${
          sortedActions.length <= 4 ? Style.actions_page_container_column : Style.actions_page_container_row
        }`}
      >
        {sortedActions.map((action) => {
          return (
            <div className={Style.action_button_container}>
              <button
                type="button"
                key={action.id}
                onClick={() => {
                  setLoading(true);
                  setSelectedAction(action.id);
                }}
                className={Style.action_button}
              >
                {action.label}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
