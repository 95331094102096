import { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import bartenderService from "../../services/bartender-service";
import useZebraPrinter from "../use-zebra-printer";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import { PRINT_TIMES, PRINT_TYPES } from "../../data/constants";

const printerInfo = {
  name: { hiddenOnInfoCard: true },
  message: { label: "Status" },
  id: { label: "IP Address" }
};

export const usePrintService = (isEnabled, printType, customPrintConfig, solutionType) => {
  const [printers, setPrinters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [printerErrorMessage, setPrinterErrorMessage] = useState("");
  const xemelgoClientAppSync = useXemelgoAppsyncClient();
  const [templatesList, setTemplatesList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [printTime, setPrintTime] = useState(PRINT_TIMES.now.id);

  const {
    isPrinterScriptsReady,
    queryPrinters,
    selectedPrinterInfo,
    setSelectedPrinterInfo,
    sendToPrinter,
    registerPrinter,
    stopGetPrinterStatus
  } = useZebraPrinter();

  // parse the config from selected label
  const templateConfig = useMemo(() => {
    const { template = "" } = selectedTemplate || {};
    if (printType !== PRINT_TYPES.ZPL) {
      // parse bartender print config from custom print configuration
      if (customPrintConfig) {
        const { endpoint } = customPrintConfig || {};
        return { bartenderEndpoint: endpoint, config: customPrintConfig };
      }
      const parsedBartenderConfig = JSON.parse(template || "{}");
      const { endpoint } = parsedBartenderConfig;
      return { bartenderEndpoint: endpoint, config: parsedBartenderConfig };
    }
    return { config: { customTemplate: template } };
  }, [selectedTemplate, printType]);

  const isPrintReady = useMemo(() => {
    if (printTime === PRINT_TIMES.later.id) {
      return true;
    }

    if (printType === PRINT_TYPES.ZPL && selectedPrinterInfo?.message?.value !== "Online") {
      return false;
    }

    return !!Object.keys(templateConfig?.config || {}).length;
  }, [selectedPrinterInfo, templateConfig, printType, printTime]);

  const { printTag: bartenderPrint } = bartenderService(templateConfig.bartenderEndpoint);

  const getBartenderPrinters = (templates) => {
    return templates.map((template) => {
      const { printerIP } = template;
      return { id: printerIP, label: printerIP, name: printerIP };
    });
  };

  const getDefaultBartenderPrinters = () => {
    const defaultPrinter = { id: "default_printer", name: "default_printer", label: customPrintConfig.printerName };
    return { printers: [defaultPrinter], defaultPrinter };
  };

  const getTemplates = async () => {
    try {
      const printClient = xemelgoClientAppSync.getPrintClient();

      // if print config exist, generate a default template
      if (customPrintConfig) {
        switch (printType) {
          case PRINT_TYPES.BARTENDER:
          case PRINT_TYPES.BARTENDER_UPLOAD:
            return [{ id: "default", label: "Default template", template: customPrintConfig }];
          default:
            return [{ id: "default", label: "Default template", template: customPrintConfig }];
        }
      } else {
        // get templates from DB
        const printTemplates = await printClient.getTemplates(solutionType);
        return printTemplates;
      }
    } catch {
      throw new Error("Could not get printers list.");
    }
  };

  const print = async (printPayload) => {
    stopGetPrinterStatus();

    if (printTime === PRINT_TIMES.later.id) {
      return null;
    }

    switch (printType) {
      case PRINT_TYPES.ZPL:
        return sendToPrinter(printPayload);
      case PRINT_TYPES.BARTENDER:
        return bartenderPrint(printPayload);
      case PRINT_TYPES.BARTENDER_UPLOAD:
        const uploadCSVClient = xemelgoClientAppSync.getUploadCSVClient();
        return uploadCSVClient.uploadCSVToS3(`${new Date().getTime()}-${v4()}-tags.csv`, "print-files", printPayload);
      default:
        return null;
    }
  };

  const onLoad = async () => {
    if (printType === PRINT_TYPES.ZPL && !isPrinterScriptsReady) {
      return;
    }

    let newPrinters = [];
    let newDefaultPrinter;

    // get list of printers
    try {
      const newPrintTemplates = await getTemplates();
      switch (printType) {
        case PRINT_TYPES.BARTENDER:
          if (customPrintConfig) {
            const { printers: bartenderPrinters, defaultPrinter: defaultBartenderPrinter } =
              getDefaultBartenderPrinters();
            newPrinters = [...bartenderPrinters];
            newDefaultPrinter = { ...defaultBartenderPrinter };
            break;
          }

          const bartenderPrinters = getBartenderPrinters(newPrintTemplates);
          newPrinters = [...bartenderPrinters];
          break;
        case PRINT_TYPES.ZPL:
          if (isPrinterScriptsReady) {
            const { printers: zebraPrinters, defaultPrinter: defaultZebraPrinter } = (await queryPrinters()) || {};
            newPrinters = [...zebraPrinters];
            newDefaultPrinter = { ...defaultZebraPrinter };
          }
          break;
        default:
          break;
      }

      // default the printer selection when using config
      if (customPrintConfig) {
        await onSelectPrinter(newDefaultPrinter);
      }
      setTemplatesList(newPrintTemplates);
      setPrinters(newPrinters);
    } catch (e) {
      setPrinterErrorMessage(e.message);
    }
    setIsLoading(false);
  };

  const onSelectPrinter = async (printer) => {
    setPrinterErrorMessage("");
    if (!printer || !Object.keys(printer).length) {
      setSelectedPrinterInfo({});
      await registerPrinter({});

      return;
    }
    try {
      if (printType === PRINT_TYPES.BARTENDER) {
        setSelectedPrinterInfo({ ...printerInfo, name: { ...printerInfo.name, label: printer.label } });
      } else {
        await registerPrinter(printer);
      }
    } catch (e) {
      setPrinterErrorMessage("Could not get printer information");
    }
  };

  useEffect(() => {
    setIsLoading(true);

    if (isEnabled) {
      onLoad();
    }
    return () => {
      setPrinterErrorMessage("");
    };
  }, [isEnabled, isPrinterScriptsReady]);

  return {
    print,
    printers,
    selectedPrinterInfo,
    onSelectPrinter,
    isLoading,
    printerErrorMessage,
    templatesList,
    selectedTemplate,
    onSelectTemplate: setSelectedTemplate,
    selectedTemplateConfig: templateConfig?.config || {},
    isPrintReady,
    printTime,
    onPrintTimeSelect: setPrintTime
  };
};
