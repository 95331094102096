import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useStore } from "react-hookstore";
import queryString from "query-string";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitFullscreenIcon from "@material-ui/icons/FullscreenExit";
import { fullScreenModeStore } from "../../state-managements/stores/full-screen-mode-store";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import Style from "./CheckOutTable.module.css";
import { ReactComponent as GoodsReceiptIcon } from "../../assets/icons/goods-receipt.svg";
import LoadingCircle from "../../components/loading/LoadingCircle";
import ActionsPage from "./features/actions-page";
import MainPage from "./features/main-page";
import SubmissionModal from "./features/submission-modal";
import ScreenFrame from "../../components/ScreenFrame/ScreenFrame";
import xemelgoStyle from "../../styles/variable";
import { getLocationWithDetector } from "./utils/location-detector-mapper";
import { OPTION_TEMPLATE_OPTIONS } from "./data/constants";
import useUserLocationContext from "../../context/user-location-context";
import StatusPopupComponent from "../../components/status-popup-component";
import BreadcrumbsComponent from "../../components/breadcrumbs-component/BreadcrumbsComponentV2";
import useCheckOutTableConfigContext, {
  CheckOutTableConfigContextProvider
} from "./contexts/check-out-table-config-context";
import useCheckOutTableStateContext, {
  CheckOutTableStateContextProvider
} from "./contexts/check-out-table-state-context";
import { STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";

const mainColor = xemelgoStyle.theme.INVENTORY_PRIMARY;
const secondaryColor = xemelgoStyle.theme.INVENTORY_SECONDARY;

const CheckOutTable = () => {
  const xemelgoClient = useXemelgoClient();
  const xemelgoClientAppSync = useXemelgoAppsyncClient();
  const { userLocation } = useUserLocationContext();

  const [userClient] = useState(xemelgoClientAppSync.getUserClient());
  const [locationClient] = useState(xemelgoClient.getLocationClient());
  const [detectorClient] = useState(xemelgoClient.getDetectorClient());

  const [fullScreenMode, setFullScreenMode] = useStore(fullScreenModeStore);

  const { search, pathname } = useLocation();
  const history = useHistory();

  const { sidePanelAttributes, actionsMap, title } = useCheckOutTableConfigContext();

  const {
    loading,
    setLoading,
    selectedAction,
    setSelectedAction,
    setLocationOptionsMap,
    setReaderLocationOptionsMap,
    setItemByTag,
    setItemTypeReports,
    setOptionTemplateMap,
    submitStatus,
    setSubmitStatus,
    statusMessage
  } = useCheckOutTableStateContext();

  const getOptionsFromOptionsTemplate = async (id) => {
    switch (id) {
      case OPTION_TEMPLATE_OPTIONS.USERS:
        const queryUsersResponse = await userClient.queryUsers();
        const { queryUsers = {} } = queryUsersResponse;
        const { users = [] } = queryUsers;
        const userOptions = users.map((user) => {
          const formattedName = `${user.firstName || ""} ${user.lastName || ""}`;
          return {
            id: user.id,
            value: formattedName,
            label: formattedName
          };
        });
        return {
          id,
          options: userOptions
        };
      default:
        return {
          id,
          options: []
        };
    }
  };

  const onBreadScumbsClick = useCallback(
    (id) => {
      const params = { ...queryString.parse(search) };
      if (id) {
        params.actionId = id;
      } else {
        delete params.actionId;
      }
      const paramsString = queryString.stringify(params);
      history.push(`${pathname}?${paramsString}`);
    },
    [history]
  );

  const breadscumbsDataList = useMemo(() => {
    const defaultBreadscrumbsDataList = [
      {
        id: "",
        value: title,
        onClick: onBreadScumbsClick
      }
    ];
    if (selectedAction) {
      defaultBreadscrumbsDataList.push({
        id: selectedAction,
        value: actionsMap[selectedAction]?.label || "",
        onClick: onBreadScumbsClick
      });
    }
    return defaultBreadscrumbsDataList;
  }, [selectedAction, actionsMap, title]);

  const fetchData = async () => {
    const newLocationTree = locationClient.getLocationTree(["id", "name", "identifier"]);
    const newDetectorsPromise = detectorClient.listDetectors();
    const promises = [newLocationTree, newDetectorsPromise];

    sidePanelAttributes.forEach((attribute) => {
      if (attribute.optionTemplate) {
        promises.push(getOptionsFromOptionsTemplate(attribute.optionTemplate));
      }
    });

    const [locationTree = {}, detectors = [], ...optionsResponses] = await Promise.all(promises);
    const { locationOptionsMap, readerLocationOptionsMap } = getLocationWithDetector(
      locationTree,
      userLocation,
      detectors
    );
    const newOptionsTemplateMap = optionsResponses.reduce((accumulator, response) => {
      const { id, options } = response;
      accumulator[id] = options;
      return accumulator;
    }, {});

    setOptionTemplateMap(newOptionsTemplateMap);
    setLocationOptionsMap(locationOptionsMap);
    setReaderLocationOptionsMap(readerLocationOptionsMap);
    setLoading(false);
  };

  useEffect(() => {
    const { fullScreen, actionId } = queryString.parse(search);
    const newFullScreen = fullScreen === "true";
    setFullScreenMode(newFullScreen);

    setSelectedAction(actionId);
    if (!actionId) {
      setItemByTag({});
      setItemTypeReports([]);
    }
    return () => {
      setSelectedAction("");
      setFullScreenMode(false);
    };
  }, [search]);

  useEffect(() => {
    if (selectedAction) {
      const params = queryString.stringify({ ...queryString.parse(search), actionId: selectedAction });
      history.replace(`${pathname}?${params}`);

      fetchData();
    }
  }, [locationClient, detectorClient, selectedAction, sidePanelAttributes]);

  useEffect(() => {
    if (submitStatus !== STATUS_OPTIONS.NONE) {
      setTimeout(() => {
        setSubmitStatus(STATUS_OPTIONS.NONE);
      }, 5000);
    }
  }, [submitStatus]);

  if (loading) {
    return <LoadingCircle shouldCenter={!fullScreenMode} />;
  }

  return (
    <>
      <SubmissionModal />
      <ScreenFrame
        titleIconComponent={
          <GoodsReceiptIcon
            width={25}
            height={25}
            style={{ color: mainColor }}
          />
        }
        title={
          <BreadcrumbsComponent
            dataList={breadscumbsDataList}
            itemStyle={Style.breadscrumbs_item}
          />
        }
        titleRightComponent={
          <div
            onClick={() => {
              const params = queryString.stringify({ ...queryString.parse(search), fullScreen: !fullScreenMode });
              history.replace(`${pathname}${params ? `?${params}` : ""}`);
            }}
            className={Style.fullscreen_button}
          >
            {fullScreenMode ? (
              <ExitFullscreenIcon className={Style.fullscreen_icon} />
            ) : (
              <FullscreenIcon className={Style.fullscreen_icon} />
            )}
            {fullScreenMode ? "Exit Full Screen" : "Full Screen"}
          </div>
        }
        color={mainColor}
        secondaryColor={secondaryColor}
      >
        <div className={fullScreenMode ? Style.fullscreen_main_container : Style.main_container}>
          {selectedAction ? <MainPage /> : <ActionsPage />}
        </div>
        <div className={Style.popup_modal_div}>
          <StatusPopupComponent
            showPopup={submitStatus !== STATUS_OPTIONS.NONE}
            message={statusMessage}
            status={submitStatus}
          />
        </div>
      </ScreenFrame>
    </>
  );
};

export default () => {
  return (
    <CheckOutTableStateContextProvider>
      <CheckOutTableConfigContextProvider>
        <CheckOutTable />
      </CheckOutTableConfigContextProvider>
    </CheckOutTableStateContextProvider>
  );
};
