import React, { useCallback } from "react";
import { SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import ImageUploadComponent from './components/image-upload-component/ImageUploadComponent';
import ChartSwiper from '../chartSwiper';
import ImageWithEnlargedModalView from '../image-with-enlarged-modal-view';
import Style from "./ImagesDisplayAndUpload.module.css";

const ImagesDisplayAndUpload = ({
  images,
  isEditMode,
  onImagesAdd,
  onImageRemove,
  maxNumberOfFiles,
  isLoading
}) => {
  const renderImage = useCallback(() => {
    return (
      <ChartSwiper containerClassName={Style.swiper_container}>
        {images.map((image) => {
          return (
            <SwiperSlide key={image}>
              <div
                className={Style.image_container}
              >
                <ImageWithEnlargedModalView
                  classOverrides={{
                    imageStyle: Style.detail_image
                  }}
                  imageUri={image}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </ChartSwiper>
    );
  }, [images]);

  if (isLoading) {
    return <Skeleton className={`${Style.image_container} ${Style.skeleton}`} />;
  }

  return isEditMode ? (
    <div>
      <ImageUploadComponent
        existingImagePaths={images}
        onNewImageChange={onImagesAdd}
        onExistingImageRemove={onImageRemove}
        maxNumberOfFiles={maxNumberOfFiles}
        acceptedFileTypes={["jpg", "png", "jpeg"]}
      />
    </div>
  ) : (
    renderImage()
  );
};

ImagesDisplayAndUpload.defaultProps = {
  onImageRemove: () => { },
  images: [],
  maxNumberOfFiles: 1,
  isLoading: false,
};

ImagesDisplayAndUpload.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  isEditMode: PropTypes.bool.isRequired,
  onImagesAdd: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func, 
  maxNumberOfFiles: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default ImagesDisplayAndUpload;
