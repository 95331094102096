import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Style from "./Breadcrumb.module.css";
import BreadcrumbComponent, { useBreadcrumbBuilder } from "../../../../components/breadcrumb";
import useInventoryTrackPageDataSourceContext from "../../contexts/inventory-track-page-data-source-context";
import useInventoryTrackPageStateContext from "../../contexts/inventory-track-page-state-context";
import { SEARCH_PARAM_NAME } from "../../hooks/use-inventory-track-page-search-params-hook";

export const Breadcrumb = () => {
  const { locationTreeMap, itemTypesByLocationState } = useInventoryTrackPageDataSourceContext();

  const { selectedLocationId, selectedItemTypeIdentifier } = useInventoryTrackPageStateContext();
  const { search, pathname } = useLocation();

  const selectedItemTypeObj = useMemo(() => {
    return itemTypesByLocationState.dataList.find((eachItemType) => {
      return eachItemType.identifier === selectedItemTypeIdentifier;
    });
  }, [selectedItemTypeIdentifier, itemTypesByLocationState.dataList]);

  const { breadcrumbDataList } = useBreadcrumbBuilder({
    selectedLocationId,
    locationTreeMap
  });

  const finalBreadcrumbDataList = useMemo(() => {
    if (selectedItemTypeObj) {
      const newBreadcrumbDataList = [...breadcrumbDataList];
      const newSearchStringObj = queryString.parse(search);
      delete newSearchStringObj[SEARCH_PARAM_NAME.selectedItemTypeIdentifier];
      newBreadcrumbDataList[newBreadcrumbDataList.length - 1].path = `${pathname}?${queryString.stringify(
        newSearchStringObj
      )}`;

      return [...newBreadcrumbDataList, { title: <div>{selectedItemTypeObj.identifier}</div> }];
    }
    return breadcrumbDataList;
  }, [breadcrumbDataList, selectedItemTypeObj]);

  return (
    <div className={Style.container}>
      <BreadcrumbComponent dataList={finalBreadcrumbDataList} />
    </div>
  );
};
