export const queryForLatestDetectionsByReader = async (apiUrl, detectorSerial) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  return await fetch(`${apiUrl}${detectorSerial}/recent-tags`, requestOptions);
};

/**
 *
 * @param apiUrl
 * @param detectorSerial
 * @param timestamp
 * @returns {Promise<string[]>}
 */
export const getTagsSinceTimestamp = async (apiUrl, detectorSerial, timestamp) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      deviceId: detectorSerial,
      timeStampSince: timestamp
    })
  };
  try {
    const response = await fetch(`${apiUrl}/streaming-tags`, requestOptions);
    const results = await response.json();
    return results;
  } catch (e) {
    return [];
  }
};

/**
 *
 * @param {*} apiUrl { string }
 * @param {*} detectorSerial { string }
 * @param {*} vendor { string }
 * @param {*} state { string }
 * @param {*} transmitPower { number }
 *
 * @returns {Promise}
 */

export const updateDeviceState = async (apiUrl, detectorSerial, vendor, state, transmitPower = 25) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      vendor: vendor.toLowerCase(),
      state,
      transmitPower
    })
  };

  try {
    const result = await fetch(`${apiUrl}/devices/${detectorSerial}/state`, requestOptions);
    return result;
  } catch (e) {
    Promise.resolve({});
  }
};
