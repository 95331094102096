import React, { PureComponent, Fragment } from "react";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import ClearIcon from "@material-ui/icons/Clear";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody } from "mdbreact";
import Switch from "react-switch";
import * as Datetime from "react-datetime";
import TextareaAutosize from "react-textarea-autosize";
import "../../pages/order/TrackOrderPage.css";
import { getCustomerLogo, getLongXemelgoLogo, convertMillisecondsToUnitsOfTime } from "common/Utilities";
import ImagesDisplayAndUpload from "../images-display-and-upload";
import Style from "./css/DetailCard.module.css";

const statusColor = {
  delayed: "#F62227",
  expedited: "#e3621d",
  completed: "#C6CBD4",
  "on-time": "#00B200"
};

const MAX_NUMBER_OF_IMAGES = 20;

export default class DetailCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editEnable: false,
      imageFilesToUpload: [],
      imagePathsToRemove: []
    };
    this.handleEditSave.bind(this);
  }

  async componentDidMount() {
    const { titleStructure = {}, detailStructureList = {}, remarks = "" } = this.props;
    const state = {
      remarks,
      remarksDisabled: false
    };
    state.customerLogo = (await getCustomerLogo()) || getLongXemelgoLogo();
    if (titleStructure.editable) {
      state[titleStructure.key] = titleStructure.value;
    }
    detailStructureList.forEach((each) => {
      if (each.editable) {
        state[each.key] = each.value;
      }
    });
    this.setState(state);
  }

  handleChange = (currentTarget, type) => {
    this.setState({
      [currentTarget.id]:
        type === "number"
          ? parseFloat(currentTarget.value ? currentTarget.value.replace(/\D/g, "") : 0)
          : currentTarget.value
    });
  };

  handleRemarksChange = (event) => {
    this.setState({
      remarks: event.target.value
    });
  };

  handleRemarksCancel = () => {
    const { remarks } = this.props;
    this.setState({
      remarks
    });
  };

  handleRemarksSave = async () => {
    const { onRemarksSubmit } = this.props;
    const { remarks } = this.state;
    this.setState({
      remarksDisabled: true
    });
    await onRemarksSubmit(remarks);
    this.setState({
      remarksDisabled: false
    });
  };

  handleEditCancel = () => {
    const { titleStructure = {}, detailStructureList = {} } = this.props;
    const state = { editEnable: false, imageFilesToUpload: [], imagePathsToRemove: [] };
    if (titleStructure.editable) {
      state[titleStructure.key] = titleStructure.value;
    }
    detailStructureList.forEach((each) => {
      if (each.editable) {
        state[each.key] = each.value;
      }
    });

    this.setState(state);
  };

  handleEditSave = () => {
    const { titleStructure, detailStructureList, onSubmit, onSubmitImages } = this.props;
    const { editEnable, imageFilesToUpload, imagePathsToRemove } = this.state;

    if (editEnable) {
      // Determine which fields were changed and put them in formData
      const formData = {};
      Object.keys(this.state).forEach((eachKey) => {
        let changed = false;

        if (eachKey !== "editEnable") {
          const detailItem = detailStructureList.find((each) => each.key === eachKey);
          if (eachKey === titleStructure.key && this.state[eachKey] !== titleStructure.value) {
            changed = true;
          } else if (detailItem && this.state[eachKey] !== detailItem.value) {
            changed = true;
          }
        }
        if (changed) {
          formData[eachKey] = this.state[eachKey];
        }
      });
      onSubmit(formData);

      if (imageFilesToUpload.length || imagePathsToRemove.length) {
        onSubmitImages(
          imageFilesToUpload.map((image) => image.file),
          [...imagePathsToRemove]
        );
      }
    }

    this.setState({ editEnable: false, imageFilesToUpload: [], imagePathsToRemove: [] });
  };

  renderMoreButton = () => {
    const { getAdditionalMoreButtonOptions, editButtonLabel } = this.props;
    return (
      <Dropdown>
        <DropdownToggle
          nav
          className={Style.dropdown_toggle}
          data-cy-detail-card__more-button
        >
          <MoreIcon />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            className="dropdown-item"
            onClick={() => {
              this.setState({ editEnable: true });
            }}
          >
            {editButtonLabel || "Edit Details"}
          </DropdownItem>
          {getAdditionalMoreButtonOptions && getAdditionalMoreButtonOptions()}
        </DropdownMenu>
      </Dropdown>
    );
  };

  renderEditActionButtons = () => {
    return (
      <div className={Style.edit_form_buttons}>
        <button
          type="button"
          className={`${Style.btn} ${Style.gray_btn}`}
          onClick={this.handleEditCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`${Style.btn} ${Style.blue_btn}`}
          onClick={this.handleEditSave}
        >
          Save
        </button>
      </div>
    );
  };

  renderDateTimeInput = (props) => {
    const clear = () => {
      props.onChange({ target: { value: "-" } });
    };
    return (
      <div className={Style.date_time_with_clear}>
        <input {...props} />
        <ClearIcon
          className={Style.clear_icon}
          onClick={clear}
        />
      </div>
    );
  };

  renderValueField = ({
    key,
    value,
    editable,
    type = "default",
    color = null,
    format,
    relativeDayCount = {},
    options,
    convertFromMilliseconds
  }) => {
    if (convertFromMilliseconds) {
      value = convertMillisecondsToUnitsOfTime(value);
    }
    const { editEnable } = this.state;
    switch (type.toLowerCase()) {
      case "switch":
        return (
          <Switch
            onColor={statusColor.expedited}
            id={key}
            checked={editable ? !!this.state[key] : !!value}
            onChange={(enable) => {
              if (key === "expedited" && !editEnable) {
                this.props.onExpeditedStatusChange(enable);
              } else {
                this.setState({ [key]: enable });
              }
            }}
            disabled={!(editEnable && editable) && !key === "expedited"}
            handleDiameter={24}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className={Style.react_switch}
          />
        );
      case "date":
      case "datepicker":
        return (
          <div className={Style.exp_date_field}>
            <Datetime
              id={key}
              className={Style.date_time}
              defaultValue="-"
              value={editable ? this.state[key] : value}
              inputProps={{
                className: editable && editEnable ? Style.date_time_value_input : Style.date_time_value,
                disabled: !(editEnable && editable)
              }}
              onChange={(moment) => {
                const date = moment._d;
                this.setState({ [key]: Date.parse(date) || null });
              }}
              onBlur={this.handleSubmit}
              dateFormat={format || "MM/DD/YYYY"}
              timeFormat={false}
              closeOnSelect
              renderInput={editable && editEnable ? this.renderDateTimeInput : null}
            />
            {relativeDayCount.show && (
              <TextareaAutosize
                id={`${key}_label`}
                className={`${Style.exp_date_label} ${Style.detail_value}`}
                style={{ color: relativeDayCount.color || "black" }}
                value={relativeDayCount.value}
                disabled
              />
            )}
          </div>
        );
      case "dropdown":
        if (editable && editEnable) {
          return (
            <select
              id={key}
              className={Style.detail_value_input}
              onChange={({ currentTarget }) => {
                this.handleChange(currentTarget);
              }}
              value={this.state[key] !== undefined ? this.state[key] : value}
            >
              <option
                key=""
                value={null}
              />
              {options.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {option.label}
                </option>
              ))}
            </select>
          );
        }
        return (
          <TextareaAutosize
            id={key}
            className={`${Style.detail_value} ${color ? Style[color] : null}`}
            value={value}
            disabled
          />
        );

      default:
        return (
          <TextareaAutosize
            id={key}
            className={`${editable && editEnable ? Style.detail_value_input : Style.detail_value} ${
              color ? Style[color] : null
            }`}
            value={editable && editEnable ? String(this.state[key]) : value}
            disabled={!(editable && editEnable)}
            onChange={({ currentTarget }) => {
              this.handleChange(currentTarget, type.toLowerCase());
            }}
          />
        );
    }
  };

  renderDetailGroups = (detailStructureList, isActive) => {
    const { editEnable } = this.state;
    return detailStructureList.map((eachStructure) => {
      if (eachStructure.type === "button") {
        return editEnable || !isActive ? null : (
          <button
            key={eachStructure.key}
            type="submit"
            className={`${Style.btn} ${eachStructure.light ? Style.white_btn : Style.blue_btn} ${
              eachStructure.className
            }`}
            onClick={eachStructure.onClick}
            data-cy-work-order-track-page-detail-card__button={eachStructure.name}
          >
            {eachStructure.name}
          </button>
        );
      }
      return (
        <div
          key={eachStructure.key}
          className={Style.detail_group}
        >
          <p className={Style.detail_label}>{`${eachStructure.name}:`}</p>
          {this.renderValueField(eachStructure)}
        </div>
      );
    });
  };

  renderStatus = (statusList) => {
    return statusList
      .filter((a, b) => statusList.indexOf(a) === b)
      .map((eachStatus, index) => {
        return (
          <div
            key={index}
            style={{ backgroundColor: eachStatus.color }}
            className={Style.status}
          >
            <p
              className={Style.status_text}
              data-cy-work-order-track-page-detail-card__status
            >
              {eachStatus.displayText}
            </p>
          </div>
        );
      });
  };

  renderRemarks = () => {
    const { remarks: propsRemarks = "" } = this.props;
    const { remarks, remarksDisabled } = this.state;

    const remarksHasChanges = propsRemarks !== remarks;
    return (
      <Fragment>
        <div className={Style.remarks_container}>
          <div className={Style.remarks_title}>Remarks</div>
          <textarea
            disabled={remarksDisabled}
            value={remarks || ""}
            className={Style.remarks_text}
            onChange={this.handleRemarksChange}
            rows={6}
          />
        </div>
        {remarksHasChanges && !remarksDisabled && (
          <div className={Style.remarks_button_container}>
            <button
              className={`${Style.btn} ${Style.remarks_cancel_button}`}
              onClick={this.handleRemarksCancel}
            >
              Cancel
            </button>
            <button
              className={`${Style.btn} ${Style.remarks_submit_button}`}
              onClick={this.handleRemarksSave}
            >
              Submit
            </button>
          </div>
        )}
      </Fragment>
    );
  };

  componentWillReceiveProps = (props) => {
    const { detailStructureList = {} } = props;
    detailStructureList.forEach((each) => {
      if (each.editable) {
        this.setState({ [each.key]: each.value });
      }
    });
  };

  addItemImages = (images) => {
    this.setState({ imageFilesToUpload: images });
  };

  removeItemImage = (imagePath) => {
    this.setState({ imagePathsToRemove: [...this.state.imagePathsToRemove, imagePath] });
  };

  render() {
    const {
      defaultImage = "",
      images = [],
      titleStructure = {},
      detailStructureList = [],
      statusList = [],
      isActive = true,
      remarksEnabled = false,
      isLoading
    } = this.props;
    const { editEnable, customerLogo } = this.state;

    const itemImages = images.filter((image) => !this.state.imagePathsToRemove.includes(image));
    const imageEditEnabled = editEnable && !!images;
    if (!editEnable || !imageEditEnabled) {
      if (defaultImage) {
        itemImages.unshift(defaultImage);
      } else if (itemImages.length === 0) {
        itemImages.unshift(customerLogo);
      }
    }

    return (
      <Card className={Style.detail_card}>
        <CardBody className={Style.detail_card_body}>
          <ImagesDisplayAndUpload
            images={itemImages}
            isEditMode={imageEditEnabled}
            onImagesAdd={this.addItemImages}
            onImageRemove={this.removeItemImage}
            maxNumberOfFiles={MAX_NUMBER_OF_IMAGES}
            isLoading={isLoading}
          />
          {statusList.length > 0 && <div className={Style.status_group}>{this.renderStatus(statusList)}</div>}
          <div className={Style.top_group}>
            <div className={Style.title_group}>
              <div className={Style.title_label}>{`${titleStructure.name || ""}:`}</div>
              <TextareaAutosize
                id={titleStructure.key}
                className={titleStructure.editable && editEnable ? Style.title_value_input : Style.title_value}
                value={
                  titleStructure.editable && editEnable ? this.state[titleStructure.key] : titleStructure?.value || ""
                }
                disabled={!(titleStructure.editable && editEnable)}
                onChange={({ currentTarget }) => {
                  this.handleChange(currentTarget);
                }}
                data-cy-detail-card__title
              />
            </div>
            {!editEnable && isActive && <this.renderMoreButton />}
          </div>
          <div className={Style.bottom_group}>{this.renderDetailGroups(detailStructureList, isActive)}</div>
          {editEnable && <this.renderEditActionButtons />}
          {remarksEnabled && this.renderRemarks()}
        </CardBody>
      </Card>
    );
  }
}
